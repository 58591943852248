import { useState } from "react";
import {
  CircularProgress,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../../Services/Https/user";
import { useQuery } from "react-query";

interface UsersTableProps {
  workspaceId: number;
}

const UsersTable: React.FC<UsersTableProps> = ({ workspaceId }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const pageSize = 25;
  const [search, setSearch] = useState("");

  const { data, isLoading } = useQuery(
    ["users", page, pageSize, search, workspaceId],
    () => getUsers(page, pageSize, search, workspaceId),
    {
      keepPreviousData: true,
    }
  );

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item mt={4}>
          <Input
            style={{
              color: "white",
              textAlign: "center",
            }}
            placeholder="Search Users..."
            inputProps={{ "aria-label": "search" }}
            value={search}
            onChange={(event) => {
              setPage(1);
              setSearch(event.target.value);
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
        </Grid>
        <Grid item mt={5} style={{ maxWidth: "40vw", width: "100%" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                      width: "10%",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                      width: "30%",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: color.btn_dark,
                      fontWeight: "bolder",
                      width: "30%",
                    }}
                  >
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.users && data.users.length > 0 ? (
                  data.users.map((user) => (
                    <TableRow
                      key={user.id}
                      onClick={() => navigate(`/user/${user.id}`)}
                      sx={{
                        "&:hover": { backgroundColor: color.primary_dark },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="left">
                      No Users available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item mb={10}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={data.total}
            rowsPerPage={pageSize}
            page={page - 1}
            onPageChange={handleChangePage}
          />
        </Grid>
      </Grid>
    );
  }
};

export default UsersTable;
