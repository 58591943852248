import { Button, Grid, Typography } from "@mui/material";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";

const EvaluationError = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={{
        height: "90vh",
      }}
    >
      <Typography variant="h5" sx={{ color: color.red, textAlign: "center" }}>
        An error occurred while evaluating the company.
      </Typography>
      <Typography variant="h5" sx={{ color: color.red, textAlign: "center" }}>
        Please try again.
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          navigate("/evaluation");
        }}
        sx={{
          backgroundColor: color.btn_dark,
          color: color.white,
          "&:hover": {
            backgroundColor: color.primary_dark,
            color: color.btn_dark,
          },
          marginTop: "2rem",
        }}
      >
        Go Back
      </Button>
    </Grid>
  );
};
export default EvaluationError;
