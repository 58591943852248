import React, { useEffect } from "react";
import color from "../../Constants/colors";

const ReportAnIssue = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "a1a47368-b491-46aa-a1f7-fd14dae8ff21";
    script.src =
      "https://baustela.youtrack.cloud/static/simplified/form/form-entry.js";
    script.setAttribute("data-yt-url", "https://baustela.youtrack.cloud");
    script.setAttribute("data-theme", "light");
    script.setAttribute("data-lang", "en");

    const container = document.getElementById("script-container");
    if (container) {
      container.appendChild(script);
    }

    return () => {
      if (container) {
        container.removeChild(script);
      }
    };
  }, []);

  return (
    <div
      style={{
        background: `linear-gradient(to bottom, rgba(3, 46, 77, 0.85), rgba(6, 70, 116, 0.85)), url(/background.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <div
        id="script-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: color.white,
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      ></div>
    </div>
  );
};

export default ReportAnIssue;
