import axios from "axios";
import { EvaluationResponse, EvaluationHistory } from "../../Models/evaluation";

export const getEvaluation = async (
  evaluationId: string
): Promise<EvaluationResponse> => {
  const { data } = await axios.get<EvaluationResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/evaluation/${evaluationId}/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return data;
};

export const deleteEvaluation = async (evaluationId: string): Promise<void> => {
  await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/evaluation/${evaluationId}/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
};

export const getEvaluationHistory = async (
  companyId: string,
  page: number,
  rowsPerPage: number,
  search: string = "",
  sort_order: string = "created_at",
  sort: "asc" | "desc" = "asc"
): Promise<EvaluationHistory> => {
  const { data } = await axios.get<EvaluationHistory>(
    `${process.env.REACT_APP_BASE_URL}/v1/evaluation/history/${companyId}/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
      params: {
        page: page + 1,
        page_size: rowsPerPage,
        search: search,
        sort_order: "created_at",
        sort_direction: sort,
      },
    }
  );
  return data;
};


export const downloadEvaluation = async (
  user_evaluation_request_id: string,
): Promise<Blob> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/evaluation/history/${user_evaluation_request_id}/download`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
      responseType: "blob",
    }
  );

  return response.data;
}
