import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Box,
  Collapse,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Features } from "../../Constants/Enums/companyStatus";
import color from "../../Constants/colors";
import { getFeatures, Feature } from "../../Services/Https/features";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { enqueueSnackbar } from "notistack";

interface FeaturesProps {
  selectedFeatures: number[];
  setSelectedFeatures: React.Dispatch<React.SetStateAction<number[]>>;
}

const FeaturesComponent: React.FC<FeaturesProps> = ({
  selectedFeatures,
  setSelectedFeatures,
}) => {
  const [features, setFeatures] = useState<Feature[]>([]);
  const [expandAll, setExpandAll] = useState<boolean>(false);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const data = await getFeatures();
        setFeatures(data.features);
      } catch (error) {
        enqueueSnackbar("Failed to fetch features.", { variant: "error" });
      }
    };
    fetchFeatures();
  }, []);

  const handleToggleExpandAll = () => {
    setExpandAll((prev) => !prev);
  };

  const handleSelectFeature = (featureId: number) => {
    const updatedFeatures = selectedFeatures.includes(featureId)
      ? selectedFeatures.filter((id) => id !== featureId)
      : [...selectedFeatures, featureId];
    setSelectedFeatures(updatedFeatures);
  };

  return (
    <Grid item xs={12} mt={1}>
      <Typography
        sx={{
          color: color.white,
          borderBottom: "2px solid white",
          lineHeight: "32px",
          marginBottom: "10px",
        }}
      >
        Select Features
      </Typography>
      <Grid container spacing={2}>
        {Object.entries(Features)
          .filter(([key]) => isNaN(Number(key)))
          .map(([key, value]) => (
            <Grid item key={value} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  transition: "background-color 0.3s ease",
                  backgroundColor: selectedFeatures.includes(value as number)
                    ? color.btn_dark
                    : color.white,
                }}
              >
                <CardActionArea
                  onClick={() => handleSelectFeature(value as number)}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box sx={{ position: "relative" }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "1rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {key.replace(/_/g, " ")}
                      </Typography>
                      <Collapse in={expandAll} timeout="auto" unmountOnExit>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "0.75rem",
                            color: color.primary_dark,
                          }}
                        >
                          {
                            features.find((feature) => feature.id === value)
                              ?.description
                          }
                        </Typography>
                      </Collapse>
                    </Box>
                  </CardContent>
                </CardActionArea>

                <IconButton
                  sx={{
                    marginLeft: "auto",
                    transform: expandAll ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleExpandAll();
                  }}
                >
                  <ExpandMoreIcon sx={{ color: color.primary_dark }} />
                </IconButton>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default FeaturesComponent;
