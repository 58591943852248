import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "../../Styles/HomePage.css";
import { fetchEvaluate } from "../../Services/Https/llm";
import { User } from "../../Models/user";
import { ModelLevel, UserRole } from "../../Constants/Enums/companyStatus";
import color from "../../Constants/colors";
import { useCheckProgress, useCompanies } from "../../Hooks/useCompanies";
import { CompanyStatus } from "../../Constants/Enums/companyStatus";
import { CompanyInfo } from "../../Models/company";
import CategoryAndQuestionForm from "./CategoryAndQustionForm";
import EvaluateButton from "./EvaluateButton";
import SelectCompany from "./SelectCompany";
import LanguageSelect from "./LanguageSelect";
import theme from "../../Constants/theme";
import { useEvaluationModel } from "../../Hooks/useEvaluation";
import { evaluationModel } from "../../Models/evaluationModel";
import { useCompanyContext } from "../../contexts/SelectedCompanyContext";
import { hasRole } from "../../helpers/helpers";

interface HomeComponentProps {
  user: User | undefined;
}

const HomeComponent: React.FC<HomeComponentProps> = ({ user }) => {
  const [alanExpertEnabled, setAlanExpertEnabled] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyInfo>();
  const [selectedModelId, setSelectedModelId] = useState<number | "">("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isEvaluateLoading, setIsEvaluateLoading] = useState(false);
  const [languageId, setLanguageId] = useState<number>(0);
  const [chunkSize] = useState(3000);
  const [chunkOverlap] = useState(700);
  const [certificationId, setCertificationId] = useState("");
  const [questionListId, setQuestionListId] = useState("");
  const [modelOptions, setModelOptions] = useState<evaluationModel[]>([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedCompanyId, setSelectedCompanyId } = useCompanyContext();

  const { data: useEvaluationModelData, isLoading: useEvaluationModelLoading } =
    useEvaluationModel();

  const {
    data: companyResponse,
    refetch: refetchCompanies,
    isLoading: areCompaniesLoading,
  } = useCompanies(1, 20, "name", "asc", "");

  const { data: checkProgressData, refetch: refetchCheckProgressData } =
    useCheckProgress();

  const evaluationId = checkProgressData?.company?.id?.toString();

  useEffect(() => {
    setAlanExpertEnabled(hasRole(UserRole.ADMIN));
  }, []);

  useEffect(() => {
    if (useEvaluationModelData && user) {
      const userLevel = user.model_level as ModelLevel;
      const userModelAccess = useEvaluationModelData.model_access.find(
        (level) => level.level_id === userLevel
      );

      setModelOptions(userModelAccess ? userModelAccess.models : []);
    }
  }, [useEvaluationModelData, user]);

  useEffect(() => {
    const hasRedirectedKey = `hasRedirected_${evaluationId}`;
    const hasRedirected = sessionStorage.getItem(hasRedirectedKey) === "true";

    if (checkProgressData?.in_progress && !hasRedirected) {
      sessionStorage.setItem(hasRedirectedKey, "true");
      navigate(`/evaluation_in_progress/${evaluationId}`);
    }

    if (!checkProgressData?.in_progress) {
      sessionStorage.removeItem(hasRedirectedKey);
    }
  }, [checkProgressData?.in_progress, evaluationId, navigate]);

  const handleToggleChange = (newValue: boolean) => {
    setAlanExpertEnabled(newValue);
  };

  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    setSelectedCompanyId(event.target.value as number | "");
  };

  const handleModelChange = (event: SelectChangeEvent<number>) => {
    setSelectedModelId(Number(event.target.value));
  };

  const handleLanguageChange = (id: number) => {
    setLanguageId(id);
  };

  const navigateToCreateCompany = () => {
    navigate("/company/create");
  };

  const handleEvaluateClick = async (
    questionListId: number,
    certificationId: number
  ): Promise<void> => {
    setCompany(undefined);
    setIsButtonClicked(true);
    setIsEvaluateLoading(true);

    if (selectedModelId !== "" && selectedCompanyId) {
      try {
        await fetchEvaluate(
          selectedCompanyId,
          selectedModelId,
          chunkSize,
          chunkOverlap,
          questionListId,
          certificationId,
          languageId,
          alanExpertEnabled
        );
        setLanguageId(languageId);
        refetchCheckProgressData();
      } catch (error: any) {
        setIsEvaluateLoading(false);

        enqueueSnackbar(error.message, { variant: "error" });
      }
    } else {
      setIsEvaluateLoading(false);
      enqueueSnackbar(
        "Please select both a company and a model before evaluating.",
        { variant: "warning" }
      );
    }
  };

  if (!user) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={{
        width: "100vw",
        margin: "0 auto",
        pt: "2vh",
      }}
      alignContent="center"
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h2" sx={{ color: color.white }}>
          Evaluate Your Company
        </Typography>
      </Grid>
      <Grid item width="50%" sx={{ mt: { xs: 3, sm: 4 } }}>
        <Divider
          sx={{
            margin: "auto",
            width: "100%",
            borderColor: color.accent,
          }}
        />
      </Grid>

      {/* <EvaluationLegent /> */}

      <SelectCompany
        selectedCompanyId={selectedCompanyId}
        handleSelectChange={handleSelectChange}
        companyResponse={companyResponse}
        isEvaluateLoading={isEvaluateLoading}
        navigateToCreateCompany={navigateToCreateCompany}
        onRefetchCompanies={refetchCompanies}
        areCompaniesLoading={areCompaniesLoading}
      />

      <Grid item sx={{ width: { xs: "90%", sm: "70%", md: "30%" }, mt: 5 }}>
        <FormControl fullWidth>
          <InputLabel
            sx={{
              color: theme.palette.primary.main,
              fontFamily: theme.typography.body1.fontFamily,
              fontWeight: theme.typography.body1.fontWeight,
              fontSize: theme.typography.body1.fontSize,
              letterSpacing: theme.typography.body1.letterSpacing,
              lineHeight: theme.typography.body1.lineHeight,
              "&.Mui-focused": {
                color: theme.palette.primary.main,
              },
            }}
            id="model-select-label"
          >
            Select Evaluation Model
          </InputLabel>
          <Select
            labelId="model-select-label"
            id="model-select"
            value={selectedModelId}
            label="Select Evaluation Model"
            onChange={handleModelChange}
            disabled={isEvaluateLoading || useEvaluationModelLoading}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: color.primary_dark,
                  opacity: 0.95,
                },
              },
            }}
          >
            {modelOptions.map((model) => (
              <MenuItem key={model.model_id} value={model.model_id}>
                <Typography
                  variant="body1"
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  {model.model_name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sx={{ width: { xs: "90%", sm: "70%", md: "30%" }, mt: 5 }}>
        <CategoryAndQuestionForm
          certificationId={certificationId}
          setCertificationId={setCertificationId}
          questionListId={questionListId}
          setQuestionListId={setQuestionListId}
          userId={user?.id}
          isEvaluateLoading={isEvaluateLoading}
          selectedModelId={selectedModelId}
        />
      </Grid>

      <Grid item width="50%" sx={{ mt: 6 }}>
        <Divider
          sx={{ margin: "auto", width: "100%", borderColor: color.accent }}
        />
        <LanguageSelect
          languageId={languageId}
          onLanguageChange={handleLanguageChange}
          onToggleAlanExpertChange={handleToggleChange}
          showToggleAlanExpert={hasRole(UserRole.ADMIN)}
        />
      </Grid>

      <Grid item>
        {!isEvaluateLoading &&
          (!company ||
            company.status === CompanyStatus.ERROR ||
            company.status === CompanyStatus.TIME_LIMIT_EXCEEDED) && (
            <EvaluateButton
              handleEvaluateClick={handleEvaluateClick}
              selectedCompanyId={selectedCompanyId}
              selectedModelId={selectedModelId}
              certificationId={certificationId}
              questionListId={questionListId}
              isButtonClicked={isButtonClicked}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default HomeComponent;
