import React from "react";
import { Box, Typography, Avatar, styled } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Workspace } from "../../Models/workspace";
import color from "../../Constants/colors";

const InfoRow = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  justifyContent: "center",
  marginBottom: "0.8rem",
}));

interface WorkspaceInformationProps {
  workspaceData: Workspace;
}

const WorkspaceInformation: React.FC<WorkspaceInformationProps> = ({
  workspaceData,
}) => {
  const { name, description, icon_image, workspace_admin } = workspaceData;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: color.primary_dark,
        color: color.white,
        borderRadius: "8px",
        minHeight: "90vh",
        justifyContent: "flex-start ",
        width: "100%",
        paddingTop: { xs: "10px", md: "0px" },
      }}
    >
      <Avatar
        src={icon_image || undefined}
        alt={name}
        sx={{
          bgcolor: color.btn_dark,
          width: 180,
          height: 180,
          fontSize: "4.5rem",
          marginBottom: "1rem",
        }}
      >
        {!icon_image && name.charAt(0)}
      </Avatar>

      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          marginBottom: "0.5rem",
          textAlign: "center",
        }}
      >
        {name}
      </Typography>

      <Typography
        variant="body1"
        sx={{ marginBottom: "3rem", textAlign: "center" }}
      >
        {description || "No description provided."}
      </Typography>

      <InfoRow>
        <PersonIcon />
        <Typography variant="body1">
          Workspace Admin:{" "}
          {workspace_admin
            ? `${workspace_admin.name} ${workspace_admin.surname}`
            : "Not assigned"}
        </Typography>
      </InfoRow>
    </Box>
  );
};

export default WorkspaceInformation;
