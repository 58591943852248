import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
} from "@mui/material";
import color from "../../Constants/colors";

interface ConfirmationDialogProps {
  open: boolean;
  message: string;
  workspaceName: string;
  onClose: () => void;
  onConfirm: (workspaceName: string) => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  message,
  workspaceName,
  onClose,
  onConfirm,
}) => {
  const [enteredName, setEnteredName] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredName(event.target.value);
    setError(false);
  };

  const handleConfirm = () => {
    if (enteredName === workspaceName) {
      onConfirm(enteredName);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent sx={{ padding: "20px", color: color.primary_dark }}>
        <DialogContentText sx={{ color: color.primary_dark }}>
          {message}
        </DialogContentText>
        <TextField
          label="Enter Workspace Name"
          variant="outlined"
          fullWidth
          value={enteredName}
          onChange={handleChange}
          error={error}
          helperText={error ? "Names do not match" : ""}
          sx={{
            marginTop: "15px",
            "& .MuiInputBase-input": {
              color: color.primary_dark,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color.btn_dark,
              },
              "&:hover fieldset": {
                borderColor: color.btn_dark,
              },
              "&.Mui-focused fieldset": {
                borderColor: color.btn_dark,
              },
            },
            "& .MuiInputLabel-root": {
              color: color.primary_dark,
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: color.primary_dark,
            },
            "& .css-1ruifsz-MuiFormLabel-root-MuiInputLabel-root[data-shrink='false']":
              {
                color: color.primary_light,
              },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          No
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={enteredName !== workspaceName}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
