import React, { useState } from "react";
import { Box, TextField, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import colors from "../../Constants/colors";

interface NamespaceFieldProps {
  namespaces: string[];
  onChange: (namespaces: string[]) => void;
}

const NamespaceField: React.FC<NamespaceFieldProps> = ({
  namespaces,
  onChange,
}) => {
  const [namespaceInput, setNamespaceInput] = useState("");

  const handleAddNamespace = () => {
    if (!namespaceInput.trim()) return;

    onChange([...namespaces, namespaceInput.trim()]);
    setNamespaceInput("");
  };

  const handleRemoveNamespace = (namespace: string) => {
    onChange(namespaces.filter((ns) => ns !== namespace));
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        <TextField
          label="Add Namespace"
          name="namespaceInput"
          value={namespaceInput}
          onChange={(e) => setNamespaceInput(e.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true,
            sx: { color: colors.white },
          }}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          onClick={handleAddNamespace}
          sx={{
            backgroundColor: colors.btn_dark,
            color: colors.white,
            "&:hover": {
              backgroundColor: colors.primary_dark,
            },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>

      <Box
        mt={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={1}
      >
        {(Array.isArray(namespaces) ? namespaces : []).map(
          (namespace, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="60%"
              sx={{
                backgroundColor: colors.primary_dark,
                padding: "8px 16px",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: colors.white,
                  textAlign: "center",
                }}
              >
                {namespace}
              </Typography>

              <IconButton
                onClick={() => handleRemoveNamespace(namespace)}
                sx={{
                  color: colors.red,
                  "&:hover": {
                    backgroundColor: "rgba(255, 0, 0, 0.1)",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

export default NamespaceField;
