import axios from "axios";
import { User, UserResponse } from "../../Models/user";
import { ModelLevel } from "../../Constants/Enums/companyStatus";

export const updateUser = async (
  name: string,
  surname: string,
  email: string
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/user/edit/`,
    {
      name: name,
      email: email,
      surname: surname,
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response;
};

export const changePassword = async (
  currentPassword: string,
  newPassword: string
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/user/change-password/`,
    {
      current_password: currentPassword,
      new_password: newPassword,
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response;
};

export const getUsers = async (
  page: number = 1,
  page_size: number = 10,
  search: string = "",
  workspace?: number
): Promise<UserResponse> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/user/users/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
      params: { page, page_size, search, workspace },
    }
  );
  return response.data;
};

export const createUser = async (
  name: string,
  surname: string,
  password: string | null,
  email: string,
  daily_limit: number,
  max_companies: number,
  model_level: ModelLevel,
  cert_category_ids: number[],
  is_sso: boolean,
  workspace_id: number | null,
  features: number[]
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/user/register/`,
    {
      name,
      surname,
      password,
      email,
      daily_limit,
      companies_limit: max_companies,
      model_level,
      cert_category_ids,
      is_sso,
      workspace_id,
      features,
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response;
};

export const editUserById = async (
  userId: string,
  name: string,
  surname: string,
  email: string,
  daily_limit: number,
  companies_limit: number,
  password: string,
  model_level: ModelLevel,
  cert_category_ids: number[],
  workspace_id: number | null,
  features: number[]
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/user/${userId}/`,
    {
      name,
      surname,
      email,
      daily_limit,
      companies_limit,
      password,
      model_level,
      cert_category_ids,
      workspace_id,
      features,
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response;
};

export const getUserById = async (userId: string): Promise<User> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/user/${userId}/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};

export const getMySelf = async (): Promise<User> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/user/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};

export const deleteUserById = async (userId: string) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/user/${userId}/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response;
};
