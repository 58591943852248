import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import WestSharpIcon from "@mui/icons-material/WestSharp";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import color from "../../Constants/colors";
import {
  Button,
  Typography,
  TextField,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  useGetAllReleaseNotes,
  useCreateReleaseNote,
  useDeleteReleaseNote,
  useUpdateReleaseNote,
} from "../../Hooks/useReleaseNote";
import { ReleaseNote } from "../../Models/releaseNote";
import { hasRole } from "../../helpers/helpers";
import { UserRole } from "../../Constants/Enums/companyStatus";

const ReleaseNotes: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedNote, setSelectedNote] = useState<ReleaseNote | null>(null);
  const [noteData, setNoteData] = useState({
    title: "",
    description: "",
    category: "",
  });
  const [confirmDeleteNote, setConfirmDeleteNote] = useState<number | null>(
    null
  );
  const [showForm, setShowForm] = useState(false);

  const notesPerPage = 10;

  const {
    data: releaseNotesData,
    isLoading,
    isError,
  } = useGetAllReleaseNotes(currentPage + 1, notesPerPage);
  const { mutate: createReleaseNote } = useCreateReleaseNote();
  const { mutate: deleteReleaseNote } = useDeleteReleaseNote();
  const { mutate: updateReleaseNote } = useUpdateReleaseNote();

  const handleSaveNote = () => {
    if (selectedNote) {
      updateReleaseNote({
        id: selectedNote.id,
        data: noteData,
      });
    } else {
      createReleaseNote(noteData);
    }
    clearForm();
  };

  const handleEditClick = (note: ReleaseNote) => {
    setSelectedNote(note);
    setNoteData({
      title: note.title,
      description: note.description,
      category: note.category,
    });
    setShowForm(true);
  };

  const handleDeleteClick = (id: number) => {
    setConfirmDeleteNote(id);
  };

  const confirmDelete = () => {
    if (confirmDeleteNote !== null) {
      deleteReleaseNote(confirmDeleteNote);
      if (selectedNote?.id === confirmDeleteNote) clearForm();
      setConfirmDeleteNote(null);
    }
  };

  const cancelDelete = () => {
    setConfirmDeleteNote(null);
  };

  const clearForm = () => {
    setSelectedNote(null);
    setNoteData({
      title: "",
      description: "",
      category: "",
    });
    setShowForm(false);
  };

  const getCurrentNotes = (): ReleaseNote[] => {
    if (!releaseNotesData) return [];
    return releaseNotesData.release_notes || [];
  };

  const totalNotes = releaseNotesData?.total || 0;

  const notesToShow = getCurrentNotes().length;

  const startRange = currentPage * notesPerPage + 1;

  const endRange = Math.min(startRange + notesToShow - 1, totalNotes);

  const handleNextPage = () => {
    if ((currentPage + 1) * notesPerPage < totalNotes) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (isError) return <p>Error loading release notes</p>;

  return (
    <div
      style={{
        backgroundColor: color.primary_medium,
        padding: "20px",
        borderRadius: "12px",
        color: color.white,
        height: "100vh",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <Typography
        variant="h4"
        style={{
          color: color.accent,
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        What's New?
        {hasRole(UserRole.ADMIN) && (
          <AddIcon
            style={{
              cursor: "pointer",
              fontSize: "35px",
              color: color.accent,
              marginLeft: "auto",
            }}
            onClick={() => setShowForm(true)}
          />
        )}
      </Typography>

      {hasRole(UserRole.ADMIN) && showForm && (
        <div
          style={{
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            backgroundColor: color.secondary,
            padding: "10px",
            borderRadius: "8px",
            borderLeft: `5px solid ${color.accent}`,
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: color.white,
              marginBottom: "8px",
              fontWeight: "bold",
            }}
          >
            {selectedNote ? "Edit Note" : "Add New Note"}
          </Typography>
          <TextField
            label="Title"
            variant="outlined"
            value={noteData.title}
            onChange={(e) =>
              setNoteData({ ...noteData, title: e.target.value })
            }
            fullWidth
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              marginBottom: "8px",
              fontSize: "0.9rem",
            }}
            inputProps={{
              style: { fontSize: "0.9rem", padding: "8px", color: "black" },
            }}
            InputLabelProps={{
              style: {
                color: color.primary_medium,
                fontSize: "0.9rem",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: color.primary_medium,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: color.primary_medium,
              },
            }}
          />

          <TextField
            label="Description"
            variant="outlined"
            value={noteData.description}
            onChange={(e) =>
              setNoteData({ ...noteData, description: e.target.value })
            }
            fullWidth
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              marginBottom: "8px",
              fontSize: "0.9rem",
            }}
            inputProps={{
              style: { fontSize: "0.9rem", padding: "8px", color: "black" },
            }}
            InputLabelProps={{
              style: {
                color: color.primary_medium,
                fontSize: "0.9rem",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: color.primary_medium,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: color.primary_medium,
              },
            }}
          />

          <TextField
            label="Category"
            variant="outlined"
            value={noteData.category}
            onChange={(e) =>
              setNoteData({ ...noteData, category: e.target.value })
            }
            fullWidth
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              marginBottom: "12px",
              fontSize: "0.9rem",
            }}
            inputProps={{
              style: { fontSize: "0.9rem", padding: "8px", color: "black" },
            }}
            InputLabelProps={{
              style: {
                color: color.primary_medium,
                fontSize: "0.9rem",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: color.primary_medium,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: color.primary_medium,
              },
            }}
          />

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <IconButton
              style={{
                backgroundColor: color.secondary,
                color: color.white,
                padding: "6px",
                minWidth: "auto",
              }}
              onClick={clearForm}
            >
              <CloseIcon style={{ fontSize: "16px" }} />
            </IconButton>
            <Button
              variant="contained"
              style={{
                backgroundColor: color.accent,
                color: color.white,
                padding: "4px 8px",
                minWidth: "auto",
                fontSize: "0.85rem",
              }}
              onClick={handleSaveNote}
            >
              {selectedNote ? (
                <SaveIcon style={{ color: color.white, fontSize: "16px" }} />
              ) : (
                <CheckIcon style={{ color: color.white, fontSize: "16px" }} />
              )}
            </Button>
          </div>
        </div>
      )}

      <ul style={{ listStyleType: "none", padding: 0 }}>
        {getCurrentNotes().map((item) => (
          <li
            key={item.id}
            style={{
              margin: "10px 0",
              padding: "12px",
              backgroundColor: color.secondary,
              borderRadius: "8px",
              color: color.white,
              borderLeft: `5px solid ${color.accent}`,
              fontSize: "16px",
            }}
          >
            {confirmDeleteNote === item.id ? (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingBottom: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "10px", fontSize: "14px" }}
                  >
                    ARE YOU SURE YOU WANT TO DELETE THIS NOTE?
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      paddingRight: "5px",
                    }}
                  >
                    <Button
                      sx={{
                        backgroundColor: color.btn_dark,
                        color: color.white,
                        marginBottom: "1px",
                        marginRight: "10px",
                        width: "30px",
                        padding: "3px",
                        "&:hover": {
                          backgroundColor: color.primary_dark,
                          color: color.btn_dark,
                        },
                      }}
                      onClick={cancelDelete}
                    >
                      NO
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: color.btn_dark,
                        color: color.white,
                        marginBottom: "1px",
                        width: "30px",
                        padding: "3px",
                        "&:hover": {
                          backgroundColor: color.primary_dark,
                          color: color.btn_dark,
                        },
                      }}
                      onClick={confirmDelete}
                    >
                      YES
                    </Button>
                  </Box>
                </Box>
              </div>
            ) : (
              <>
                <strong>{item.title}</strong>: {item.description}{" "}
                <em>({item.category})</em>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{ fontSize: "0.85em", color: color.primary_light }}
                  >
                    Created at{" "}
                    {new Date(item.created_at).toLocaleDateString("en-GB")}
                  </span>
                  {hasRole(UserRole.ADMIN) && (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <EditIcon
                        style={{ cursor: "pointer", color: color.orange }}
                        onClick={() => handleEditClick(item)}
                      />
                      <DeleteIcon
                        style={{ cursor: "pointer", color: color.red }}
                        onClick={() => handleDeleteClick(item.id)}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </li>
        ))}
      </ul>

      <div
        style={{
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          backgroundColor: color.primary_medium,
          padding: "10px",
          zIndex: 1000,
          marginTop: "-20px",
        }}
      >
        <Button
          onClick={handlePreviousPage}
          disabled={currentPage === 0}
          style={{
            backgroundColor: color.primary_medium,
            color: color.white,
            padding: "5px 10px",
            minWidth: "40px",
          }}
        >
          {currentPage > 0 && <WestSharpIcon />}
        </Button>
        <Typography
          style={{
            color: color.white,
            fontSize: "18px",
            fontWeight: "500",
            whiteSpace: "nowrap",
          }}
        >
          Showing {startRange}-{endRange} of {totalNotes}
        </Typography>
        <Button
          onClick={handleNextPage}
          disabled={(currentPage + 1) * notesPerPage >= totalNotes}
          style={{
            backgroundColor: color.primary_medium,
            color: color.white,
            padding: "5px 10px",
            minWidth: "40px",
          }}
        >
          {(currentPage + 1) * notesPerPage < totalNotes && <EastSharpIcon />}
        </Button>
      </div>
    </div>
  );
};

export default ReleaseNotes;
