import React from "react";
import MyQuestions from "../../Components/MyQuestions/MyQuestions";

const MyQuestionsPage: React.FC = () => {
  return (
    <div>
      <MyQuestions />
    </div>
  );
};

export default MyQuestionsPage;
