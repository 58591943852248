import axios from "axios";
import { ExpertFormState, ExpertResponse } from "../../Models/expert";

export const createExpert = async (
  newExpert: ExpertFormState
): Promise<void> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/expert/order/`,
    {
      name: newExpert.expertName,
      professional_background: newExpert.professionalBackground,
      key_expertise: newExpert.keyExpertise,
      training_and_certifications: newExpert.trainingAndCertifications,
      process_mastery: newExpert.processMastery,
      primary_responsibilities: newExpert.primaryResponsibilities,
      problem_solving_abilities: newExpert.problemSolvingAbilities,
      knowledge_depth: newExpert.knowledgeDepth,
      knowledge_base_links: newExpert.knowledgebase,
      trends_links: newExpert.trends,
      model_behaviour: newExpert.modelBehavior,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("cih_token")}`,
      },
    }
  );
  return data;
};

export const getExperts = async () => {
  const response = await axios.get<ExpertResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/expert/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};
