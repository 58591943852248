import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { Website } from "../../Models/website";
import color from "../../Constants/colors";
import { formatDate } from "../../helpers/helpers";
import { ProcessingStatus } from "../../Constants/Enums/companyStatus";

export interface WebsiteTableRowProps {
  website: Website;
  onHandleDeleteWebsite: (website_id: number) => Promise<void>;
  convertStatus: (status: number, websiteId: number) => React.JSX.Element;
}

const WebsiteTableRow: React.FC<WebsiteTableRowProps> = ({
  website,
  onHandleDeleteWebsite,
  convertStatus,
}) => {
  const formattedWebsite = formatDate(website.created_at);

  return (
    <TableRow
      key={website.id}
      sx={{
        "&:hover": {
          backgroundColor: color.secondaryDark,
        },
      }}
    >
      <TableCell>
        <a href={website.web_adress}>
          <Tooltip title={`Visit ${website.web_adress}`}>
            <Typography variant="caption" sx={{ color: color.textPrimary }}>
              {website.name}
            </Typography>
          </Tooltip>
        </a>
      </TableCell>
      <TableCell>{convertStatus(website.status, website.id)}</TableCell>
      <TableCell>
        <Typography variant="caption" sx={{ color: color.textPrimary }}>
          {formattedWebsite}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title="Delete website">
          <IconButton
            onClick={() => onHandleDeleteWebsite(website.id)}
            disabled={website.status === ProcessingStatus.IN_PROGRESS}
            sx={{
              opacity:
                website.status === ProcessingStatus.IN_PROGRESS ? 0.5 : 1,
            }}
          >
            <DeleteIcon sx={{ color: color.red }} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default WebsiteTableRow;
