import React from "react";
import ReportAnIssue from "../../Components/ReportAnIssue/ReportAnIssue";

const ReportIssuePage = () => {
  return (
    <div>
      <ReportAnIssue />
    </div>
  );
};

export default ReportIssuePage;
