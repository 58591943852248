import {
  Grid,
  Paper,
  TextField,
  Avatar,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { passwordResetSubmit } from "../../Services/Https/auth";
import color from "../../Constants/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

const PasswordResetSubmit = () => {
  const paperStyle = {
    padding: 20,
    backgroundColor: "rgba(255, 255, 255)",
    margin: "0 auto",
  };

  const avatarStyle = {
    backgroundColor: color.white,
    marginInline: "auto",
    marginBottom: "8px",
    border: `2px solid ${color.primary_dark}`,
  };
  const btnstyle = { margin: "8px 0" };
  const textFieldStyle = { margin: "8px 0" };
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const tokenParam = searchParams.get("token");
    setToken(tokenParam);
  }, [enqueueSnackbar, searchParams, setSearchParams]);

  const handleChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
  };

  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  const handlePasswordResetSubmit = async (
    event: React.FormEvent<HTMLElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);
    if (newPassword === confirmPassword) {
      try {
        await passwordResetSubmit(newPassword, token);
        enqueueSnackbar("Password reseted successfully!", {
          variant: "success",
        });
        navigate("/login");
      } catch (error) {
        enqueueSnackbar("Password Reset Failed", { variant: "error" });
        setToken(null);
      }
    } else {
      enqueueSnackbar("Passwords Must Match!", { variant: "error" });
    }

    setIsLoading(false);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: color.primary_dark,
      }}
    >
      <Paper
        elevation={0}
        style={paperStyle}
        sx={{
          maxWidth: { xs: "80%", sm: 400, md: 500 },
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Avatar style={avatarStyle}>
            <img
              src="/commo_logo.png"
              alt="Logo"
              style={{ width: "100%", height: "auto" }}
            />
          </Avatar>
          <Typography variant="h4" sx={{ color: color.btn_dark }}>
            {token ? "Set new password for your account" : "Error"}
          </Typography>
        </Grid>
        {token ? (
          <>
            <form onSubmit={handlePasswordResetSubmit}>
              <TextField
                label="New password"
                type={showNewPassword ? "text" : "password"}
                variant="outlined"
                fullWidth
                style={textFieldStyle}
                required
                value={newPassword}
                onChange={handleChangeNewPassword}
                sx={{
                  "& .MuiInputBase-root.MuiOutlinedInput-root input": {
                    color: `${color.primary_dark} !important`,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? (
                          <Visibility style={{ color: color.btn_dark }} />
                        ) : (
                          <VisibilityOff style={{ color: color.btn_dark }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    borderColor: `${color.btn_dark} !important`,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${color.btn_dark} !important`,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${color.btn_dark} !important`,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${color.btn_dark} !important`,
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: `${color.black} !important`,
                  },
                }}
              />
              <TextField
                label="Confirm new password"
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                fullWidth
                style={textFieldStyle}
                required
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                sx={{
                  "& .MuiInputBase-root.MuiOutlinedInput-root input": {
                    color: `${color.primary_dark} !important`,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility style={{ color: color.btn_dark }} />
                        ) : (
                          <VisibilityOff style={{ color: color.btn_dark }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    borderColor: `${color.btn_dark} !important`,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${color.btn_dark} !important`,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${color.btn_dark} !important`,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${color.btn_dark} !important`,
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: `${color.black} !important`,
                  },
                }}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={btnstyle}
                fullWidth
                disabled={isLoading}
                sx={{
                  backgroundColor: color.btn_dark,
                  color: color.white,
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                  textTransform: "none",
                }}
              >
                Submit
              </Button>
            </form>
          </>
        ) : (
          <Typography
            variant="subtitle1"
            sx={{
              color: color.accent_dark,
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            Invalid token presented.
          </Typography>
        )}
      </Paper>
      <Box
        sx={{
          position: "fixed",
          left: 20,
          bottom: 20,
          zIndex: 1100,
        }}
      >
        <img
          src="logo_big.png"
          alt="Big Logo"
          style={{ maxWidth: "250px", maxHeight: "250px" }}
        />
      </Box>
    </Grid>
  );
};

export default PasswordResetSubmit;
