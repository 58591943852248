import React from "react";
import EvaluationError from "../../Components/EvaluationPage/EvaluationError";

const EvaluationErrorPage: React.FC = () => {
  return (
    <div>
      <EvaluationError />
    </div>
  );
};

export default EvaluationErrorPage;
