import axios from "axios";
import { User } from "../../Models/user";

export const login = async (email: string, password: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/auth/login/`,
    {
      email,
      password,
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};

export const passwordResetRequest = async (email: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/auth/password-reset-request/`,
    {
      email,
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};

export const passwordResetSubmit = async (
  password: string,
  token: string | null
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/auth/password-reset-submit/`,
    {
      password,
      token,
    },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};

export const checkAuth = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/auth/check_auth/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response;
};

export const checkAdmin = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/auth/check_admin/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response;
};

export const logout = async () => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/user/logout/`,
    {},
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response;
};

export const getUser = async (): Promise<User> => {
  const { data } = await axios.get<User>(
    `${process.env.REACT_APP_BASE_URL}/v1/user/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return data;
};
