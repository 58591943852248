import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Button } from "@mui/material";

const Unauthorized: React.FC = () => {
  return (
    <Container
      sx={{
        textAlign: "center",
        margin: 0,
        padding: 0,
        backgroundImage: `url(/background.png)`,
        backgroundColor: "#000",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      maxWidth={false}
    >
      <img
        src="logo_big.png"
        alt="Logo"
        style={{
          width: "200px",
          marginBottom: "30px",
          position: "absolute",
          top: "20px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      />

      <Typography
        sx={{
          fontSize: { xs: "40px", md: "70px" },
          color: "#ffffff",
          textShadow: "2px 2px 10px rgba(0, 0, 0, 0.8)",
          fontWeight: "bold",
          marginBottom: "40px",
        }}
      >
        Access Denied
      </Typography>

      <Typography sx={{ color: "#ffffff", marginBottom: "40px" }}>
        You don't have permission to view this page. If you think this is a
        mistake, please reach out to your administrator for assistance.
      </Typography>

      <Link
        to="/"
        aria-label="Go back to home"
        style={{ textDecoration: "none" }}
      >
        <Button
          variant="contained"
          sx={{
            fontSize: "18px",
            color: "#fff",
            backgroundColor: "#F28D01",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
            "&:hover": {
              backgroundColor: "#D78E01",
            },
            padding: "12px 24px",
            borderRadius: "12px",
            transition: "background-color 0.3s ease",
          }}
        >
          Go Back To Home
        </Button>
      </Link>
    </Container>
  );
};

export default Unauthorized;
