import { Grid } from "@mui/material";
import WorkspaceCreator from "../../Components/Workspace/CreateWorkspace";
import React from "react";

const CreateWorkspacePage = () => {
  return (
    <Grid>
      <WorkspaceCreator />
    </Grid>
  );
};

export default CreateWorkspacePage;
