import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { checkAdmin, checkAuth } from "../../Services/Https/auth";
import { CircularProgress } from "@mui/material";

const withAuth = (
  Component: React.ComponentType<any>,
  path: string,
  isCheckAdmin: boolean
) => {
  const WrappedComponent: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingAdmin, setLoadingAdmin] = useState(isCheckAdmin);
    const [hasCORS, setHasCORS] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      const fetchAuth = async () => {
        try {
          const response = await checkAuth();
          setAuth(response.status === 200);
        } catch (error: unknown) {
          if ((error as Error).message === "Network Error") {
            setHasCORS(true); // set hasCORS state if there's a CORS error
          } else {
            setAuth(false);
          }
        } finally {
          setLoading(false); // always set loading to false when done
        }
      };
      const fetchAdmin = async () => {
        try {
          const response = await checkAdmin();
          setAdmin(response.status === 200);
        } catch (error: unknown) {
          if ((error as Error).message === "Network Error") {
            setHasCORS(true); // set hasCORS state if there's a CORS error
          } else {
            setLoadingAdmin(false);
          }
        } finally {
          setLoadingAdmin(false); // always set loading to false when done
        }
      };

      const cihToken = searchParams.get("cih-token");
      if(cihToken) {
        localStorage.setItem("cih_token", cihToken)
        setSearchParams({})
      }

      fetchAuth();
      if (isCheckAdmin) {
        fetchAdmin();
      }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
      if (loading) {
        return () => {}; // no cleanup needed
      }

      if (hasCORS) {
        return () => {}; // no cleanup needed
      }

      if (!auth) {
        navigate(path);
        return () => {}; // cleanup function to cancel navigation
      }

      if (loadingAdmin) {
        return () => {}; // no cleanup needed
      }

      if (isCheckAdmin && !admin) {
        navigate(path);
        return () => {}; // cleanup function to cancel navigation
      }

      // no cleanup needed
      return () => {};
    }, [loading, hasCORS, auth, navigate, admin, loadingAdmin]);
    if (isCheckAdmin) {
      if (loadingAdmin) {
        return <CircularProgress />;
      }
      return auth && admin ? <Component {...props} /> : <CircularProgress />;
    }
    return auth ? <Component {...props} /> : <CircularProgress />; // render wrapped component if authenticated
  };

  WrappedComponent.displayName = `withAuth(${
    Component.displayName || Component.name || "Component"
  })`;

  return <WrappedComponent />;
};
export default withAuth;
