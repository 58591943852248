import { useQuery } from "react-query";
import { getUsers } from "../Services/Https/user";
import { getUser } from "../Services/Https/auth";

export const useUsers = (page: number, page_size: number, search: string) => {
  return useQuery(
    ["users", page, page_size, search],
    () => getUsers(page, page_size, search),
    { keepPreviousData: true }
  );
};

export const useUser = () => {
  return useQuery(["users"], () => getUser(), { keepPreviousData: true });
};
