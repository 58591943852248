import React from "react";
import "./App.css";
import { SnackbarProvider } from "notistack";
import Container from "./Container";
import { QueryClientProvider, QueryClient, setLogger } from "react-query";
import theme from "./Constants/theme";
import { ThemeProvider } from "@mui/material";
import { SelectedCompanyProvider } from "./contexts/SelectedCompanyContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 15 * 60 * 1000,
      staleTime: 0 * 60 * 1000,
      retry: false,
    },
  },
});

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <SelectedCompanyProvider>
            <Container />
          </SelectedCompanyProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
