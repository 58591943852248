import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Avatar,
  Box,
  CircularProgress,
  Paper,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import { useSnackbar } from "notistack";
import colors from "../../Constants/colors";
import { getWorkspace } from "../../Services/Https/workspace";
import { Workspace, GetWorkspaceResponse } from "../../Models/workspace";
import { Edit } from "@mui/icons-material";
import UsersTable from "./WorkspaceUsersTable";
import WorkspaceQuestionListsTable from "./WorkspaceQuestionListTable";
import { ModelLevel } from "../../Constants/Enums/companyStatus";

const SingleWorkspace: React.FC = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [workspaceData, setWorkspaceData] = useState<Workspace | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!workspaceId) {
      setError("Invalid workspace ID.");
      setLoading(false);
      return;
    }

    const fetchWorkspace = async () => {
      try {
        setLoading(true);
        const data: GetWorkspaceResponse = await getWorkspace(workspaceId);

        setWorkspaceData({
          ...data.workspace,
          permissions: data.permissions || [],
          workspace_certifications: data.workspace_certifications || [],
          workspace_question_lists: data.workspace_question_lists || [],
        });
      } catch (err) {
        setError("Failed to fetch workspace details.");
      } finally {
        setLoading(false);
      }
    };

    fetchWorkspace();
  }, [workspaceId]);

  const parseNamespaces = (namespaces: any): string[] => {
    if (Array.isArray(namespaces)) {
      return namespaces;
    }
    if (typeof namespaces === "string") {
      try {
        return JSON.parse(namespaces.replace(/'/g, '"'));
      } catch {
        enqueueSnackbar("Failed to parse namespaces.", { variant: "error" });
      }
    }
    return [];
  };

  const handleEdit = () => {
    if (workspaceData) {
      const parsedNamespaces = parseNamespaces(workspaceData.namespaces);
      navigate("/workspace/create", {
        state: {
          workspace: {
            ...workspaceData,
            namespaces: parsedNamespaces,
          },
        },
      });
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h4" sx={{ color: colors.red }}>
          {error}
        </Typography>
      </Container>
    );
  }

  if (!workspaceData) {
    return (
      <Container>
        <Typography variant="h4" sx={{ color: colors.red }}>
          Workspace data not available.
        </Typography>
      </Container>
    );
  }

  const {
    name,
    description,
    icon_image,
    namespaces,
    user_company_limit,
    user_evaluation_limit,
    workspace_admin,
    created_at,
    permissions = [],
    workspace_certifications = [],
    model_level,
  } = workspaceData;

  const formatDate = (dateString: string) => {
    if (!dateString) return "Not Available";
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : date.toLocaleString();
  };

  const renderNamespaces = () => {
    if (!namespaces) {
      return "No namespaces available.";
    }

    if (Array.isArray(namespaces)) {
      return namespaces.length > 0
        ? namespaces.join(", ")
        : "No namespaces available.";
    }

    if (typeof namespaces === "string") {
      try {
        const parsedNamespaces = JSON.parse(namespaces.replace(/'/g, '"'));
        if (Array.isArray(parsedNamespaces) && parsedNamespaces.length > 0) {
          return parsedNamespaces.join(", ");
        }
        return "No namespaces available.";
      } catch {
        enqueueSnackbar("Invalid namespaces format.", { variant: "error" });
        return "Invalid namespaces format.";
      }
    }

    return "Invalid namespaces data.";
  };
  const modelLevelToString = (level: number) => {
    switch (level) {
      case ModelLevel.BASIC:
        return "BASIC";
      case ModelLevel.ENTRY:
        return "ENTRY";
      case ModelLevel.FULL:
        return "FULL";
      default:
        return "Unknown";
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          backgroundColor: colors.primary_dark,
          color: colors.white,
          borderRadius: 2,
        }}
      >
        <Grid container spacing={4} sx={{ position: "relative" }}>
          <Grid item xs={12} md={4}>
            {icon_image ? (
              <Avatar
                src={`data:image/png;base64,${icon_image}`}
                alt={name || "Workspace Icon"}
                sx={{
                  width: 150,
                  height: 150,
                  margin: "0 auto",
                  border: "2px solid white",
                }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 150,
                  height: 150,
                  margin: "0 auto",
                  backgroundColor: colors.btn_dark,
                  color: colors.primary_dark,
                  fontSize: 48,
                }}
              >
                {name?.charAt(0).toUpperCase() || "N"}
              </Avatar>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              {name || "No Name"}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontStyle: "italic", marginBottom: 2 }}
            >
              {description || "No Description"}
            </Typography>
            <Typography variant="caption" sx={{ fontSize: "0.75rem" }}>
              Created At: {formatDate(created_at)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
            }}
          >
            <Edit
              onClick={handleEdit}
              sx={{
                cursor: "pointer",
                color: colors.btn_dark,
                fontSize: 32,
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ marginY: 4 }} />

        <Box sx={{ marginBottom: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              borderBottom: "2px solid",
              borderBottomColor: colors.btn_dark,
              paddingBottom: 1,
            }}
          >
            Namespaces
          </Typography>
          <Typography variant="body1" sx={{ paddingLeft: 2 }}>
            {renderNamespaces()}
          </Typography>
        </Box>

        <Divider sx={{ marginY: 4 }} />

        <Box
          sx={{
            textAlign: "center",
            marginBottom: 4,
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              borderBottom: "2px solid",
              borderBottomColor: colors.btn_dark,
              paddingBottom: 1,
              display: "inline-block",
            }}
          >
            Limits
          </Typography>
          <Grid container spacing={4} sx={{ marginTop: 2 }}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                User Evaluation Limit:
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginTop: 1 }}
              >
                {user_evaluation_limit !== null ? user_evaluation_limit : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                User Company Limit:
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginTop: 1 }}
              >
                {user_company_limit !== null ? user_company_limit : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ marginY: 4 }} />

        <Box sx={{ marginBottom: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              borderBottom: "2px solid",
              borderBottomColor: colors.btn_dark,
              paddingBottom: 1,
            }}
          >
            Workspace Admin
          </Typography>
          {workspace_admin ? (
            <Typography variant="body1" sx={{ paddingLeft: 2 }}>
              {workspace_admin.name} {workspace_admin.surname} (
              {workspace_admin.email})
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ paddingLeft: 2 }}>
              No admin assigned
            </Typography>
          )}
        </Box>

        <Divider sx={{ marginY: 4 }} />

        <Box sx={{ marginBottom: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              borderBottom: "2px solid",
              borderBottomColor: colors.btn_dark,
              paddingBottom: 1,
            }}
          >
            Model level
          </Typography>
          <Typography
            key={model_level}
            variant="body1"
            sx={{ paddingLeft: 2, marginBottom: 1 }}
          >
            {modelLevelToString(model_level)}
          </Typography>
        </Box>

        <Divider sx={{ marginY: 4 }} />

        <Box sx={{ marginBottom: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              borderBottom: "2px solid",
              borderBottomColor: colors.btn_dark,
              paddingBottom: 1,
            }}
          >
            Features
          </Typography>
          <Grid container spacing={2}>
            {permissions.length > 0 ? (
              permissions.map((permission) => (
                <Grid item key={permission.permission_id} xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      transition: "background-color 0.3s ease",
                      "&:hover": {
                        backgroundColor: colors.btn_dark,
                      },
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: colors.primary_dark }}
                      >
                        {permission.feature.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ marginTop: 1, color: colors.primary_dark }}
                      >
                        {permission.feature.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Card sx={{ padding: 2, backgroundColor: colors.white }}>
                  <Typography variant="body1" align="center">
                    No permissions assigned
                  </Typography>
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>

        <Divider sx={{ marginY: 4 }} />

        <Box>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              borderBottom: "2px solid",
              borderBottomColor: colors.btn_dark,
              paddingBottom: 1,
            }}
          >
            Certifications
          </Typography>
          {workspace_certifications.length > 0 ? (
            workspace_certifications.map((certification) => (
              <Typography
                key={certification.id}
                variant="body1"
                sx={{ paddingLeft: 2, marginBottom: 1 }}
              >
                {certification.certification_category_name}
              </Typography>
            ))
          ) : (
            <Typography variant="body1" sx={{ paddingLeft: 2 }}>
              No certifications assigned
            </Typography>
          )}
        </Box>
        <Divider sx={{ marginY: 4 }} />

        <Box sx={{ marginBottom: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              borderBottom: "2px solid",
              borderBottomColor: colors.btn_dark,
              paddingBottom: 1,
            }}
          >
            Workspace Users
          </Typography>
          <UsersTable workspaceId={parseInt(workspaceId!, 10)} />
        </Box>

        <Box sx={{ marginBottom: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: "bold",
              borderBottom: "2px solid",
              borderBottomColor: colors.btn_dark,
              paddingBottom: 1,
            }}
          >
            Shared question lists
          </Typography>
          <WorkspaceQuestionListsTable
            data={workspaceData?.workspace_question_lists || []}
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default SingleWorkspace;
