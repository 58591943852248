import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import { getSubstringAfterLastPeriod } from "../../../helpers/helpers";
import { downloadDocument } from "../../../Services/Https/company";
import { Document } from "../../../Models/knowledge";
import { ProcessingStatus } from "../../../Constants/Enums/companyStatus";
import TempUploadedFilesPanel from "./TempUploadedFilesPanel";

interface DocumentListProps {
  documentsData: Document[];
  isDownloading: boolean;
  setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>;
}

const DocumentList: React.FC<DocumentListProps> = ({
  documentsData,
  isDownloading,
  setIsDownloading,
}) => {
  return (
    <Grid
      container
      justifyContent="start"
      spacing={2}
      sx={{ flexGrow: 1, mb: 2 }}
      height="fit-content"
    >
      {documentsData &&
        documentsData.map((file) => {
          const isError = file.status === ProcessingStatus.ERROR;

          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.4}
              key={file.name}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                opacity: isError ? 0.5 : 1,
                pointerEvents: isError ? "none" : "auto",
              }}
            >
              <Box sx={{ position: "relative" }}>
                {isError && (
                  <CloseIcon
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 2,
                      opacity: 0.8,
                      color: "red",
                      pointerEvents: "none",
                    }}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: 1,
                  }}
                >
                  <Tooltip title={"Download document"}>
                    <span>
                      <IconButton
                        size="small"
                        sx={{
                          color: "orange",
                          zIndex: 1,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadDocument(
                            file.id.toString(),
                            getSubstringAfterLastPeriod(file.name),
                            setIsDownloading
                          );
                        }}
                        disabled={isDownloading || isError}
                      >
                        <CloudDownloadIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    zIndex: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      wordWrap: "break-word",
                      overflow: "hidden",
                      whiteSpace: "normal",
                    }}
                  >
                    <TempUploadedFilesPanel
                      file={file}
                      getFileName={(file) => file.name}
                      getFileStatus={(file) => file.status}
                      getFileCreated={(file) => file.created_at}
                      getFileUpdated={(file) => file.updated_at}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default DocumentList;
