import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Button,
  Typography,
  TableSortLabel,
} from "@mui/material";
import { useWebsites } from "../../Hooks/useCompanies";
import { SetStateAction, useState, useEffect } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import {
  uploadWebsite,
  downloadScrappedWebsiteContent,
  deleteWebsite,
} from "../../Services/Https/company";
import { enqueueSnackbar } from "notistack";
import color from "../../Constants/colors";
import "../../Styles/Companies.css";
import { ProcessingStatus } from "../../Constants/Enums/companyStatus";
import DownloadIcon from "@mui/icons-material/Download";
import colors from "../../Constants/colors";
import { Website } from "../../Models/website";
import React from "react";
import WebsiteTableRow from "./WebsiteTableRow";

interface WebsiteUploadProps {
  open: boolean;
  onClose: () => void;
  companyId: string;
}

const WebsiteUpload: React.FC<WebsiteUploadProps> = ({
  open,
  onClose,
  companyId,
}) => {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [websitesResponse, setWebsitesResponse] = useState<any>(null);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);
  const {
    data: websitesData,
    isLoading,
    refetch,
  } = useWebsites(companyId, page + 1, pageSize, sortField, sortOrder, "");

  useEffect(() => {
    if (open) {
      const interval = setInterval(refetch, 5000);
      return () => clearInterval(interval);
    }
  }, [open, refetch]);

  useEffect(() => {
    if (open && websitesData) {
      setWebsitesResponse(websitesData);
    }
  }, [websitesData, open]);

  const [isUploadingWebsites, setIsUploadingWebsites] = useState(false);

  const [websiteName, setWebsiteName] = useState("");
  const [websiteAddress, setWebsiteAddress] = useState("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSort = (field: SetStateAction<string>) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const handleWebsiteNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 25) {
      setWebsiteName(value);
      setIsSnackbarShown(false);
    } else {
      if (!isSnackbarShown) {
        enqueueSnackbar("Website name cannot exceed 25 characters.", {
          variant: "warning",
        });
        setIsSnackbarShown(true);
      }
    }
  };

  const handleUploadWebsite = async () => {
    try {
      setIsUploadingWebsites(true);
      const newWebsiteResponse = await uploadWebsite(
        companyId,
        websiteName,
        websiteAddress
      );

      const newWebsite = {
        id: newWebsiteResponse.data.id || 1,
        name: websiteName,
        web_adress: websiteAddress,
        created_at: new Date().toISOString(),
        status: ProcessingStatus.WAITING,
      };

      if (websitesResponse) {
        setWebsitesResponse({
          ...websitesResponse,
          websites: [newWebsite, ...websitesResponse.websites],
          total: websitesResponse.total + 1,
        });
      }

      setWebsiteName("");
      setWebsiteAddress("");
    } catch (error: any) {
      const { message } = error.response?.data || error;
      enqueueSnackbar(`Failed to upload website: ${message}`, {
        variant: "error",
      });
    } finally {
      setIsUploadingWebsites(false);
    }
  };

  const handleDeleteWebsite = async (website_id: number) => {
    try {
      await deleteWebsite(website_id);

      if (websitesResponse) {
        const updatedWebsites = websitesResponse.websites.filter(
          (website: Website) => website.id !== website_id
        );

        setWebsitesResponse({
          ...websitesResponse,
          websites: updatedWebsites,
          total: websitesResponse.total - 1,
        });
      }

      enqueueSnackbar("Website deleted successfully", { variant: "success" });
    } catch (error: any) {
      const { message } = error.response?.data || error;
      enqueueSnackbar(`Failed to delete website: ${message}`, {
        variant: "error",
      });
    }
  };

  const convertStatus = (status: number, websiteId: number) => {
    switch (status) {
      case ProcessingStatus.WAITING:
        return (
          <Typography variant="caption" sx={{ color: color.textPrimary }}>
            Processing...
          </Typography>
        );
      case ProcessingStatus.IN_PROGRESS:
        return (
          <Grid container direction="row" alignItems="center">
            <Typography variant="caption" sx={{ color: color.textPrimary }}>
              Processing...
            </Typography>
            <CircularProgress
              size={20}
              sx={{ marginLeft: "10px", color: color.white }}
            />
          </Grid>
        );
      case ProcessingStatus.PROCESSED:
        return (
          <Grid container direction="row" alignItems="center">
            <Typography variant="caption" sx={{ color: color.textPrimary }}>
              Done
            </Typography>
            <Tooltip title="Download scraped data">
              <IconButton
                onClick={() => downloadScrappedWebsiteContent(websiteId)}
              >
                <DownloadIcon sx={{ color: color.btn_dark }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      case ProcessingStatus.ERROR:
        return (
          <Grid container direction="row" alignItems="center">
            <Typography variant="caption" sx={{ color: color.textPrimary }}>
              An error occurred during processing
            </Typography>
          </Grid>
        );
      case ProcessingStatus.TIME_LIMIT_EXCEEDED:
        return (
          <Grid container direction="row" alignItems="center">
            <Typography variant="caption" sx={{ color: color.textPrimary }}>
              Error: time limit exceeded
            </Typography>
          </Grid>
        );
      default:
        return (
          <Typography variant="caption" sx={{ color: color.textPrimary }}>
            Oops something went wrong!
          </Typography>
        );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          sx: {
            backgroundImage: color.white,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LanguageIcon sx={{ color: colors.btn_dark }} />
            <Typography variant="h4" sx={{ color: color.primary_dark }} ml={2}>
              Websites
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            {isLoading || !websitesResponse ? (
              <CircularProgress />
            ) : (
              <>
                <Grid
                  item
                  alignSelf="flex-start"
                  sx={{ position: "relative", top: "0px" }}
                ></Grid>
                <Grid
                  item
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="flex-start"
                  mt={3}
                  sx={{ marginTop: "-10px" }}
                >
                  <Grid item>
                    <TextField
                      label="Name"
                      variant="outlined"
                      value={websiteName}
                      onChange={handleWebsiteNameChange}
                      size="small"
                      InputLabelProps={{
                        style: { color: color.primary_dark, fontSize: "14px" },
                      }}
                      sx={{
                        width: "220px",
                        "& .MuiInputLabel-root": {
                          color: color.primary_dark,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: color.primary_dark,
                          },
                          "&:hover fieldset": {
                            borderColor: color.primary_dark,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: color.primary_dark,
                          },
                          "& input": {
                            color: color.primary_dark,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Web Address"
                      variant="outlined"
                      value={websiteAddress}
                      onChange={(e) => setWebsiteAddress(e.target.value)}
                      size="small"
                      InputLabelProps={{
                        style: { color: color.primary_dark, fontSize: "14px" },
                      }}
                      sx={{
                        width: "220px",
                        "& .MuiInputLabel-root": {
                          color: color.primary_dark,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: color.primary_dark,
                          },
                          "&:hover fieldset": {
                            borderColor: color.primary_dark,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: color.primary_dark,
                          },
                          "& input": {
                            color: color.primary_dark,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} sx={{ marginLeft: "-10px" }}>
                    <Tooltip
                      title={
                        !websiteName || !websiteAddress
                          ? "Please add website name and website address"
                          : "Start scraping"
                      }
                    >
                      <span>
                        <Button
                          onClick={handleUploadWebsite}
                          disabled={
                            isUploadingWebsites ||
                            !websiteName ||
                            !websiteAddress
                          }
                          sx={{
                            backgroundColor: color.btn_dark,
                            color: color.white,
                            "&:hover": {
                              backgroundColor: color.primary_dark,
                              color: color.btn_dark,
                            },
                          }}
                          variant="outlined"
                        >
                          SCRAPE
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid item width="100%" sx={{ marginTop: "20px" }}>
                  {" "}
                  <Table
                    sx={{
                      backgroundColor: color.backgroungOfMyQuestionTable,
                      borderRadius: "12px",
                      padding: "20px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <TableHead
                      sx={{
                        borderBottom: `2px solid ${color.primary_dark}`,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: color.btn_dark,
                            cursor: "pointer",
                          }}
                          onClick={() => handleSort("name")}
                        >
                          <TableSortLabel
                            active={sortField === "name"}
                            direction={sortField === "name" ? sortOrder : "asc"}
                          >
                            Website
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: color.btn_dark,
                            cursor: "pointer",
                          }}
                          onClick={() => handleSort("status")}
                        >
                          <TableSortLabel
                            active={sortField === "status"}
                            direction={
                              sortField === "status" ? sortOrder : "asc"
                            }
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            color: color.btn_dark,
                            cursor: "pointer",
                          }}
                          onClick={() => handleSort("created_at")}
                        >
                          <TableSortLabel
                            active={sortField === "created_at"}
                            direction={
                              sortField === "created_at" ? sortOrder : "asc"
                            }
                          >
                            Upload Date
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {websitesResponse.websites.length > 0 ? (
                        websitesResponse.websites.map((website: Website) => (
                          <WebsiteTableRow
                            key={website.id}
                            website={website}
                            onHandleDeleteWebsite={handleDeleteWebsite}
                            convertStatus={convertStatus}
                          />
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            <Typography
                              variant="body2"
                              sx={{ color: color.btn_dark }}
                              fontWeight="bold"
                            >
                              No websites available.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={websitesResponse.total}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WebsiteUpload;
