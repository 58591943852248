import React, { createContext, useState, useContext } from "react";

interface SelectedCompanyValue {
  selectedCompanyId: number | "";
  setSelectedCompanyId: (id: number | "") => void;
}

export const SelectedCompanyContext = createContext<SelectedCompanyValue>({
  selectedCompanyId: "",
  setSelectedCompanyId: () => {},
});

export const SelectedCompanyProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | "">("");

  return (
    <SelectedCompanyContext.Provider
      value={{ selectedCompanyId, setSelectedCompanyId }}
    >
      {children}
    </SelectedCompanyContext.Provider>
  );
};

export const useCompanyContext = () => useContext(SelectedCompanyContext);
