import React from "react";
import { Grid } from "@mui/material";
import CompaniesTable from "../../Components/Companies/CompaniesTable";

const CompaniesPage = () => {
  return (
    <Grid container direction="column" alignItems="center" width="100%">
      <Grid item width="100%">
        <CompaniesTable />
      </Grid>
    </Grid>
  );
};

export default CompaniesPage;
