import axios from "axios";
import {
  CreateReleaseNoteRequest,
  PaginatedReleaseNotes,
  UpdateReleaseNoteRequest,
} from "../../Models/releaseNote";

export const createReleaseNote = async (data: CreateReleaseNoteRequest) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/release_note/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};

export const getAllReleaseNotes = async (
  page: number,
  page_size: number
): Promise<PaginatedReleaseNotes> => {
  const response = await axios.get<PaginatedReleaseNotes>(
    `${process.env.REACT_APP_BASE_URL}/v1/release_note/`,
    {
      params: { page, page_size },
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};

export const deleteReleaseNote = async (id: number) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/release_note/${id}/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};

export const updateReleaseNote = async (
  id: number,
  data: UpdateReleaseNoteRequest
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/release_note/${id}/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
    }
  );
  return response.data;
};
