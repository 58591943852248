import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import color from "../../Constants/colors";

interface DefaultDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedList: {
    name: string;
    category: { name: string };
  } | null;
}

const DefaultDialog: React.FC<DefaultDialogProps> = ({
  open,
  onClose,
  onConfirm,
  selectedList,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="default-dialog-title"
      aria-describedby="default-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="default-dialog-title" sx={{ color: color.primary_dark }}>
        <strong>Confirm Default</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="default-dialog-description"
          sx={{ color: color.primary_dark }}
        >
          Are you sure you want to make the question list "{selectedList?.name}"
          default for the certification category "{selectedList?.category.name}
          "?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DefaultDialog;
