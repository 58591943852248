import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import color from "../../../Constants/colors";

interface DeleteEvaluationConfirmationDialogProps {
  open: boolean;
  onClose: (event: any, reason: string) => void;
  onConfirmClose: (confirmed: boolean, reason: string) => void;
}

const DeleteEvaluationConfirmationDialog: React.FC<
  DeleteEvaluationConfirmationDialogProps
> = ({ open, onClose, onConfirmClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <Box
        component="img"
        src="/commo_logo.png"
        alt="Certification Logo"
        sx={{
          position: "absolute",
          top: "20px",
          left: "16px",
          width: "auto",
          height: "40px",
        }}
      />
      <DialogTitle id="alert-dialog-title">
        <Typography
          variant="body1"
          sx={{
            color: color.primary_dark,
            marginLeft: "20px",
            marginTop: "40px",
          }}
        >
          Delete evaluation confirmation
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="body2"
            sx={{ color: color.primary_dark, paddingLeft: "20px" }}
          >
            Are you sure you want to delete this evaluation?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            backgroundColor: color.btn_dark,
            color: color.primary,
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
          onClick={() => {
            onConfirmClose(false, "");
          }}
        >
          No
        </Button>
        <Button
          sx={{
            backgroundColor: color.btn_dark,
            color: color.primary,
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
          onClick={() => {
            onConfirmClose(true, "");
          }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEvaluationConfirmationDialog;
