import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Divider,
  Grid,
  Chip,
  Box,
} from "@mui/material";
import { GetSingleKnowledgeBaseResponse } from "../../Models/knowledge";
import color from "../../Constants/colors";
import { useState } from "react";
import DocumentList from "./DocumentList/DocumentList";
import { formatDate } from "../../helpers/helpers";

const KnowledgeBaseModal = ({
  open,
  onClose,
  knowledgeBase,
  isLoading,
}: {
  open: boolean;
  onClose: () => void;
  knowledgeBase: GetSingleKnowledgeBaseResponse | null;
  isLoading: boolean;
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (!knowledgeBase) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent
        sx={{
          overflowY: "auto",
          padding: "2rem",
          color: color.primary_dark,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: color.primary_dark,
                fontWeight: "bold",
              }}
            >
              {knowledgeBase.name}
            </Typography>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  color: color.primary_dark,
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  opacity: 0.6,
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 1,
                    textDecoration: "underline",
                    textDecorationColor: "orange",
                  },
                }}
              >
                Created at: {formatDate(knowledgeBase.created_at)}
              </Typography>
              <Typography
                sx={{
                  color: color.primary_dark,
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  opacity: 0.6,
                  cursor: "pointer",
                  marginTop: "5px",
                  "&:hover": {
                    opacity: 1,
                    textDecoration: "underline",
                    textDecorationColor: "orange",
                  },
                }}
              >
                Updated at: {formatDate(knowledgeBase.updated_at)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: color.primary_dark,
                fontWeight: "bold",
              }}
            >
              Description
            </Typography>
            <Divider sx={{ backgroundColor: color.primary_dark }} />
            <Typography
              variant="body2"
              sx={{ margin: "10px 0", fontSize: "1rem", fontWeight: "normal" }}
            >
              {knowledgeBase.description}
            </Typography>
          </Grid>
        </Grid>
        {knowledgeBase.used_in.length > 0 && (
          <>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: color.primary_dark,
                fontWeight: "bold",
                marginTop: "2rem",
              }}
            >
              Used in the following certification categories
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              {Array.from(
                new Set(knowledgeBase.used_in.map((item) => item.name))
              ).map((name, index) => (
                <Chip
                  key={index}
                  sx={{
                    color: color.white,
                    backgroundColor: color.btn_dark,
                    fontWeight: "bold",
                  }}
                  label={name}
                  variant="outlined"
                />
              ))}
            </Box>
          </>
        )}

        <Typography
          variant="body1"
          gutterBottom
          sx={{
            color: color.primary_dark,
            fontWeight: "bold",
            marginTop: "1.5rem",
          }}
        >
          Documents
        </Typography>
        <Divider sx={{ backgroundColor: color.primary_dark }} />
        {knowledgeBase.documents && (
          <DocumentList
            documentsData={knowledgeBase.documents}
            isDownloading={isDownloading}
            setIsDownloading={setIsDownloading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KnowledgeBaseModal;
