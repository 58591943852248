import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import color from "../../../Constants/colors";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  title: string;
  description: string;
  content: string;
  actions: { label: string; onClick: () => void }[];
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  title,
  description,
  content,
  actions,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby={title}
      aria-describedby={description}
      maxWidth="sm"
      fullWidth
    >
      <Box
        component="img"
        src="/commo_logo.png"
        alt="Certification Logo"
        sx={{
          position: "absolute",
          top: "20px",
          left: "16px",
          width: "auto",
          height: "40px",
        }}
      />
      <DialogTitle id={title}>
        <Typography
          sx={{
            color: color.primary_dark,
            padding: "20px",
            marginRight: "3px",
            marginTop: "10px",
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={description}>
          <Typography
            variant="caption"
            sx={{ color: color.primary_dark, paddingLeft: "30px" }}
          >
            {content}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map((action, index) => (
          <Button
            key={index}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            variant="outlined"
            onClick={action.onClick}
          >
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
