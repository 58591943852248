import React from "react";
import { Tooltip, TextField } from "@mui/material";
import color from "../../../Constants/colors";

interface CustomTextFieldProps {
  id: string;
  label: string;
  type?: string;
  name: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  tooltipMessage?: string;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  id,
  label,
  type = "text",
  name,
  value,
  onChange,
  autoFocus = false,
  disabled = false,
  tooltipMessage = "",
}) => {
  return (
    <Tooltip title={tooltipMessage} arrow>
      <TextField
        autoFocus={autoFocus}
        margin="dense"
        id={id}
        label={label}
        type={type}
        fullWidth
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        InputLabelProps={{
          style: { color: color.primary_dark },
        }}
        sx={{
          "& .MuiInputLabel-root": {
            color: disabled
              ? `${color.primary_dark} !important`
              : color.primary_dark,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: disabled
                ? `${color.primary_dark} !important`
                : color.primary_dark,
            },
            "&:hover fieldset": {
              borderColor: disabled
                ? `${color.primary_dark} !important`
                : color.primary_dark,
            },
            "&.Mui-focused fieldset": {
              borderColor: disabled
                ? `${color.primary_dark} !important`
                : color.primary_dark,
            },
            "& input": {
              color: color.primary_dark,
            },
          },
          marginBottom: "7px",
          marginTop: "7px",
        }}
      />
    </Tooltip>
  );
};

export default CustomTextField;
