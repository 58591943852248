import React from "react";
import { Grid, Paper, CircularProgress } from "@mui/material";
import color from "../../Constants/colors";
import { ProcessingStatus } from "../../Constants/Enums/companyStatus";
import { getFileIcon } from "../../helpers/helpers";

interface UploadedFilesPanelProps<T> {
  file: T;
  getFileName: (file: T) => string;
  getFileStatus: (file: T) => number;
}

const UploadedFilesPanel = <T,>({
  file,
  getFileName,
  getFileStatus,
}: UploadedFilesPanelProps<T>) => {
  const fileName = getFileName(file);
  const fileStatus = getFileStatus(file);
  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 120,
      }}
      maxWidth={200}
    >
      <Paper
        variant="outlined"
        sx={{
          width: 50,
          height: 50,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          padding: 1,
          "&:hover .remove-icon": {
            display: "flex",
          },
          opacity: fileStatus === ProcessingStatus.IN_PROGRESS ? 0.5 : 1,
          "& .MuiSvgIcon-root": {
            height: "100%",
            width: "100%",
            maxHeight: 40,
            maxWidth: 40,
          },
        }}
      >
        {getFileIcon(fileName)}
        {fileStatus === ProcessingStatus.IN_PROGRESS && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
              color: color.btn_dark,
            }}
          />
        )}
      </Paper>
    </Grid>
  );
};

export default UploadedFilesPanel;
