import { Grid } from "@mui/material";
import ProfileInformation from "../../Components/Profile/ProfileInformation";

const ProfilePage = () => {
  return (
    <Grid>
      <ProfileInformation />
    </Grid>
  );
};

export default ProfilePage;
