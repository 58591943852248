import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Avatar,
  Button,
  Typography,
  CircularProgress,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useQuery } from "react-query";
import { getUser } from "../../Services/Https/auth";
import { getCurrentWorkspace } from "../../Services/Https/workspace";
import { Features } from "../../Constants/Enums/companyStatus";
import color from "../../Constants/colors";
import UserLimits from "./UserLimits";
import EditUserModal from "./EditUserModal";
import { userRoleToString } from "../../helpers/helpers";
import { hasPermission } from "../../helpers/helpers";
import WorkspaceInformation from "./WorkspaceInformations";

const InfoRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  justifyContent: "center",
  marginBottom: "0.8rem",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: color.btn_dark,
  color: color.white,
  padding: "0.8rem 1.5rem",
  fontSize: "1rem",
  textTransform: "none",
  marginTop: "1rem",
  "&:hover": {
    backgroundColor: color.primary_dark,
    color: color.btn_dark,
  },
}));

const DecorativeLine = styled(Box)(({ theme }) => ({
  height: "2px",
  width: "100%",
  maxWidth: "800px",
  backgroundColor: color.accent,
  margin: "1rem auto",
  borderRadius: "2px",
}));

const ProfileInformation = () => {
  const { data: userData } = useQuery("user", getUser);
  const [workspaceData, setWorkspaceData] = useState<any | null>(null);

  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const data = await getCurrentWorkspace();
        if (
          !data.workspace ||
          (!data.workspace.name &&
            !data.workspace.description &&
            !data.workspace.workspace_admin)
        ) {
          setWorkspaceData(null);
        } else {
          setWorkspaceData(data.workspace);
        }
      } catch (error) {
        console.error("Failed to fetch workspace data:", error);
        setWorkspaceData(null);
      }
    };

    fetchWorkspace();
  }, []);

  const [editModalOpen, setEditModalOpen] = useState(false);

  if (!userData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const canViewUserLimits = hasPermission(Features.EVALUATION);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "2rem",
        backgroundColor: color.primary_dark,
        minHeight: "100vh",
        color: color.white,
        display: "flex",
        flexDirection: workspaceData ? { xs: "column", md: "row" } : "row",
        justifyContent: workspaceData ? "space-between" : "center",
        alignItems: workspaceData
          ? { xs: "center", md: "flex-start" }
          : "flex-start",
      }}
    >
      <Box
        sx={{
          maxWidth: workspaceData ? "48%" : "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                bgcolor: color.btn_dark,
                width: 180,
                height: 180,
                fontSize: "4.5rem",
                marginBottom: "1rem",
              }}
            >
              {`${userData.name?.charAt(0)}${userData.surname?.charAt(0)}`}
            </Avatar>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}
            >
              {userData.name} {userData.surname}
            </Typography>
            <InfoRow>
              <MailOutlineIcon />
              <Typography variant="body1">{userData.email}</Typography>
            </InfoRow>
            <InfoRow>
              <PersonOutlineIcon />
              <Typography variant="body1">
                {userRoleToString(userData.role)}
              </Typography>
            </InfoRow>
          </Grid>

          {canViewUserLimits && (
            <>
              <Grid item xs={12}>
                <DecorativeLine />
              </Grid>

              <Grid item xs={12}>
                <UserLimits
                  dailyLimit={userData.daily_limit}
                  evaluations={userData.evaluations || 0}
                  companiesLimit={userData.companies_limit}
                  companiesCount={userData.companiesCount || 0}
                />
              </Grid>

              <Grid item xs={12}>
                <DecorativeLine />
              </Grid>
            </>
          )}

          <Grid item xs={12} textAlign="center">
            <StyledButton
              startIcon={<EditIcon />}
              onClick={() => setEditModalOpen(true)}
            >
              Edit Profile
            </StyledButton>
          </Grid>
        </Grid>

        <EditUserModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          userData={{
            name: userData.name,
            surname: userData.surname,
            email: userData.email,
            is_sso: userData.is_sso,
          }}
        />
      </Box>

      {workspaceData && (
        <Grid item xs={12} md={6} textAlign="center" sx={{ width: "100%" }}>
          <WorkspaceInformation workspaceData={workspaceData} />
        </Grid>
      )}
    </Box>
  );
};

export default ProfileInformation;
