const color = {
  primary: "#ffffff",
  primary_not_present_in_design: "#e0e4fc",
  primary_medium: "#98989A",
  primary_light: "#B1B1B3",
  primary_dark: "#032e4d",
  accent: "#f28d01",
  accent_dark: "#c07200",
  btn_dark: "#f28d01",
  btn_dark_disabled: "rgba(242, 141, 1, 0.6)",
  secondary: "#054d7f",
  secondaryDark: "#d1d6e1",
  black: "#343a40",
  white: "#ffffff",
  red: "#ff0000",
  green: "#77DD77",
  yellow: "#FDFD96",
  orange: "#F28D01",
  textPrimary: "rgba(0, 0, 0, 0.87)",
  textSecondary: "rgba(0, 0, 0, 0.54)",
  textDisabled: "rgba(0, 0, 0, 0.38)",
  textHint: "rgba(0, 0, 0, 0.38)",
  backgroungOfMyQuestionTable: "rgba(242, 141, 1, 0.12)",
};

export default color;
