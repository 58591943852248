import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import color from "../../Constants/colors";

interface WorkspaceSelectorProps {
  workspaces: { id: number; name: string }[];
  workspaceId: string | number | null;
  setWorkspaceId: (id: string | number | null) => void;
  disabled?: boolean;
}

const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = ({
  workspaces,
  workspaceId,
  setWorkspaceId,
  disabled = false,
}) => {
  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <InputLabel sx={{ color: color.white }}>Workspace</InputLabel>
        <Select
          label="Workspace"
          name="workspace_id"
          value={workspaceId}
          disabled={disabled}
          onChange={(e) => setWorkspaceId(e.target.value)}
          fullWidth
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: color.primary_dark,
                opacity: 0.95,
              },
            },
          }}
        >
          {workspaces.map((workspace) => (
            <MenuItem key={workspace.id} value={workspace.id}>
              <Typography sx={{ color: color.white }}>
                {workspace.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default WorkspaceSelector;
