import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Box,
  Collapse,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
  createWorkspace,
  updateWorkspace,
  getWorkspace,
} from "../../Services/Https/workspace";
import colors from "../../Constants/colors";
import PhotoUploadButton from "./UploadPhotoButton";
import WorkspaceAdminId from "./WorkspaceAdminId";
import UserLimits from "./UserWorkspaceLimtis";
import { Features } from "../../Constants/Enums/companyStatus";
import { getFeatures, Feature } from "../../Services/Https/features";
import CertificationsSelector from "./CertificationField";
import NamespaceField from "./Namespaces";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ModelLevel } from "../../Constants/Enums/companyStatus";

const WorkspaceCreator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const existingWorkspace = location.state?.workspace || null;

  const [workspace, setWorkspace] = useState({
    id: existingWorkspace?.id || null,
    name: existingWorkspace?.name || "",
    description: existingWorkspace?.description || "",
    userEvaluationLimit: existingWorkspace?.userEvaluationLimit || 5,
    userCompanyLimit: existingWorkspace?.userCompanyLimit || 5,
    namespaces: Array.isArray(existingWorkspace?.namespaces)
      ? existingWorkspace.namespaces
      : existingWorkspace?.namespaces
      ? JSON.parse(existingWorkspace.namespaces)
      : [],
    workspaceAdminId: existingWorkspace?.workspaceAdminId || null,
    model_level: ModelLevel.BASIC,
    iconImage: null as File | null,
    existingIconUrl: existingWorkspace?.iconImage || null,
    features: [] as string[],
    certifications: [] as number[],
  });

  const [features, setFeatures] = useState<Feature[]>([]);
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const data = await getFeatures();
        setFeatures(data.features);
      } catch (error) {
        enqueueSnackbar("Failed to fetch features.", { variant: "error" });
      }
    };
    fetchFeatures();
  }, []);

  useEffect(() => {
    if (workspace.id) {
      fetchWorkspaceDetails(workspace.id);
    }
    setIsChanged(false);
  }, [workspace.id]);

  const fetchWorkspaceDetails = async (id: number) => {
    try {
      const details = await getWorkspace(id.toString());

      const features = details.permissions.map((permission) =>
        permission.feature.id.toString()
      );

      const certifications = details.workspace_certifications.map(
        (certification) => certification.certification_category
      );

      setWorkspace((prev) => ({
        ...prev,
        id: details.workspace.id,
        name: details.workspace.name,
        description: details.workspace.description,
        userEvaluationLimit: details.workspace.user_evaluation_limit,
        model_level: details.workspace.model_level,
        userCompanyLimit: details.workspace.user_company_limit,
        namespaces: Array.isArray(details.workspace.namespaces)
          ? details.workspace.namespaces
          : typeof details.workspace.namespaces === "string" &&
            details.workspace.namespaces
          ? JSON.parse(details.workspace.namespaces.replace(/'/g, '"'))
          : [],
        workspaceAdminId: details.workspace.workspace_admin?.id || null,
        features: features || [],
        certifications: certifications || [],
        existingIconUrl: details.workspace.icon_image
          ? `data:image/png;base64,${details.workspace.icon_image}`
          : null,
        iconImage: null,
      }));
    } catch (error) {
      enqueueSnackbar("Failed to load workspace details.", {
        variant: "error",
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setWorkspace((prev) => {
      const updatedWorkspace = {
        ...prev,
        [name]:
          name === "userEvaluationLimit" || name === "userCompanyLimit"
            ? Number(value)
            : value,
      };

      setIsChanged(
        JSON.stringify(updatedWorkspace) !== JSON.stringify(existingWorkspace)
      );

      return updatedWorkspace;
    });
  };

  const handleToggleExpandAll = () => {
    setExpandAll((prev) => !prev);
  };

  const handleFeaturesChange = (value: string, checked: boolean) => {
    setWorkspace((prev) => {
      const updatedFeatures = checked
        ? [...prev.features, value]
        : prev.features.filter((feature) => feature !== value);

      setIsChanged(
        JSON.stringify({ ...prev, features: updatedFeatures }) !==
          JSON.stringify(existingWorkspace)
      );

      return { ...prev, features: updatedFeatures };
    });
  };

  const handleCertificationsChange = (selected: number[]) => {
    setWorkspace((prev) => {
      setIsChanged(
        JSON.stringify({ ...prev, certifications: selected }) !==
          JSON.stringify(existingWorkspace)
      );

      return { ...prev, certifications: selected };
    });
  };

  const handleSubmit = async () => {
    if (!workspace.name || workspace.name.length < 2) {
      enqueueSnackbar("Workspace name must be at least 2 characters long.", {
        variant: "warning",
      });
      return;
    }
    if (!workspace.description) {
      enqueueSnackbar("Description is required.", { variant: "warning" });
      return;
    }

    if (!isChanged) {
      enqueueSnackbar("No changes detected.", {
        variant: "info",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      if (workspace.id) {
        await updateWorkspace(
          workspace.id,
          workspace.name,
          workspace.description,
          workspace.userEvaluationLimit,
          workspace.userCompanyLimit,
          workspace.namespaces,
          workspace.iconImage,
          workspace.workspaceAdminId || null,
          workspace.features.map(Number),
          workspace.certifications,
          workspace.model_level
        );

        enqueueSnackbar("Workspace updated successfully!", {
          variant: "success",
        });
        navigate(`/workspace/${workspace.id}`);
      } else {
        const response = await createWorkspace(
          workspace.name,
          workspace.description,
          workspace.userEvaluationLimit,
          workspace.userCompanyLimit,
          workspace.namespaces,
          workspace.iconImage,
          workspace.workspaceAdminId || null,
          workspace.features.map(Number),
          workspace.certifications,
          workspace.model_level
        );

        enqueueSnackbar("Workspace created successfully!", {
          variant: "success",
        });
        navigate(`/workspace/${response.workspace_id}`);
      }
    } catch (error) {
      enqueueSnackbar("Failed to save workspace. Please try again.", {
        variant: "error",
      });
    } finally {
      setIsSubmitting(false);
      setIsChanged(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Grid item>
        <Typography variant="h3" sx={{ color: colors.white, marginBottom: 4 }}>
          {workspace.id ? "Edit Workspace" : "Create Workspace"}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Workspace Name"
            name="name"
            value={workspace.name}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              shrink: true,
              sx: { color: colors.white },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            name="description"
            value={workspace.description}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            multiline
            rows={3}
            InputLabelProps={{
              shrink: true,
              sx: { color: colors.white },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <UserLimits
            userEvaluationLimit={workspace.userEvaluationLimit}
            userCompanyLimit={workspace.userCompanyLimit}
            onChange={(limits) => {
              const updatedWorkspace = { ...workspace, ...limits };
              setWorkspace(updatedWorkspace);
              setIsChanged(
                JSON.stringify(updatedWorkspace) !==
                  JSON.stringify(existingWorkspace)
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: 3 }}>
          <PhotoUploadButton
            onFileChange={(file) => {
              const updatedWorkspace = { ...workspace, iconImage: file };
              setWorkspace(updatedWorkspace);
              setIsChanged(
                JSON.stringify(updatedWorkspace) !==
                  JSON.stringify(existingWorkspace)
              );
            }}
            existingImage={workspace.existingIconUrl}
          />
        </Grid>
        <Grid item xs={12}>
          <WorkspaceAdminId
            currentAdminEmail={
              existingWorkspace?.workspace_admin?.email || null
            }
            onChange={(newAdminId) => {
              const updatedWorkspace = {
                ...workspace,
                workspaceAdminId: newAdminId,
              };
              setWorkspace(updatedWorkspace);
              setIsChanged(
                JSON.stringify(updatedWorkspace) !==
                  JSON.stringify(existingWorkspace)
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <NamespaceField
            namespaces={workspace.namespaces}
            onChange={(updatedNamespaces) => {
              const updatedWorkspace = {
                ...workspace,
                namespaces: updatedNamespaces,
              };
              setWorkspace(updatedWorkspace);
              setIsChanged(
                JSON.stringify(updatedWorkspace) !==
                  JSON.stringify(existingWorkspace)
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: colors.btn_dark,
                },
              }}
            >
              <Typography variant="body1" sx={{ color: colors.white }}>
                AI Model Level
              </Typography>
            </InputLabel>
            <Select
              label="AI Model Level"
              name="model_level"
              value={workspace.model_level}
              onChange={(e) => {
                const { value } = e.target;
                setWorkspace((prev) => {
                  const updatedWorkspace = {
                    ...prev,
                    model_level: Number(value),
                  };

                  setIsChanged(
                    JSON.stringify(updatedWorkspace) !==
                      JSON.stringify(existingWorkspace)
                  );

                  return updatedWorkspace;
                });
              }}
              fullWidth
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: colors.primary_dark,
                    opacity: 0.95,
                  },
                },
              }}
            >
              <MenuItem value={ModelLevel.BASIC}>
                <Typography sx={{ color: colors.white }}>Basic</Typography>
              </MenuItem>
              <MenuItem value={ModelLevel.ENTRY}>
                <Typography sx={{ color: colors.white }}>Entry</Typography>
              </MenuItem>
              <MenuItem value={ModelLevel.FULL}>
                <Typography sx={{ color: colors.white }}>Full</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            align="center"
            sx={{ color: colors.white, marginBottom: 2 }}
          >
            Features
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(Features)
              .filter(([key]) => isNaN(Number(key)))
              .map(([key, value]) => (
                <Grid item key={value} xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      transition: "background-color 0.3s ease",
                      backgroundColor: workspace.features.includes(
                        value.toString()
                      )
                        ? colors.btn_dark
                        : colors.white,
                    }}
                  >
                    <CardActionArea
                      onClick={() =>
                        handleFeaturesChange(
                          value.toString(),
                          !workspace.features.includes(value.toString())
                        )
                      }
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Box sx={{ position: "relative" }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              color: colors.primary_dark,
                            }}
                          >
                            {
                              features.find((feature) => feature.id === value)
                                ?.name
                            }
                          </Typography>
                          <Collapse in={expandAll} timeout="auto" unmountOnExit>
                            <Typography
                              variant="body2"
                              sx={{ marginTop: 1, color: colors.primary_dark }}
                            >
                              {
                                features.find((feature) => feature.id === value)
                                  ?.description
                              }
                            </Typography>
                          </Collapse>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                    <IconButton
                      sx={{
                        marginLeft: "auto",
                        transform: expandAll
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleExpandAll();
                      }}
                    >
                      <ExpandMoreIcon sx={{ color: colors.primary_dark }} />
                    </IconButton>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {workspace.features.includes(Features.QUESTION_LISTS.toString()) &&
            workspace.features.includes(Features.EVALUATION.toString()) && (
              <CertificationsSelector
                selectedCertifications={workspace.certifications}
                onChange={handleCertificationsChange}
              />
            )}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleSubmit}
            disabled={!isChanged || isSubmitting}
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: colors.btn_dark,
              color: colors.white,
              "&:hover": {
                backgroundColor: colors.primary_dark,
                color: colors.btn_dark,
              },
              marginBottom: 10,
              marginTop: 6,
              opacity: isChanged ? "1" : "0.6",
              padding: "12px 24px",
              transition: "opacity 0.3s ease",
            }}
          >
            <Typography variant="body1">
              {workspace.id
                ? isSubmitting
                  ? "Saving..."
                  : "Save Changes"
                : isSubmitting
                ? "Processing..."
                : "Create Workspace"}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WorkspaceCreator;
