import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { styled } from "@mui/system";
import { VerifiedUser, Build, TrendingUp } from "@mui/icons-material";
import color from "../../Constants/colors";

const FooterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: color.primary_dark,
  color: color.white,
  textAlign: "center",
  fontFamily: "'Work Sans', sans-serif",
}));

const FeaturesSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10),
  backgroundColor: color.primary_light,
  color: color.primary_dark,
  textAlign: "center",
}));

const CallToAction = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: color.secondary,
  color: color.white,
  textAlign: "center",
  fontFamily: "'Barlow', sans-serif",
}));

const Footer: React.FC = () => {
  return (
    <Box>
      <FeaturesSection>
        <Typography variant="h4" sx={{ marginBottom: "2rem" }}>
          Why Choose Us
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Box textAlign="center">
              <VerifiedUser
                fontSize="large"
                style={{ color: color.secondary }}
              />
              <Typography variant="h6">Trusted Expertise</Typography>
              <Typography variant="body2">
                Our team consists of industry experts with years of experience.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box textAlign="center">
              <Build fontSize="large" style={{ color: color.secondary }} />
              <Typography variant="h6">Innovative Solutions</Typography>
              <Typography variant="body2">
                We provide cutting-edge solutions tailored to your needs.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box textAlign="center">
              <TrendingUp fontSize="large" style={{ color: color.secondary }} />
              <Typography variant="h6">Proven Results</Typography>
              <Typography variant="body2">
                Our solutions have consistently delivered outstanding results.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </FeaturesSection>
      <CallToAction>
        <Typography variant="h4">Ready to Transform Your Business?</Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Contact us today to learn more about our services and how we can help
          your business achieve its digital goals.
        </Typography>
        <Button
          onClick={() =>
            window.open(
              "https://corporatemomentum.zohobookings.eu/#/109854000000018042",
              "_blank"
            )
          }
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: color.btn_dark,
            color: color.white,
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
            width: "fit-content",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Get in Touch
        </Button>
      </CallToAction>
      <FooterContainer>
        <Typography variant="body2" component="p">
          &copy; {new Date().getFullYear()} Digital Benefits. All rights
          reserved.
        </Typography>
        <Typography variant="body2" component="p">
          Contact us: ai@corporate-momentum.com
        </Typography>
      </FooterContainer>
    </Box>
  );
};

export default Footer;
