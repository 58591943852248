import React from "react";
import { Button, CircularProgress, Box } from "@mui/material";
import color from "../../Constants/colors";

interface ActionButtonsProps {
  onCreate: () => void;
  onImport: (file: File) => void;
  isLoading: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  onCreate,
  onImport,
  isLoading,
}) => {
  const handleFileInput = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json";
    input.onchange = (event) => {
      const target = event.target as HTMLInputElement;
      const file = target?.files?.[0];
      if (file) {
        onImport(file);
      }
    };
    input.click();
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        flexWrap: "wrap",
        flexDirection: "row",
        marginBottom: "20px",
      }}
    >
      <Button
        onClick={onCreate}
        sx={{
          backgroundColor: color.btn_dark,
          color: color.white,
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
          textAlign: "center",
          flexGrow: 1,
          "&:hover": {
            backgroundColor: color.primary_dark,
            color: color.btn_dark,
          },
        }}
      >
        Create a New Question List
      </Button>
      <Button
        onClick={handleFileInput}
        sx={{
          backgroundColor: color.btn_dark,
          color: color.white,
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
          textAlign: "center",
          flexGrow: 1,
          "&:hover": {
            backgroundColor: color.primary_dark,
            color: color.btn_dark,
          },
        }}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={20} sx={{ color: color.primary_dark }} />
        ) : (
          "Import Question List As JSON"
        )}
      </Button>
    </Box>
  );
};

export default ActionButtons;
