import { Grid } from "@mui/material";
import CreateCompanyForm from "../../Components/Companies/CreateCompanyForm";

const CreateCompanyPage = () => {
  return (
    <Grid>
      <CreateCompanyForm />
    </Grid>
  );
};
export default CreateCompanyPage;
