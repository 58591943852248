import React, { useState, useEffect } from "react";
import { Box, Button, Avatar, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colors from "../../Constants/colors";

const PhotoUploadButton = ({
  onFileChange,
  existingImage,
}: {
  onFileChange: (file: File | null) => void;
  existingImage?: string | null;
}) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);

  useEffect(() => {
    if (existingImage) {
      setUploadedImage(existingImage);
    }
  }, [existingImage]);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.result) {
          setUploadedImage(fileReader.result as string);
        }
      };
      fileReader.readAsDataURL(file);
      onFileChange(file);
    }
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
    onFileChange(null); // Očisti sliku
  };

  return (
    <Box textAlign="center" sx={{ marginTop: 2 }}>
      {!uploadedImage ? (
        <>
          <input
            type="file"
            accept="image/png, image/jpeg"
            style={{ display: "none" }}
            id="photo-upload-button"
            onChange={handleFileInputChange}
          />
          <label htmlFor="photo-upload-button">
            <Button
              variant="contained"
              component="span"
              sx={{
                backgroundColor: colors.btn_dark,
                color: colors.white,
                "&:hover": {
                  backgroundColor: colors.primary_dark,
                  color: colors.btn_dark,
                },
                padding: "10px 20px",
                textTransform: "none",
              }}
            >
              Upload Photo
            </Button>
          </label>
        </>
      ) : (
        <Grid
          item
          xs={12}
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Avatar
            sx={{
              bgcolor: colors.white,
              width: 180,
              height: 180,
            }}
            src={uploadedImage}
          />
          <IconButton
            aria-label="remove photo"
            onClick={handleRemoveImage}
            sx={{
              position: "absolute",
              top: "5px",
              right: "-5px",
              backgroundColor: "transparent",
              color: colors.red,
              width: 20,
              height: 20,
              border: "1px solid red",
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: colors.red,
                color: colors.white,
                border: `1px solid ${colors.white}`,
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      )}
    </Box>
  );
};

export default PhotoUploadButton;
