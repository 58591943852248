import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Box,
  IconButton,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import PageviewIcon from "@mui/icons-material/Pageview";
import { CompanyInfo } from "../../Models/company";
import {
  getSingleCompany,
  deleteSingleCompany,
  updateSingleCompany,
  updateAboutCompany,
} from "../../Services/Https/company";
import { AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";
import Evaluation from "../Evaluation/Evaluation";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadModal from "../FileUpload/FileUploadModal";
import { CompanyStatus } from "../../Constants/Enums/companyStatus";
import color from "../../Constants/colors";
import { AnswerDetail, EvaluationResponse } from "../../Models/evaluation";
import CompanyActions from "./CompanyActions";
import { useReviewQuestions } from "../../Hooks/useReviewQuestions";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import { Assessment } from "@mui/icons-material";
import theme from "../../Constants/theme";
import { deleteEvaluation } from "../../Services/Https/evaluations";
import {
  fetchDefaultQuestionListDetails,
  fetchQuestionListDetails,
} from "../../Services/Https/questions";
import { QuestionListDetailsResponse } from "../../Models/question";
import { Language } from "../../Models/languageModel";
import { useLanguages } from "../../Hooks/useLanguages";
import { useCheckProgress } from "../../Hooks/useCompanies";
import DeleteEvaluationConfirmationDialog from "./Dialogs/DeleteEvaluationDialog";
import { getGradeDescription } from "../../helpers/helpers";

const SingleCompanyComponent: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null);
  const [originalCompanyInfo, setOriginalCompanyInfo] =
    useState<CompanyInfo | null>(null);
  const [resetAnswers, setResetAnswers] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [evaluationLoading, setEvaluationLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [disablingEdit, setDisablingEdit] = useState<boolean>(true);
  const [isFileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [visibility, setVisibility] = useState<"visible" | "hidden">("visible");
  const evaluationRef = useRef<HTMLDivElement>(null);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const [responseName, setResponseName] = useState<string>("");
  const [responseAbout, setResponseAbout] = useState<string>("");
  const [responseIndustry, setResponseIndustry] = useState<string>("");
  const [responseLocation, setResponseLocation] = useState<string>("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [hasErrorBeenShown, setHasErrorBeenShown] = useState(false);
  const [evaluationToDelete, setEvaluationToDelete] = useState<number | null>(
    null
  );
  const [, setResponseAnswers] = useState<{
    [key: string]: string;
  }>({});
  const [evaluationInProgress, setEvaluationInProgress] = useState<
    boolean | null
  >(null);
  const [questionList, setQuestionList] = useState<
    QuestionListDetailsResponse | undefined
  >(undefined);
  const [selectedEvaluationId, setSelectedEvaluationId] = useState<
    number | null
  >(null);
  const [originalAnswers, setOriginalAnswers] = useState<{
    [question_key: string]: string;
  }>({});
  const [, setSelectedEvaluation] = useState<EvaluationResponse | null>(null);

  const isAddMode = window.location.pathname.includes("/add");

  useEffect(() => {
    if (isAddMode) {
      const handlePopState = (event: PopStateEvent) => {
        event.preventDefault();
        navigate("/evaluation", { replace: true });
      };

      window.history.pushState(null, "", window.location.pathname);
      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [isAddMode, navigate]);

  const questionListId =
    companyInfo?.last_evaluation_request?.question_list?.id ?? 0;

  const certificationId = 0;

  const { data: languages } = useLanguages();

  const getLanguageName = (id: number) => {
    const language = languages?.find(
      (lang: Language) => lang.language_id === id
    );
    return language ? language.language_name : "Unknown Language";
  };
  const centerAltReturnsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  };

  const {
    answers,
    handleAnswerChange,
    handleFileChange,
    setAnswers,
    handleSubmitUpdateAboutCompany,
  } = useReviewQuestions(
    questionListId,
    certificationId,
    String(companyInfo?.id),
    selectedEvaluationId
      ? JSON.stringify(
          companyInfo?.evaluations.find(
            (e) => e.evaluation_id === selectedEvaluationId
          )?.answers ?? []
        )
      : "",
    companyInfo ?? ({} as CompanyInfo)
  );

  const { data: checkProgressData } = useCheckProgress();

  const evaluationId = checkProgressData?.company?.id?.toString();

  const fetchCompanyInfo = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const data: CompanyInfo = await getSingleCompany(id);
      setEvaluationInProgress(
        data.status === CompanyStatus.EVALUATION_IN_PROGRESS ||
          data.status === CompanyStatus.FETCHING_COMPANY_DETAILS ||
          data.status === CompanyStatus.PROCESSING_COMPANY_INFORMATIONS ||
          data.status === CompanyStatus.GRADING_COMPANY
      );
      setCompanyInfo(data);
      setOriginalCompanyInfo(data);

      const firstEvaluationId =
        data.evaluations.length > 0 ? data.evaluations[0].evaluation_id : null;
      setSelectedEvaluationId(firstEvaluationId);
      setResponseName(data.name);
      setResponseAbout(data.about);
      setResponseIndustry(data.industry);
      setResponseLocation(data.location);
      if (firstEvaluationId) {
        const selectedEvaluation = data.evaluations.find(
          (e) => e.evaluation_id === firstEvaluationId
        );

        const formattedAnswers =
          selectedEvaluation?.answers.reduce(
            (acc: { [key: string]: string }, answer: AnswerDetail) => {
              acc[answer.question_key] = answer.answer;
              return acc;
            },
            {}
          ) ?? {};

        setResponseAnswers(formattedAnswers);
        setOriginalAnswers(formattedAnswers);
      } else {
        setResponseAnswers({});
      }
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error fetching company: ${errorMessage}`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchCompanyInfo(companyId);
    } else {
      setError("No company ID provided");
      setLoading(false);
    }
  }, [companyId, fetchCompanyInfo]);

  const fetchQuestionList = useCallback(async () => {
    const questionListId =
      companyInfo?.last_evaluation_request?.question_list?.id ?? 0;
    if (questionListId) {
      setEvaluationLoading(true);
      try {
        const list = await fetchQuestionListDetails(questionListId);
        setQuestionList(list);
      } catch (err) {
        enqueueSnackbar("Failed to fetch question list.", { variant: "error" });
      } finally {
        setEvaluationLoading(false);
      }
    }
  }, [companyInfo]);

  useEffect(() => {
    if (companyInfo && companyInfo.last_evaluation_request?.question_list?.id) {
      fetchQuestionList();
    }
  }, [companyInfo, fetchQuestionList]);

  useEffect(() => {
    if (questionList) {
    }
  }, [questionList]);

  const handleCancel = () => {
    if (originalCompanyInfo) {
      setCompanyInfo({ ...originalCompanyInfo });
      setResponseName(originalCompanyInfo.name);
      setResponseAbout(originalCompanyInfo.about);
      setResponseIndustry(originalCompanyInfo.industry);
      setResponseLocation(originalCompanyInfo.location);
      setAnswers(
        selectedEvaluationId
          ? originalCompanyInfo.evaluations
              .find((e) => e.evaluation_id === selectedEvaluationId)
              ?.answers.map((answer) => ({
                question_key: answer.question_key,
                answer: answer.answer,
                category_key:
                  questionList?.categories.find((category) =>
                    category.questions.some(
                      (q) => q.question_key === answer.question_key
                    )
                  )?.question_category_key || "",
              })) ?? []
          : []
      );
      setIsSaveButtonEnabled(false);
      setResetAnswers(true);
      setDisablingEdit(!disablingEdit);
    }
    if (isAddMode) {
      navigate(`/company/${companyId}`);
    }
  };

  useEffect(() => {
    if (resetAnswers) {
      setResetAnswers(false);
    }
  }, [resetAnswers]);

  if (loading) {
    return (
      <Box sx={centerAltReturnsStyle}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Box sx={centerAltReturnsStyle}>Error: {error}</Box>;
  }
  if (!companyInfo) {
    return (
      <Box sx={centerAltReturnsStyle}>
        <div>No company information found</div>
      </Box>
    );
  }

  const handleSave = async (id: string) => {
    setLoading(true);
    try {
      const { name, about, industry, location } = companyInfo;

      if (name.trim() === "" || about.trim() === "") {
        enqueueSnackbar('"Name" and "About" fields cannot be empty.', {
          variant: "warning",
        });
        setLoading(false);
        return;
      }

      const changedAnswers = Object.keys(answers).reduce((acc, questionKey) => {
        if (answers[questionKey] !== originalAnswers[questionKey]) {
          acc[questionKey] = answers[questionKey];
        }
        return acc;
      }, {} as { [key: string]: string });

      const isCompanyInfoChanged =
        about !== responseAbout ||
        name !== responseName ||
        industry !== responseIndustry ||
        location !== responseLocation;

      if (!isCompanyInfoChanged && Object.keys(changedAnswers).length === 0) {
        enqueueSnackbar("No changes detected", { variant: "info" });
      } else {
        let isUpdated = false;

        if (isCompanyInfoChanged) {
          await updateSingleCompany(id, name, about, location, industry);
          enqueueSnackbar("Company Updated Successfully", {
            variant: "success",
          });
          isUpdated = true;
        }

        if (Object.keys(changedAnswers).length > 0 && companyId) {
          const changedGroupedAnswers = Object.keys(changedAnswers).reduce(
            (acc, questionKey) => {
              const category_key = questionList?.categories.find((category) =>
                category.questions.some((q) => q.question_key === questionKey)
              )?.question_category_key;

              if (category_key) {
                if (!acc[category_key]) {
                  acc[category_key] = {};
                }
                acc[category_key][questionKey] = changedAnswers[questionKey];
              }
              return acc;
            },
            {} as {
              [category_key: string]: { [question_key: string]: string };
            }
          );

          const dataToSend = {
            evaluation_id: selectedEvaluationId
              ? selectedEvaluationId.toString()
              : "",
            answers: changedGroupedAnswers,
          };

          await updateAboutCompany(companyId, dataToSend);
          enqueueSnackbar("Answers Updated Successfully", {
            variant: "success",
          });
          isUpdated = true;

          setOriginalAnswers((prevOriginalAnswers) => ({
            ...prevOriginalAnswers,
            ...changedAnswers,
          }));
        }

        if (isUpdated) {
          setResponseAbout(about);
          setResponseName(name);
          setResponseIndustry(industry);
          setResponseLocation(location);
        }
      }
      if (isAddMode) {
        navigate(`/company/${companyId}`);
      }
    } catch (err) {
      setError("Failed to update company");
      enqueueSnackbar("Failed to Update Company", { variant: "error" });
    } finally {
      setLoading(false);
      setDisablingEdit(true);
      setVisibility("visible");
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      if (companyId !== undefined) {
        const response: AxiosResponse<CompanyInfo> = await deleteSingleCompany(
          companyId
        );
        setCompanyInfo(response.data);
        enqueueSnackbar("Deleted Company Successfully", { variant: "success" });
        navigate("/company");
      }
    } catch (err) {
      setError("Failed to delete company");
      enqueueSnackbar("Didn't Delete Company Successfully", {
        variant: "error",
      });
      navigate("/company");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    setDisablingEdit(!disablingEdit);
  };

  if (!companyId) {
    return <Grid>Invalid address</Grid>;
  }

  const handleOpenFileUploadModal = () => {
    setFileUploadModalOpen(true);
  };

  const handleCloseFileUploadModal = () => {
    setFileUploadModalOpen(false);
  };

  const handleFieldChange = () => {
    setIsSaveButtonEnabled(true);
  };

  const handleEvaluationChange = async (
    event: SelectChangeEvent<number | string>
  ) => {
    const selectedValue = event.target.value;

    if (selectedValue === "start-evaluation") {
      navigate("/evaluation");
      return;
    }

    setEvaluationLoading(true);
    const evaluationId = Number(selectedValue);
    setSelectedEvaluationId(evaluationId);

    const evaluation = companyInfo?.evaluations.find(
      (evaluation) => evaluation.evaluation_id === evaluationId
    );

    setSelectedEvaluation(evaluation || null);

    if (evaluation?.question_list_id) {
      try {
        const list = await fetchQuestionListDetails(
          evaluation.question_list_id
        );
        setQuestionList(list);
      } catch (err) {
        enqueueSnackbar("Failed to fetch question list for new evaluation.", {
          variant: "error",
        });
      }
    } else {
      setQuestionList(undefined);
    }
    setEvaluationLoading(false);
  };

  const handleEvaluationData = (evaluation: any) => {
    try {
      return JSON.parse(evaluation.evaluation);
    } catch (e) {
      if (!hasErrorBeenShown) {
        enqueueSnackbar(
          `Something went wrong. We couldn't display your evaluation. Please try running it again.`,
          {
            variant: "error",
          }
        );
        setHasErrorBeenShown(true);
      }
      return null;
    }
  };

  const handleDeleteEvaluation = async (evaluationId: number) => {
    try {
      await deleteEvaluation(String(evaluationId));
      enqueueSnackbar("Deleted Evaluation Successfully", {
        variant: "success",
      });

      if (companyId) {
        fetchCompanyInfo(companyId);
      }
    } catch (error) {
      enqueueSnackbar("Failed to Delete Evaluation", { variant: "error" });
    }
  };

  const handleAnswerChangeWrapper = async (
    question_key: string,
    answer: string
  ) => {
    if (!questionList) {
      try {
        const list = questionListId
          ? await fetchQuestionListDetails(questionListId)
          : await fetchDefaultQuestionListDetails(certificationId);

        if (list) {
          setQuestionList(list);
        } else {
          enqueueSnackbar("Failed to fetch question list.", {
            variant: "error",
          });
          return;
        }
      } catch (error) {
        enqueueSnackbar("Error fetching question list", {
          variant: "error",
        });
        return;
      }
    }

    const category = questionList?.categories.find((category) =>
      category.questions.some((q) => q.question_key === question_key)
    );

    if (category) {
      handleAnswerChange(question_key, answer, category.question_category_key);
    } else {
      enqueueSnackbar(`No category found for question_key: ${question_key}.`, {
        variant: "warning",
      });
    }
  };

  const openDeleteDialog = (evaluationId: number) => {
    setEvaluationToDelete(evaluationId);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = (confirmed: boolean) => {
    if (confirmed && evaluationToDelete !== null) {
      handleDeleteEvaluation(evaluationToDelete);
    }
    setDeleteDialogOpen(false);
    setEvaluationToDelete(null);
  };

  return (
    <>
      {isAddMode ? (
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid ref={evaluationRef} mb={14}>
                {selectedEvaluationId &&
                  companyInfo.evaluations.length > 0 &&
                  companyInfo.evaluations
                    .filter(
                      (evaluation) =>
                        evaluation.evaluation_id === selectedEvaluationId
                    )
                    .map((evaluation) => {
                      const evaluationData = handleEvaluationData(evaluation);
                      if (!evaluationData) return null;
                      return (
                        <Evaluation
                          key={evaluation.evaluation_id}
                          evaluationResponse={
                            evaluationData as EvaluationResponse
                          }
                          questionList={questionList}
                          answers={answers || {}}
                          handleAnswerChange={handleAnswerChangeWrapper}
                          handleFileChange={handleFileChange}
                          handleSubmitUpdateAboutCompany={
                            handleSubmitUpdateAboutCompany
                          }
                          resetAnswers={resetAnswers}
                          isQuestionFieldDisabled={disablingEdit}
                          onAnswerChange={handleFieldChange}
                          companyId={companyId}
                          onHandleSave={handleSave}
                          onHandleCancel={handleCancel}
                          isSaveButtonEnabled={isSaveButtonEnabled}
                          disablingEdit={disablingEdit}
                          isAddMode={isAddMode}
                        />
                      );
                    })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            padding={"0 16px"}
            sx={{
              flexDirection: { xs: "column-reverse", md: "row" },
              marginBottom: 2,
              marginTop: 1,
              alignItems: { xs: "space-between", md: "center" },
              gap: { xs: "1rem", md: "0" },
            }}
          >
            <Grid
              display="flex"
              alignItems="center"
              sx={{ paddingLeft: "15px" }}
            >
              <TextField
                value={companyInfo.name}
                label="Name of company"
                disabled={disablingEdit}
                onChange={(e) => {
                  setCompanyInfo({ ...companyInfo, name: e.target.value });
                  handleFieldChange();
                }}
                fullWidth
                InputProps={{
                  sx: {
                    backgroundColor: disablingEdit
                      ? "rgba(0, 0, 0, 0.2)"
                      : "inherit",
                  },
                }}
                InputLabelProps={{
                  style: { color: "white" },
                }}
              />
              {companyInfo?.in_progress && evaluationId && (
                <Tooltip title="Track your company's evaluation progress">
                  <PageviewIcon
                    fontSize="large"
                    onClick={() => {
                      navigate(`/evaluation_in_progress/${evaluationId}`, {
                        state: { companyId: companyId },
                      });
                    }}
                    sx={{
                      color: color.white,
                      "&:hover": {
                        backgroundColor: color.primary_dark,
                        color: color.btn_dark,
                      },
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              )}
            </Grid>

            {!evaluationInProgress && (
              <CompanyActions
                companyInfo={companyInfo}
                companyId={companyId}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                onOpenFileUploadModal={handleOpenFileUploadModal}
                disablingEdit={!disablingEdit}
                visibility={visibility}
              />
            )}
          </Grid>
          <Grid item padding={"0 16px"}>
            <TextField
              value={companyInfo.industry || ""}
              label="Industry"
              disabled={disablingEdit}
              onChange={(e) => {
                setCompanyInfo({ ...companyInfo, industry: e.target.value });
                handleFieldChange();
              }}
              variant="outlined"
              fullWidth
              InputProps={{
                sx: {
                  backgroundColor: disablingEdit
                    ? "rgba(0, 0, 0, 0.2)"
                    : "inherit",
                },
              }}
              InputLabelProps={{
                style: { color: "white" },
                shrink: true,
              }}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
          <Grid item padding={"0 16px"}>
            <TextField
              value={companyInfo.location || ""}
              label="Location"
              disabled={disablingEdit}
              onChange={(e) => {
                setCompanyInfo({ ...companyInfo, location: e.target.value });
                handleFieldChange();
              }}
              variant="outlined"
              fullWidth
              InputProps={{
                sx: {
                  backgroundColor: disablingEdit
                    ? "rgba(0, 0, 0, 0.2)"
                    : "inherit",
                },
              }}
              InputLabelProps={{
                style: { color: "white" },
                shrink: true,
              }}
            />
          </Grid>
          <Grid item padding={"0 16px"}>
            <TextField
              label="About"
              multiline
              fullWidth
              value={companyInfo.about}
              disabled={disablingEdit}
              onChange={(e) => {
                setCompanyInfo({ ...companyInfo, about: e.target.value });
                handleFieldChange();
              }}
              variant="outlined"
              margin="normal"
              InputProps={{
                sx: {
                  color: disablingEdit ? "white" : "inherit",
                  backgroundColor: disablingEdit
                    ? "rgba(0, 0, 0, 0.2)"
                    : "inherit",
                },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
            />
          </Grid>

          <Grid item padding={"0 16px"} marginBottom={2} marginTop={1}>
            <FormControl fullWidth>
              <InputLabel
                id="evaluation-select-label"
                sx={{ color: color.white }}
              >
                Select Evaluation
              </InputLabel>
              <Select
                labelId="evaluation-select-label"
                id="evaluation-select"
                value={selectedEvaluationId || ""}
                label="Select Evaluation"
                onChange={handleEvaluationChange}
                IconComponent={() => null}
                sx={{
                  color: color.white,
                  backgroundColor: "transparent",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: color.white,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: color.white,
                  },
                  ".MuiSvgIcon-root": {
                    display: "none",
                  },
                  display: "flex",
                  alignItems: "center",
                  ".MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: color.primary_dark,
                      opacity: 0.95,
                    },
                  },
                }}
              >
                {companyInfo.evaluations.length === 0 ? (
                  <MenuItem value="start-evaluation">
                    <ListItemIcon>
                      <Assessment sx={{ color: color.btn_dark }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          style={{
                            color: theme.palette.primary.main,
                            opacity: 0.7,
                          }}
                        >
                          Start Evaluation
                        </Typography>
                      }
                    />
                  </MenuItem>
                ) : (
                  companyInfo.evaluations.map((evaluation) => (
                    <MenuItem
                      key={evaluation.evaluation_id}
                      value={evaluation.evaluation_id}
                      sx={{
                        color: "white",
                        backgroundColor: "transparent",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                        },
                      }}
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1" sx={{ color: "white" }}>
                          {` ${
                            evaluation.question_list_name
                          } in ${getLanguageName(evaluation.language)}`}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.palette.primary.light,
                            fontStyle: "italic",
                            fontSize: "0.8rem",

                            marginRight: 6,
                            opacity: 0.6,
                          }}
                        >{`Question list by: ${evaluation.created_by.name}`}</Typography>

                        <Tooltip
                          title={`Grade: ${evaluation.grade}`}
                          placement="left"
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: color.btn_dark,
                            }}
                          >
                            {getGradeDescription(Number(evaluation.grade))}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          openDeleteDialog(evaluation.evaluation_id);
                        }}
                        sx={{ color: color.red }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>

          {/* evaluation results */}
          <Grid
            container
            spacing={2}
            sx={{
              height: "50vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12}>
              <Grid
                ref={evaluationRef}
                mb={14}
                container
                justifyContent="center"
                alignItems="center"
              >
                {evaluationLoading ? (
                  <CircularProgress size={100} />
                ) : (
                  selectedEvaluationId &&
                  companyInfo.evaluations.length > 0 &&
                  companyInfo.evaluations
                    .filter(
                      (evaluation) =>
                        evaluation.evaluation_id === selectedEvaluationId
                    )
                    .map((evaluation) => {
                      const evaluationData = handleEvaluationData(evaluation);
                      if (!evaluationData) return null;
                      return (
                        <Evaluation
                          key={evaluation.evaluation_id}
                          evaluationResponse={
                            evaluationData as EvaluationResponse
                          }
                          questionList={questionList}
                          answers={answers || {}}
                          handleAnswerChange={handleAnswerChangeWrapper}
                          handleFileChange={handleFileChange}
                          handleSubmitUpdateAboutCompany={
                            handleSubmitUpdateAboutCompany
                          }
                          resetAnswers={resetAnswers}
                          isQuestionFieldDisabled={disablingEdit}
                          onAnswerChange={handleFieldChange}
                          companyId={companyId}
                          onHandleSave={handleSave}
                          onHandleCancel={handleCancel}
                          isSaveButtonEnabled={isSaveButtonEnabled}
                          disablingEdit={disablingEdit}
                          isAddMode={isAddMode}
                        />
                      );
                    })
                )}
              </Grid>
            </Grid>
          </Grid>

          <DeleteEvaluationConfirmationDialog
            open={deleteDialogOpen}
            onClose={(_event, _reason) => setDeleteDialogOpen(false)}
            onConfirmClose={(confirmed) => closeDeleteDialog(confirmed)}
          />

          {isFileUploadModalOpen && (
            <FileUploadModal
              open={isFileUploadModalOpen}
              onClose={handleCloseFileUploadModal}
              documents={companyInfo.documents}
            />
          )}
        </Container>
      )}
      {disablingEdit === false && (
        <Grid
          item
          xs={12}
          mt={5}
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            backgroundColor: color.secondaryDark,
            padding: "1rem",
            zIndex: 1000,
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
        >
          <div>
            <Button
              variant="contained"
              disabled={!isSaveButtonEnabled}
              sx={{
                backgroundColor: color.btn_dark,
                color: "white",
                "&:hover": {
                  backgroundColor: color.primary_dark,
                  color: color.btn_dark,
                },
              }}
              onClick={() => handleSave(companyId)}
            >
              Save Company
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: color.secondary,
                color: "white",
                "&:hover": {
                  backgroundColor: color.secondary,
                },
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      )}
    </>
  );
};

export default SingleCompanyComponent;
