import axios from "axios";
export interface Feature {
  description: string;
  id: number;
  name: string;
}
export interface GetFeaturesResponse {
  features: Feature[];
}
export const getFeatures = async (): Promise<GetFeaturesResponse> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/permission/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cih_token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
