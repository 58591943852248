import React, { useState } from "react";
import { CategoryDetail, EvaluationResponse } from "../../Models/evaluation";
import color from "../../Constants/colors";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Collapse,
  Tooltip,
  Button,
  LinearProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "../../Styles/HomePage.css";
import EvaluationCategory from "../EvaluationCategory/EvaluationCategory";
import Questions from "../Questions/Questions";
import { QuestionListDetailsResponse } from "../../Models/question";
import { Link } from "react-router-dom";
import { getGradeDescription } from "../../helpers/helpers";

interface EvaluationProps {
  evaluationResponse: EvaluationResponse;
  questionList: QuestionListDetailsResponse | undefined;
  answers: { [key: string]: string };
  handleAnswerChange: (questionName: string, answer: string) => void;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    questionName: string
  ) => Promise<void>;
  handleSubmitUpdateAboutCompany: () => Promise<void>;
  resetAnswers: boolean;
  isQuestionFieldDisabled?: boolean;
  onAnswerChange?: (() => void) | undefined;
  companyId?: string;
  onHandleSave?: (id: string) => Promise<void>;
  onHandleCancel?: () => void;
  isSaveButtonEnabled: boolean;
  disablingEdit: boolean;
  isAddMode: boolean | undefined;
}

const Evaluation: React.FC<EvaluationProps> = ({
  evaluationResponse,
  questionList,
  answers,
  handleAnswerChange,
  handleFileChange,
  handleSubmitUpdateAboutCompany,
  resetAnswers,
  isQuestionFieldDisabled = false,
  onAnswerChange,
  companyId,
  onHandleSave,
  onHandleCancel,
  isSaveButtonEnabled,
  disablingEdit,
  isAddMode = false,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const summaryRating =
    typeof evaluationResponse.summary_rating === "number"
      ? evaluationResponse.summary_rating
      : 0;

  const filledPart = summaryRating;
  const unfilledPart = 100 - summaryRating;

  const categories = Object.keys(evaluationResponse).reduce((acc, key) => {
    const value = evaluationResponse[key as keyof EvaluationResponse];
    if (typeof value === "object" && value !== null && "summary" in value) {
      acc.push({ key, detail: value as CategoryDetail });
    }
    return acc;
  }, [] as { key: string; detail: CategoryDetail }[]);

  const goodCategories = categories.filter(
    (category) => category.detail.more_info === 0
  );

  const questionCategories = evaluationResponse.questions || [];

  const categoriesNeedingMoreInfo = categories.filter(
    (category) => category.detail.more_info === 1
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      mb={10}
      sx={{ margin: "0 auto" }}
    >
      <Grid item mt={3}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="h3"
            align="center"
            sx={{
              color: color.white,
              mb: 5,
            }}
          >
            Company Evaluation
          </Typography>
        </Grid>

        <div style={{ position: "relative", display: "flex" }}>
          <Box
            position="relative"
            display="inline-flex"
            width={330}
            height={20}
            sx={{
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            <LinearProgress
              variant="determinate"
              value={filledPart}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: 2,
                backgroundColor: color.primary_light,
                "& .MuiLinearProgress-bar": {
                  backgroundColor: color.btn_dark,
                  borderRadius: 2,
                },
              }}
            />
            <Tooltip title={`${filledPart}`} arrow placement="top">
              <Box
                position="absolute"
                left={0}
                width={`${filledPart}%`}
                height="100%"
                sx={{
                  pointerEvents: "auto",
                }}
              />
            </Tooltip>
            <Tooltip title={`${unfilledPart}`} arrow placement="top">
              <Box
                position="absolute"
                left={`${filledPart}%`}
                width={`${unfilledPart}%`}
                height="100%"
                sx={{
                  pointerEvents: "auto",
                }}
              />
            </Tooltip>

            <Box
              position="absolute"
              bottom="-40px"
              left="50%"
              sx={{
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip title={`${summaryRating.toFixed(2)}`} arrow>
                <Typography
                  variant="body1"
                  sx={{
                    color: color.btn_dark,
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    padding: "0 5px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {getGradeDescription(summaryRating)}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </div>
      </Grid>
      <Grid item width="100%" mt={10}>
        <Typography
          variant="h4"
          align="center"
          sx={{ marginBottom: "18px", color: color.white }}
        >
          Company summary
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: color.white, textAlign: "center" }}
        >
          {evaluationResponse.summary}
        </Typography>
      </Grid>
      {goodCategories.map((category, index) => (
        <Grid
          item
          width="100%"
          key={category.key}
          style={{
            animation: `fadeInEffect 0.5s ease forwards ${0.7 * index}s`,
            opacity: 0,
          }}
        >
          <EvaluationCategory
            categoryDetail={category.detail}
            categoryKey={category.key}
          />
        </Grid>
      ))}
      {questionCategories.length > 0 && (
        <Grid item width="100%" mt={5}>
          <Typography
            variant="h4"
            align="center"
            sx={{
              marginBottom: "18px",
              color: color.white,
              textAlign: "center",
            }}
          >
            We had trouble gathering information for the following categories.
            Here are the questions:
          </Typography>
          {questionCategories.map((category) => (
            <div key={category.question_category_key}>
              <Typography
                variant="h5"
                sx={{
                  color: color.white,
                  marginTop: "50px",
                  textAlign: "center",
                  marginBottom: "-30px",
                }}
              >
                {category.question_category_name}
              </Typography>
              <Questions
                moreInfo={{
                  moreInfo: evaluationResponse.more_info,
                  questions: category.questions.map((q) => q.question_key),
                }}
                answers={answers}
                questionList={questionList}
                handleAnswerChange={handleAnswerChange}
                handleFileChange={handleFileChange}
                handleSubmitUpdateAboutCompany={handleSubmitUpdateAboutCompany}
                isQuestionFieldDisabled={
                  isAddMode ? false : isQuestionFieldDisabled
                }
                onAnswerChange={onAnswerChange}
                resetAnswers={resetAnswers}
              />
            </div>
          ))}
        </Grid>
      )}
      {categoriesNeedingMoreInfo.length > 0 && (
        <Grid item width="100%" mt={5}>
          <Tooltip
            title="Expand/Collapse evaluation segments that need more info"
            arrow
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: color.white,
                cursor: "pointer",
              }}
              onClick={handleExpandClick}
            >
              <Typography
                variant="h4"
                align="center"
                sx={{
                  marginBottom: "38px",
                  color: color.red,
                  marginLeft: "48px",
                  fontSize: "1.5rem",
                }}
              >
                {expanded
                  ? "Show Less"
                  : "Evaluation Segments That Need More Information"}
              </Typography>
              <IconButton
                sx={{ marginLeft: "14px", marginBottom: "38px" }}
                size="large"
              >
                {expanded ? (
                  <ExpandLessIcon sx={{ color: color.red }} />
                ) : (
                  <ExpandMoreIcon sx={{ color: color.red }} />
                )}
              </IconButton>
            </Box>
          </Tooltip>
          <Collapse in={expanded}>
            {categoriesNeedingMoreInfo.map((category) => (
              <Box
                key={category.key}
                sx={{
                  marginBottom: 2,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    border: `5px solid ${color.red}`,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    pointerEvents: "none",
                    zIndex: 1,
                  }}
                />
                <EvaluationCategory
                  categoryDetail={category.detail}
                  categoryKey={category.key}
                />
              </Box>
            ))}
          </Collapse>
        </Grid>
      )}

      {onHandleSave && companyId && isAddMode && (
        <Grid
          item
          xs={12}
          mt={5}
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            backgroundColor: color.secondaryDark,
            padding: "1rem",
            zIndex: 1000,
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
        >
          {evaluationResponse.more_info !== 0 ? (
            <Button
              variant="contained"
              onClick={() => {
                onHandleSave(companyId);
              }}
              sx={{
                backgroundColor: color.btn_dark,
                color: "white",
                "&:hover": {
                  backgroundColor: color.primary_dark,
                  color: color.btn_dark,
                },
              }}
            >
              Save Answers
            </Button>
          ) : (
            <Link
              to="/"
              aria-label="Go back to home"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: color.btn_dark,
                  color: "white",
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                }}
              >
                Go back to home
              </Button>
            </Link>
          )}
        </Grid>
      )}

      {onHandleSave && companyId && !disablingEdit && (
        <Grid
          item
          xs={12}
          mt={5}
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            backgroundColor: color.secondaryDark,
            padding: "1rem",
            zIndex: 1000,
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
        >
          <div>
            <Button
              variant="contained"
              disabled={!isSaveButtonEnabled}
              onClick={() => {
                onHandleSave(companyId);
              }}
              sx={{
                backgroundColor: color.btn_dark,
                color: "white",
                "&:hover": {
                  backgroundColor: color.primary_dark,
                  color: color.btn_dark,
                },
              }}
            >
              Save Company
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={onHandleCancel}
              sx={{
                backgroundColor: color.secondary,
                color: "white",
                "&:hover": {
                  backgroundColor: color.secondary,
                },
              }}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default Evaluation;
