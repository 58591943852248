import {
  Grid,
  Paper,
  TextField,
  Avatar,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { passwordResetRequest } from "../../Services/Https/auth";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";

const PasswordResetRequest = () => {
  const paperStyle = {
    padding: 20,
    backgroundColor: "rgba(255, 255, 255)",
    margin: "0 auto",
  };

  const avatarStyle = {
    backgroundColor: color.white,
    marginInline: "auto",
    marginBottom: "8px",
    border: `2px solid ${color.primary_dark}`,
  };
  const btnstyle = { margin: "8px 0" };
  const textFieldStyle = { margin: "8px 0" };
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordResetRequest = async (
    event: React.FormEvent<HTMLElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await passwordResetRequest(email);
      setIsSubmitted(true);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        enqueueSnackbar("Account with the requested email does not exist", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Password Reset Request Failed", { variant: "error" });
      }
    }
    setIsLoading(false);
  };

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: color.primary_dark,
      }}
    >
      <Paper
        elevation={0}
        style={paperStyle}
        sx={{
          maxWidth: { xs: "80%", sm: 400, md: 500 },
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Avatar style={avatarStyle}>
            <img
              src="/commo_logo.png"
              alt="Logo"
              style={{ width: "100%", height: "auto" }}
            />
          </Avatar>
          <Typography variant="h4" sx={{ color: color.btn_dark }}>
            Password reset
          </Typography>
        </Grid>
        <form onSubmit={handlePasswordResetRequest}>
          {!isSubmitted ? (
            <>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                style={textFieldStyle}
                required
                value={email}
                onChange={handleChangeEmail}
                sx={{
                  "& .MuiInputBase-root.MuiOutlinedInput-root input": {
                    color: `${color.primary_dark} !important`,
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: `${color.black} !important`,
                  },
                }}
                InputProps={{
                  sx: {
                    borderColor: `${color.btn_dark} !important`,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${color.btn_dark} !important`,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${color.btn_dark} !important`,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${color.btn_dark} !important`,
                    },
                  },
                }}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={btnstyle}
                fullWidth
                disabled={isLoading}
                sx={{
                  backgroundColor: color.btn_dark,
                  color: color.white,
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                  textTransform: "none",
                }}
              >
                Send password reset request
              </Button>
            </>
          ) : (
            <Typography
              variant="subtitle1"
              sx={{ color: color.accent_dark, marginBottom: "20px" }}
            >
              Password reset request sent! Check your email.
            </Typography>
          )}
          <Button
            variant="contained"
            style={btnstyle}
            fullWidth
            disabled={isLoading}
            onClick={handleNavigateToLogin}
            sx={{
              backgroundColor: color.primary_light,
              color: color.white,
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
              textTransform: "none",
            }}
          >
            Go back to login
          </Button>
        </form>
      </Paper>
      <Box
        sx={{
          position: "fixed",
          left: 20,
          bottom: 20,
          zIndex: 1100,
        }}
      >
        <img
          src="logo_big.png"
          alt="Big Logo"
          style={{ maxWidth: "250px", maxHeight: "250px" }}
        />
      </Box>
    </Grid>
  );
};

export default PasswordResetRequest;
