import { ChangeEvent, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Grid, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import color from "../../Constants/colors";
import QuestionListTextField from "./QuestionListTextField";

const QuestionsForm = ({
  isEdit,
  categoryId,
  existingQuestions,
  setQuestionListFull,
  isNewCategory,
}: any) => {
  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState<any>(existingQuestions || []);
  const [isAddingEnabled, setIsAddingEnabled] = useState<boolean>(true);
  const [, setEnableButtonsOnChange] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);

  const handleQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;

    if (inputText.length > 200) {
      if (!isSnackbarShown) {
        enqueueSnackbar(
          "Character limit exceeded! Maximum 200 characters allowed.",
          { variant: "warning" }
        );
        setIsSnackbarShown(true);
      }
      return;
    }

    setQuestion(inputText);
    setIsSnackbarShown(false);
  };

  const handleEditQuestionChange = (
    id: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputText = event.target.value;

    if (inputText.length > 200) {
      if (!isSnackbarShown) {
        enqueueSnackbar(
          "Character limit exceeded! Maximum 200 characters allowed.",
          { variant: "warning" }
        );
        setIsSnackbarShown(true);
      }
      return;
    }

    const updatedQuestions = questions.map((q: any) =>
      q.id === id ? { ...q, question: inputText } : q
    );

    setEnableButtonsOnChange(true);
    setQuestions(updatedQuestions);
    setIsSnackbarShown(false);
  };

  const handleDeleteQuestion = (questionId: number) => {
    const newQuestions = questions.filter(
      (question: any) => question.id !== questionId
    );
    setQuestions(newQuestions);

    setQuestionListFull((prevState: any) => {
      const updatedCategories = prevState.categories.map((category: any) =>
        category.id === categoryId
          ? { ...category, questions: newQuestions }
          : category
      );
      return { ...prevState, categories: updatedCategories };
    });
  };

  useEffect(() => {
    if (questions.length > 0) {
      setQuestionListFull((prevState: any) => {
        const updatedCategories = prevState.categories.map((category: any) =>
          category.id === categoryId
            ? { ...category, questions: questions }
            : category
        );
        return { ...prevState, categories: updatedCategories };
      });
    }
  }, [questions, categoryId, setQuestionListFull]);

  const handleSaveQuestion = () => {
    if (question.trim() !== "" && question.length <= 200) {
      const newQuestion = {
        id: uuidv4(),
        question: question,
      };

      const updatedQuestions = [...questions, newQuestion];
      setQuestions(updatedQuestions);
      setQuestionListFull((prevState: any) => {
        const updatedCategories = prevState.categories.map((category: any) =>
          category.id === categoryId
            ? {
                ...category,
                questions: updatedQuestions,
                category_name: category.category_name,
              }
            : category
        );
        return { ...prevState, categories: updatedCategories };
      });

      setQuestion("");
      setIsAddingEnabled(true);
    } else {
      enqueueSnackbar("Please enter a valid question.", { variant: "warning" });
    }
  };

  return (
    <Grid
      container
      display={"flex"}
      direction={"column"}
      paddingLeft={2}
      marginLeft={1}
      sx={{ borderLeft: 1, borderColor: color.white }}
    >
      <Grid container direction="row" spacing={2} padding={1}>
        {questions.map((question: any) =>
          !isEdit ? (
            <Grid item xs={12} key={question.id}>
              <QuestionListTextField
                value={question.question}
                onChange={() => {}}
                onDelete={() => handleDeleteQuestion(question.id)}
                showDeleteButton={true}
                disabled={true}
                id={question.id}
              />
            </Grid>
          ) : (
            <Grid item xs={12} key={question.id}>
              <QuestionListTextField
                value={question.question}
                onChange={(event) =>
                  handleEditQuestionChange(question.id, event)
                }
                onDelete={() => handleDeleteQuestion(question.id)}
                showDeleteButton={true}
                disabled={false}
                id={question.id}
              />
            </Grid>
          )
        )}
      </Grid>

      {isAddingEnabled && !isNewCategory && (
        <Grid item width="100%" padding={1}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Add Question"
                name="question"
                value={question}
                onChange={handleQuestionChange}
                required
                InputLabelProps={{
                  style: { color: "white" },
                }}
              />
            </Grid>
            <Button
              variant="contained"
              onClick={handleSaveQuestion}
              sx={{
                marginTop: 2,
                backgroundColor: color.btn_dark,
                color: color.white,
                "&:hover": {
                  backgroundColor: color.primary_dark,
                  color: color.btn_dark,
                },
              }}
            >
              Add Question
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default QuestionsForm;
