import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Add, Assessment, Home } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FC, useState, useRef, useEffect } from "react";
import { UserRole, Features } from "../../Constants/Enums/companyStatus";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import color from "../../Constants/colors";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import AddIcon from "@mui/icons-material/Add";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import ApprovalIcon from "@mui/icons-material/Approval";
import StorageIcon from "@mui/icons-material/Storage";
import SchoolIcon from "@mui/icons-material/School";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { hasPermission, hasRole } from "../../helpers/helpers";
import ReleaseNotes from "../ReleaseNotes/ReleaseNotes";
import HubIcon from "@mui/icons-material/Hub";
import WorkspaceIcon from "../Workspace/WorkspaceIcon";
import FeedbackIcon from "@mui/icons-material/Feedback";

interface Props {
  path?: string;
  text: string;
  icon: any;
  disabled?: boolean;
  externalUrl?: string;
  handleDrawerClose: () => void;
}

export const DashboardMenuItem: FC<Props> = ({
  path,
  text,
  icon,
  disabled,
  externalUrl,
  handleDrawerClose,
}) => {
  const LinkComponent = externalUrl ? "a" : Link;
  const linkProps = externalUrl
    ? { href: externalUrl, target: "_blank", rel: "noopener noreferrer" }
    : { to: path };
  const handleClick = () => {
    handleDrawerClose();
  };

  return (
    <ListItem
      disablePadding
      component={disabled ? "div" : LinkComponent}
      {...(disabled ? undefined : linkProps)}
      onClick={handleClick}
    >
      <ListItemButton disabled={disabled}>
        <ListItemIcon sx={{ color: color.primary_dark }}>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="caption" sx={{ color: color.primary_dark }}>
              {text}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

interface CollapseProps {
  text: string;
  icon: any;
  children: any;
}

export const DashboardMenuCollapse: FC<CollapseProps> = ({
  children,
  icon,
  text,
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((value) => !value);
  return (
    <ListItem disablePadding>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <ListItemButton onClick={toggleOpen}>
          <ListItemIcon sx={{ color: color.primary_dark }}>{icon}</ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="caption" sx={{ color: color.primary_dark }}>
                {text}
              </Typography>
            }
          />
        </ListItemButton>
        <Collapse in={open} style={{ paddingLeft: "1em" }}>
          {children}
        </Collapse>
      </div>
    </ListItem>
  );
};

type RoleType = {
  role: UserRole;
  handleDrawerClose: () => void;
};

export const DashboardMenu: FC<RoleType> = ({ handleDrawerClose }) => {
  const [releaseNotesOpen, setReleaseNotesOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const releaseButtonRef = useRef<HTMLDivElement>(null);

  const toggleReleaseNotes = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (releaseNotesOpen) {
      setReleaseNotesOpen(false);
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      setReleaseNotesOpen(true);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target as Node) &&
      releaseButtonRef.current &&
      !releaseButtonRef.current.contains(event.target as Node)
    ) {
      setReleaseNotesOpen(false);
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <List
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <DashboardMenuItem
        path="/"
        text="Home"
        icon={<Home />}
        handleDrawerClose={handleDrawerClose}
      />

      <DashboardMenuItem
        path="/profile"
        text="My Profile"
        icon={<AccountCircleIcon />}
        handleDrawerClose={handleDrawerClose}
      />

      {(hasRole(UserRole.ADMIN) ||
        hasPermission(Features.ORDER_AN_EXPERT) ||
        hasPermission(Features.DUSMANN_IMPORT)) && (
        <DashboardMenuCollapse text="Integrations" icon={<CloudSyncIcon />}>
          {(hasRole(UserRole.ADMIN) ||
            hasPermission(Features.ORDER_AN_EXPERT)) && (
            <DashboardMenuItem
              path="/expert-form"
              text="Order an Expert"
              icon={
                <img
                  src="/alan-icon.png"
                  alt="Alan Icon"
                  style={{ width: 24, height: 24 }}
                />
              }
              handleDrawerClose={handleDrawerClose}
            />
          )}
          {(hasRole(UserRole.ADMIN) ||
            hasPermission(Features.DUSMANN_IMPORT)) && (
            <DashboardMenuItem
              path="/upload-csv"
              text="Knowledge Import"
              icon={
                <img
                  src="/dussmann-logo.png"
                  alt="Knowledge Logo"
                  style={{ width: 24, height: 24 }}
                />
              }
              handleDrawerClose={handleDrawerClose}
            />
          )}
        </DashboardMenuCollapse>
      )}

      {(hasPermission(Features.EVALUATION) || hasRole(UserRole.ADMIN)) && (
        <DashboardMenuCollapse text="Company" icon={<AccountBalanceIcon />}>
          <DashboardMenuItem
            path="/company/create"
            text="Create a Company"
            icon={<Add />}
            handleDrawerClose={handleDrawerClose}
          />
          <DashboardMenuItem
            path="/company"
            text="My Companies"
            icon={<BusinessIcon />}
            handleDrawerClose={handleDrawerClose}
          />
        </DashboardMenuCollapse>
      )}

      {(hasPermission(Features.EVALUATION) ||
        hasRole(UserRole.ADMIN) ||
        hasPermission(Features.QUESTION_LISTS)) && (
        <DashboardMenuCollapse text="Company Audit" icon={<DynamicFormIcon />}>
          {(hasPermission(Features.EVALUATION) || hasRole(UserRole.ADMIN)) && (
            <DashboardMenuItem
              path="evaluation"
              text="Certificate Evaluation"
              icon={<Assessment />}
              handleDrawerClose={handleDrawerClose}
            />
          )}
          {(hasPermission(Features.QUESTION_LISTS) ||
            hasRole(UserRole.ADMIN)) && (
            <>
              <DashboardMenuItem
                path="/question_list/"
                text="Create a Question List"
                icon={<AddIcon />}
                handleDrawerClose={handleDrawerClose}
              />
              <DashboardMenuItem
                path="/questions_list/"
                text="My Question Lists"
                icon={<HelpCenterIcon />}
                handleDrawerClose={handleDrawerClose}
              />
            </>
          )}
        </DashboardMenuCollapse>
      )}

      <DashboardMenuItem
        path="/report-an-issue"
        text="Report an Issue"
        icon={<FeedbackIcon />}
        handleDrawerClose={handleDrawerClose}
      />
      <ListItem disablePadding>
        <ListItemButton onClick={toggleReleaseNotes} ref={releaseButtonRef}>
          <ListItemIcon sx={{ color: color.primary_dark }}>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="caption" sx={{ color: color.primary_dark }}>
                Release Notes
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>

      <Popper
        open={releaseNotesOpen}
        anchorEl={anchorEl}
        placement="right"
        style={{
          zIndex: 9999,
          height: "100vh",
          top: 0,
        }}
        ref={popperRef}
        modifiers={[
          {
            name: "preventOverflow",
            options: {
              boundary: "window",
            },
          },
        ]}
      >
        <Paper
          style={{
            backgroundColor: "white",
            opacity: 1,
            boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
            width: "300px",
            height: "100%",
            borderRadius: "12px",
          }}
        >
          <ReleaseNotes />
        </Paper>
      </Popper>

      <hr style={{ width: "100%", margin: "1em 0" }} />
      {hasRole(UserRole.ADMIN) && (
        <>
          <DashboardMenuItem
            path="users"
            text="Users"
            icon={<PeopleIcon />}
            handleDrawerClose={handleDrawerClose}
          />
          <DashboardMenuCollapse
            text="Certification Categories"
            icon={<ApprovalIcon />}
          >
            <DashboardMenuItem
              path="/cert_category/create"
              text="Create new"
              icon={<AddIcon />}
              handleDrawerClose={handleDrawerClose}
            />
            <DashboardMenuItem
              path="cert_category/"
              text="View all"
              icon={<StorageIcon />}
              handleDrawerClose={handleDrawerClose}
            />
          </DashboardMenuCollapse>
          <DashboardMenuCollapse text="Knowledge Bases" icon={<SchoolIcon />}>
            <DashboardMenuItem
              path="/knowledge_base/create"
              text="Create new"
              icon={<AddIcon />}
              handleDrawerClose={handleDrawerClose}
            />
            <DashboardMenuItem
              path="knowledge_base/"
              text="View all"
              icon={<StorageIcon />}
              handleDrawerClose={handleDrawerClose}
            />
          </DashboardMenuCollapse>
          <DashboardMenuCollapse text="Workspace" icon={<HubIcon />}>
            <DashboardMenuItem
              path="/workspace/create/"
              text="Create new"
              icon={<AddIcon />}
              handleDrawerClose={handleDrawerClose}
            />
            <DashboardMenuItem
              path="/workspace/"
              text="View all"
              icon={<StorageIcon />}
              handleDrawerClose={handleDrawerClose}
            />
          </DashboardMenuCollapse>
          <DashboardMenuItem
            externalUrl="https://corporate-momentum.sentry.io/issues/?environment=development&referrer=sidebar&statsPeriod=1h"
            text="Sentry Monitoring"
            icon={<MonitorHeartIcon />}
            handleDrawerClose={handleDrawerClose}
          />
        </>
      )}
      <ListItem
        style={{
          position: "relative",
          marginTop: "auto",
          bottom: 0,
          left: 0,
          padding: 0,
        }}
      >
        <ListItemIcon>
          <WorkspaceIcon />
        </ListItemIcon>
      </ListItem>
    </List>
  );
};
