import React from "react";
import { Grid, Typography } from "@mui/material";
import color from "../../Constants/colors";

interface InfoSectionProps {
  title: string;
  value: string;
}

const InfoSection: React.FC<InfoSectionProps> = ({ title, value }) => (
  <>
    <Grid
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item></Grid>
      <Grid item>
        <Typography
          variant="h5"
          sx={{ color: color.white, marginTop: 1, marginBottom: 1 }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h5"
          sx={{
            color: color.white,
            marginTop: 1,
            marginBottom: 1,
            textAlign: "center",
          }}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  </>
);

export default InfoSection;
