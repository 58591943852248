import {
  CompanyStatus,
  UserRole,
  Features,
} from "../Constants/Enums/companyStatus";
import { QuestionItem, QuestionListDetailsResponse } from "../Models/question";
import { enqueueSnackbar } from "notistack";
import ArticleIcon from "@mui/icons-material/Article";
import SlideshowIcon from "@mui/icons-material/Slideshow";

import color from "../Constants/colors";

export const hasPermission = (feature: Features): boolean => {
  const token = localStorage.getItem("cih_token");
  if (!token) return false;

  try {
    const payloadBase64 = token.split(".")[1];
    const decodedPayload = JSON.parse(atob(payloadBase64));
    const permissions: Features[] = decodedPayload.permissions || [];
    return permissions.includes(feature);
  } catch (error) {
    enqueueSnackbar("Error decoding permissions from token", {
      variant: "error",
    });
    return false;
  }
};

export const hasRole = (role: UserRole): boolean => {
  const token = localStorage.getItem("cih_token");
  if (!token) return false;

  try {
    const payloadBase64 = token.split(".")[1];
    const decodedPayload = JSON.parse(atob(payloadBase64));
    const userRole: UserRole = decodedPayload.role || null;
    return userRole === role;
  } catch (error) {
    enqueueSnackbar("Error decoding role from token", { variant: "error" });
    return false;
  }
};

export const findQuestionText = (
  questionKey: string,
  questionList: QuestionListDetailsResponse | undefined
): string => {
  if (!questionList) return questionKey;
  for (const item of questionList.categories) {
    const foundQuestion = item.questions.find(
      (q) => q.question_key === questionKey
    );
    if (foundQuestion) {
      return foundQuestion.question;
    }
  }
  return questionKey;
};

export const findIdByCertificationCategoryId = (
  categoryId: number,
  questionList: QuestionItem[]
): number | null => {
  const matchingObject = questionList.find(
    (item) => item.category.id === categoryId
  );
  return matchingObject ? matchingObject.id : null;
};

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const getTitleFromCamelcase = (str: string) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .split(" ")
    .map(capitalize)
    .join(" ");
};

export const getTooltipTitle = (status: CompanyStatus | undefined) => {
  switch (status) {
    case CompanyStatus.WAITING_FOR_EVALUATION:
      return "Waiting for Evaluation";
    case CompanyStatus.EVALUATED:
      return "Go to Evaluation";
    case CompanyStatus.NEEDS_MORE_INFO:
      return "Go to Questions";
    case CompanyStatus.EVALUATION_IN_PROGRESS:
      return "Evaluation in Progress";
    case CompanyStatus.ERROR:
      return "Error";
    case CompanyStatus.TIME_LIMIT_EXCEEDED:
      return "Error: time limit exceeded";
    default:
      return "Unknown Status";
  }
};

export const userRoleToString = (role: number) => {
  switch (role) {
    case UserRole.USER:
      return "User";
    case UserRole.ADMIN:
      return "Admin";
  }
};

interface AnyObject {
  [key: string]: any;
}

export const areObjectsEqual = (obj1: AnyObject, obj2: AnyObject): boolean => {
  // Ensure both objects are indeed objects and not null
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if both objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare each key-value pair
  return keys1.every((key) => {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = typeof val1 === "object" && typeof val2 === "object";

    // If both values are objects, recurse; otherwise, check for strict equality
    return areObjects ? areObjectsEqual(val1, val2) : val1 === val2;
  });
};

export const getFileIcon = (fileName: string): JSX.Element => {
  const cleanFileName = fileName.replace(/\s*\(.*?\)\s*$/, "");

  if (cleanFileName.toLowerCase().endsWith(".pdf"))
    return <img src="/pdf.png" alt="pdf" width="60" height="60" />;
  if (
    cleanFileName.toLowerCase().endsWith(".jpg") ||
    cleanFileName.toLowerCase().endsWith(".jpeg") ||
    cleanFileName.toLowerCase().endsWith(".png")
  )
    return <img src="/jpg.png" alt="jpg" width="60" height="60" />;
  if (
    cleanFileName.toLowerCase().endsWith(".doc") ||
    cleanFileName.toLowerCase().endsWith(".docx")
  )
    return <img src="/docx.png" alt="docx" width="60" height="60" />;
  if (
    cleanFileName.toLowerCase().endsWith(".ppt") ||
    cleanFileName.toLowerCase().endsWith(".pptx")
  )
    return (
      <SlideshowIcon fontSize="large" sx={{ color: color.primary_dark }} />
    );
  if (
    cleanFileName.toLowerCase().endsWith(".mp3") ||
    cleanFileName.toLowerCase().endsWith(".wav") ||
    cleanFileName.toLowerCase().endsWith(".aac") ||
    cleanFileName.toLowerCase().endsWith(".ogg") ||
    cleanFileName.toLowerCase().endsWith(".aiff") ||
    cleanFileName.toLowerCase().endsWith(".wma")
  )
    return <img src="/mpr3.png" alt="mpr3" width="60" height="60" />;
  if (cleanFileName.toLowerCase().endsWith(".csv"))
    return <img src="/csv.png" alt="csv" width="60" height="60" />;
  if (cleanFileName.toLowerCase().endsWith(".txt"))
    return <img src="/txt.png" alt="txt" width="60" height="60" />;

  return <ArticleIcon fontSize="large" sx={{ color: color.primary_dark }} />;
};
export const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return "";

  const date = new Date(dateString);

  if (isNaN(date.getTime())) return "";

  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  const weekday = date.toLocaleDateString("en-US", {
    weekday: options.weekday,
  });
  const dayMonthYear = date.toLocaleDateString("en-US", {
    year: options.year,
    month: options.month,
    day: options.day,
  });

  return `${weekday}, ${dayMonthYear}`;
};

export const truncateString = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) return str;
  return `${str.substring(0, maxLength - 3)}...`;
};

export const downloadJSON = (json: any, filename: string) => {
  const jsonStr = JSON.stringify(json, null, 2);
  const blob = new Blob([jsonStr], { type: "application/json" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

export const getSubstringAfterLastPeriod = (input: string): string => {
  const lastIndex = input.lastIndexOf(".");

  if (lastIndex === -1) {
    return "";
  }

  return input.substring(lastIndex + 1);
};

export const getGradeDescription = (grade: number): string => {
  switch (true) {
    case grade >= 90:
      return "Exemplary";
    case grade >= 80:
      return "Fully Compliant";
    case grade >= 70:
      return "Substantially Compliant";
    case grade >= 60:
      return "Partially Compliant";
    case grade >= 40:
      return "Basic Compliance";
    case grade > 0:
      return "Non-Compliant";
    default:
      return "Not enough information";
  }
};

export const allowedExtensions = [
  "doc",
  "docx",
  "pdf",
  "png",
  "jpg",
  "jpeg",
  "txt",
  "ppt",
  "pptx",
  "mp3",
  "wav",
  "aac",
  "ogg",
  "aiff",
  "wma",
];

export const allowedExtensionsKnowledge = [
  "doc",
  "docx",
  "pdf",
  "txt",
  "ppt",
  "pptx",
];
