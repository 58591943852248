import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TablePagination,
  Grid,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import {
  deleteDocument,
  downloadDocument,
  uploadFile,
} from "../../Services/Https/company";
import { useParams } from "react-router-dom";
import { Document } from "../../Models/company";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import color from "../../Constants/colors";
import { useDocuments } from "../../Hooks/useCompanies";
import { ProcessingStatus } from "../../Constants/Enums/companyStatus";
import CloseIcon from "@mui/icons-material/Close";
import { isStatusToStringFileError } from "../../Services/Functions/statusToStringFile";
import { allowedExtensions } from "../../helpers/helpers";
import FileUploaderAction from "../UI/FileUploader/FileUploaderAction";
import FileUploader from "../UI/FileUploader/FileUploader";
import UploadedFilesPanel from "../UI/UploadedFilesPanel";

interface FileUploadModalProps {
  open: boolean;
  onClose: () => void;
  documents: Document[];
}

const isAxiosError = (error: any): error is AxiosError => {
  return (error as AxiosError).response !== undefined;
};

export const handleSubmitFiles = async (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  companyId: string,
  files: File[],
  onFinish: (name: string, id: number) => void,
  setIsUploadingFiles: React.Dispatch<React.SetStateAction<boolean>>,
  refetchDocuments: any
) => {
  event.preventDefault();
  setIsUploadingFiles(true);

  const uploadStatuses: boolean[] = [];

  for (const file of files) {
    const formData = new FormData();
    formData.append("company_id", companyId);
    formData.append("document_name", file.name);
    formData.append("file", file);

    try {
      await upload(formData, onFinish);
      uploadStatuses.push(true);
    } catch {
      uploadStatuses.push(false);
    }
  }

  if (files.length === 1) {
    if (uploadStatuses[0]) {
      enqueueSnackbar("Successfully uploaded file", { variant: "success" });
    }
  } else {
    if (uploadStatuses.every((status) => status)) {
      enqueueSnackbar("Successfully uploaded all files", {
        variant: "success",
      });
    }
  }

  refetchDocuments();
  setIsUploadingFiles(false);
};

const upload = async (
  formData: FormData,
  onFinish: (name: string, id: number) => void
) => {
  try {
    const response = await uploadFile(formData);
    const documentId = response.data.document_id;
    onFinish(formData.get("document_name") as string, documentId);
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 409) {
      enqueueSnackbar("File is already uploaded", { variant: "error" });
    } else {
      enqueueSnackbar("Failed to upload file", { variant: "error" });
    }
    throw err;
  }
};

export enum SortBy {
  Name = "name",
  Date = "date",
  Type = "type",
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({ open, onClose }) => {
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [page, setPage] = useState(0);
  const [uploadedFilesPage, setUploadedFilesPage] = useState(0);
  const [uploadedFilesRowsPerPage] = useState(10);
  const { companyId } = useParams<{ companyId: string }>();
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Name);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);
  const { data: documentsData, refetch: refetchDocuments } = useDocuments(
    companyId!,
    uploadedFilesPage + 1,
    uploadedFilesRowsPerPage,
    sortBy.toString(),
    sortOrder.toString()
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleFinishUpload = (name: string, id: number) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== name)
    );
  };

  const handleRemoveFile = (fileName: string) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const handleOpenFile = (file: File) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const handleDeleteDocument = async (documentId: number) => {
    setIsDeleting(true);
    try {
      await deleteDocument(documentId);
      enqueueSnackbar("Document deleted successfully", { variant: "success" });

      if (documentsData) {
        const remainingDocuments = documentsData.documents.length - 1;
        const newPage =
          uploadedFilesPage > 0 &&
          remainingDocuments % uploadedFilesRowsPerPage === 0
            ? uploadedFilesPage - 1
            : uploadedFilesPage;

        setUploadedFilesPage(newPage);
      }

      refetchDocuments();
    } catch (error) {
      enqueueSnackbar("Failed to delete document. Please try again.", {
        variant: "error",
      });
    } finally {
      setTimeout(() => {
        setIsDeleting(false);
      }, 500);
    }
  };

  const clearAllFiles = () => {
    setUploadedFiles([]);
    setPage(0);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "85%", md: "80%" },
          height: "80vh",
          overflow: "auto",
          backgroundColor: color.white,
          p: 4,
          borderRadius: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              borderRight: { sm: "1px solid #032e4d" },
              borderColor: color.primary_dark,
              height: "100%",
              overflowY: "auto",
            }}
          >
            <Typography variant="h3" sx={{ mb: 2, color: color.primary_dark }}>
              Upload New Documents
            </Typography>

            <Box
              sx={{
                width: "80%",
                overflowY: "auto",
                padding: 2,
                backgroundColor: color.white,
                borderRadius: 2,
                boxSizing: "border-box",
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                marginTop: "48px",
              }}
            >
              <FileUploader
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                allowedExtensions={allowedExtensions}
                page={page}
                rowsPerPage={uploadedFiles.length}
                handleRemoveFile={handleRemoveFile}
                handleOpenFile={handleOpenFile}
              />
            </Box>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={5}
              mt={4}
            >
              <FileUploaderAction
                buttonTitle="Clear"
                tooltipTitle="No files to clear"
                disableHoverListener={uploadedFiles.length > 0}
                disabled={isUploadingFiles || uploadedFiles.length === 0}
                onClick={clearAllFiles}
              />
              <FileUploaderAction
                buttonTitle="Submit"
                tooltipTitle={
                  uploadedFiles.length === 0 ? "No files to submit" : ""
                }
                disableHoverListener={uploadedFiles.length > 0}
                disabled={isUploadingFiles || uploadedFiles.length === 0}
                onClick={(event) => {
                  handleSubmitFiles(
                    event,
                    companyId!,
                    uploadedFiles,
                    handleFinishUpload,
                    setIsUploadingFiles,
                    refetchDocuments
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "100%",
              paddingLeft: { sm: 2 },
            }}
          >
            <Typography variant="h3" sx={{ mb: 2, color: color.primary_dark }}>
              Company Documents
            </Typography>

            <Grid
              container
              direction="row"
              justifyContent="center"
              spacing={1}
              mb={4}
            >
              <Grid item>
                <FormControl sx={{ width: 120 }}>
                  <Select
                    value={sortBy}
                    onChange={(event) => {
                      setSortBy(event.target.value as SortBy);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: color.white,
                          opacity: 1,
                          minWidth: 120,
                        },
                      },
                    }}
                    sx={{
                      fontSize: "0.75rem",
                      padding: "4px 8px",
                      height: 32,
                    }}
                  >
                    <MenuItem value={SortBy.Name}>
                      <Typography
                        variant="body2"
                        sx={{ color: color.primary_dark }}
                      >
                        Name
                      </Typography>
                    </MenuItem>
                    <MenuItem value={SortBy.Date}>
                      <Typography
                        variant="body2"
                        sx={{ color: color.primary_dark }}
                      >
                        Date
                      </Typography>
                    </MenuItem>
                    <MenuItem value={SortBy.Type}>
                      <Typography
                        variant="body2"
                        sx={{ color: color.primary_dark }}
                      >
                        Type
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl sx={{ ml: 2, width: 150 }}>
                  <Select
                    value={sortOrder}
                    onChange={(event) => {
                      setSortOrder(event.target.value as SortOrder);
                    }}
                    sx={{
                      fontSize: "0.75rem",
                      padding: "4px 8px",
                      height: 32,
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: color.white,
                          opacity: 1,
                          minWidth: 120,
                        },
                      },
                    }}
                  >
                    <MenuItem value={SortOrder.Asc}>
                      <Typography
                        variant="body2"
                        sx={{ color: color.primary_dark }}
                      >
                        Ascending
                      </Typography>
                    </MenuItem>
                    <MenuItem value={SortOrder.Desc}>
                      <Typography
                        variant="body2"
                        sx={{ color: color.primary_dark }}
                      >
                        Descending
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box
              sx={{
                overflowY: "auto",
                width: "80%",
                height: "65%",
                padding: 1,
                borderRadius: 2,
                border: "2px solid #032e4d",
              }}
            >
              {documentsData && documentsData.documents.length === 0 ? (
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    color: color.primary_dark,
                    padding: 4,
                  }}
                >
                  No uploaded files
                </Typography>
              ) : (
                <Grid
                  container
                  direction="column"
                  justifyContent="start"
                  spacing={1}
                  sx={{ flexGrow: 1 }}
                >
                  {documentsData &&
                    documentsData.documents.map((file) => {
                      const isError = isStatusToStringFileError(file.status);
                      return (
                        <Grid
                          item
                          xs={12}
                          key={file.name}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            position: "relative",
                            padding: 1,
                            borderBottom: "1px solid #032e4d",
                            "&:last-child": {
                              borderBottom: "none",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyItems: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <IconButton
                                size="small"
                                sx={{ color: "gray", cursor: "default" }}
                              >
                                {isError && (
                                  <CloseIcon
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      zIndex: 2,
                                      opacity: 0.8,
                                      color: color.red,
                                      pointerEvents: "none",
                                    }}
                                  />
                                )}
                                <UploadedFilesPanel
                                  file={file}
                                  getFileName={(file) => file.name}
                                  getFileStatus={(file) => file.status}
                                />
                              </IconButton>

                              <Typography
                                variant="body2"
                                sx={{
                                  color: color.primary_dark,
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  marginLeft: 1,
                                }}
                                title={file.name}
                              >
                                {file.name.length > 35
                                  ? `${file.name.slice(0, 35)}...`
                                  : file.name}
                              </Typography>

                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "auto",
                                }}
                              >
                                <Tooltip
                                  title={isError ? "" : "Download document"}
                                >
                                  <span>
                                    <IconButton
                                      size="small"
                                      sx={{
                                        color: "orange",
                                        opacity:
                                          isError ||
                                          file.status ===
                                            ProcessingStatus.IN_PROGRESS
                                            ? 0.5
                                            : 1,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadDocument(
                                          file.id.toString(),
                                          file.name,
                                          setIsDownloading
                                        );
                                      }}
                                      disabled={
                                        isDownloading ||
                                        file.status ===
                                          ProcessingStatus.IN_PROGRESS ||
                                        isError
                                      }
                                    >
                                      <CloudDownloadIcon fontSize="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>

                                <Tooltip
                                  title={isError ? "" : "Delete document"}
                                >
                                  <span>
                                    <IconButton
                                      size="small"
                                      sx={{
                                        color: color.red,
                                        opacity:
                                          isError ||
                                          file.status ===
                                            ProcessingStatus.IN_PROGRESS
                                            ? 0.5
                                            : 1,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteDocument(file.id);
                                      }}
                                      disabled={
                                        isDeleting ||
                                        file.status ===
                                          ProcessingStatus.IN_PROGRESS
                                      }
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
            </Box>

            <TablePagination
              component="div"
              count={documentsData?.total || 0}
              rowsPerPage={uploadedFilesRowsPerPage}
              page={uploadedFilesPage}
              rowsPerPageOptions={[]}
              onPageChange={(_, newPage) => setUploadedFilesPage(newPage)}
              sx={{
                marginTop: 4,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FileUploadModal;
