import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import colors from "../../Constants/colors";
import { getUsers } from "../../Services/Https/user";
import { enqueueSnackbar } from "notistack";

interface User {
  id: number;
  email: string;
  workspace?: {
    id: number | null;
    name: string | null;
  } | null;
}

const WorkspaceAdminId: React.FC<{
  currentAdminEmail: string | null;
  onChange: (userId: number | null) => void;
}> = ({ currentAdminEmail, onChange }) => {
  const [, setSearchTerm] = useState("");
  const [results, setResults] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);

  const fetchResults = async (query: string) => {
    setLoading(true);
    try {
      const response = await getUsers(1, 10, query);
      const users = response.users || [];
      const filteredUsers = users.filter((user) => !user.workspace);
      setResults(filteredUsers);
    } catch (error) {
      enqueueSnackbar("Error fetching users.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setSearchTerm(newValue);
    if (newValue) {
      fetchResults(newValue);
    } else {
      setResults([]);
    }
  };

  const handleChange = (
    _: React.SyntheticEvent,
    value: string | User | null
  ) => {
    if (typeof value === "object" && value !== null) {
      setSelectedEmail(value.email);
      onChange(value.id);
    } else {
      setSelectedEmail(null);
      onChange(null);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          options={results}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.email
          }
          onInputChange={handleInputChange}
          onChange={handleChange}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search and assign a new admin"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                style: {
                  color: colors.white,
                  borderBottom: `2px solid ${colors.white}`,
                  backgroundColor: "transparent",
                },
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
              key={typeof option === "string" ? option : option.id}
              style={{
                padding: "10px 16px",
                backgroundColor: colors.white,
                color: colors.textPrimary,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                opacity: option.workspace ? 0.6 : 1,
              }}
            >
              {typeof option === "string" ? option : option.email}
              {option.workspace && (
                <Typography
                  variant="body2"
                  component="div"
                  style={{
                    fontSize: "16px",
                    color: colors.textSecondary,
                    marginTop: "1px",
                    opacity: 0.8,
                  }}
                >
                  Workspace: {option.workspace.name}
                </Typography>
              )}
            </li>
          )}
          noOptionsText="No users found"
          sx={{
            "& .MuiAutocomplete-listbox": {
              backgroundColor: colors.white,
              color: colors.primary_dark,
              padding: 0,
              width: "100%", // Ensure dropdown width consistency
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: colors.white,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            color: colors.white,
            textAlign: "center",
          }}
        >
          {selectedEmail
            ? `Selected Admin: ${selectedEmail}`
            : currentAdminEmail
            ? `Current Admin: ${currentAdminEmail}`
            : "No admin assigned"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WorkspaceAdminId;
