import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Box,
  IconButton,
  Popover,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import color from "../../Constants/colors";
import { useLanguages } from "../../Hooks/useLanguages";
import ToggleAlanExpert from "./ToggleAlanExpert";
import { Languages } from "../../Constants/Enums/companyStatus";

interface LanguageSelectProps {
  languageId: number;
  onLanguageChange: (id: number) => void;
  onToggleAlanExpertChange: (enabled: boolean) => void;
  showToggleAlanExpert: boolean;
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({
  languageId,
  onLanguageChange,
  onToggleAlanExpertChange,
  showToggleAlanExpert,
}) => {
  const [selectedLanguageID, setSelectedLanguageID] =
    useState<number>(languageId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openSettings, setOpenSettings] = useState<boolean>(false);

  const { data: languages } = useLanguages();

  useEffect(() => {
    setSelectedLanguageID(languageId);
  }, [languageId]);

  const handleToggleDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSelectChange = (id: number) => {
    setSelectedLanguageID(id);
    setAnchorEl(null);
    onLanguageChange(id);
  };

  const open = Boolean(anchorEl);

  const handleToggleSettings = () => {
    setOpenSettings(!openSettings);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: "2px",
        marginLeft: "1px",
        color: color.white,
      }}
    >
      <Typography
        variant="body1"
        sx={{ color: "grey", marginRight: "2px", cursor: "pointer" }}
        onClick={handleToggleSettings}
      >
        Additional settings
      </Typography>

      <Collapse in={openSettings} timeout="auto" unmountOnExit>
        <Box display="flex" alignItems="center" marginTop="4px">
          <Typography
            variant="body2"
            sx={{ color: "grey", fontSize: "0.875rem" }}
          >
            Language:{" "}
            <span style={{ color: "lightgray" }}>
              {languages?.find(
                (lang) => lang.language_id === selectedLanguageID
              )?.language_name || "Select Language"}
              {(selectedLanguageID === Languages.ENGLISH ||
                selectedLanguageID === Languages.GERMAN) &&
                " (recommended)"}
            </span>
          </Typography>
          <IconButton
            onClick={handleToggleDropdown}
            sx={{ color: "grey", padding: 0, marginLeft: "4px" }}
            aria-haspopup="true"
            aria-expanded={open ? "true" : "false"}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: color.primary_dark,
              opacity: 0.95,
              color: "white",
              mt: 1,
            },
          }}
        >
          <Box>
            {languages &&
              languages.map((lang) => (
                <MenuItem
                  key={lang.language_id}
                  onClick={() => handleSelectChange(lang.language_id)}
                  sx={{ color: "white" }}
                >
                  {lang.language_name}
                </MenuItem>
              ))}
          </Box>
        </Popover>
        {showToggleAlanExpert && (
          <ToggleAlanExpert onToggle={onToggleAlanExpertChange} />
        )}
      </Collapse>
    </Box>
  );
};

export default LanguageSelect;
