import React, { useEffect, useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  Grid,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import {
  getEvaluationHistory,
  downloadEvaluation,
} from "../../Services/Https/evaluations";
import {
  EvaluationHistory as EvaluationHistoryType,
  FileData,
} from "../../Models/evaluation";
import colors from "../../Constants/colors";
import { enqueueSnackbar } from "notistack";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { formatDate } from "../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

interface EvaluationHistoryProps {
  open: boolean;
  onClose: () => void;
  companyId: string;
}

const EvaluationHistory: React.FC<EvaluationHistoryProps> = ({
  open,
  onClose,
  companyId,
}) => {
  const [evaluationData, setEvaluationData] =
    useState<EvaluationHistoryType | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<string>("created_at");
  const navigate = useNavigate();

  const fetchEvaluationHistory = useCallback(
    async (
      page: number,
      rowsPerPage: number,
      search: string,
      sort: "asc" | "desc",
      sortField: string
    ) => {
      try {
        const response = await getEvaluationHistory(
          companyId,
          page,
          rowsPerPage,
          search,
          sortField,
          sort
        );
        setEvaluationData(response);
      } catch (error) {
        console.error("Error fetching evaluation history", error);
      }
    },
    [companyId]
  );

  useEffect(() => {
    if (open) {
      fetchEvaluationHistory(page, rowsPerPage, search, sortOrder, sortField);
    }
  }, [
    open,
    page,
    rowsPerPage,
    search,
    sortOrder,
    sortField,
    fetchEvaluationHistory,
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchEvaluationHistory(newPage, rowsPerPage, search, sortOrder, sortField);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSortChange = (field: string, order: "asc" | "desc") => {
    setSortField(field);
    setSortOrder(order);
    fetchEvaluationHistory(page, rowsPerPage, search, order, field);
  };

  const handleDownload = async (
    name: string,
    user_evaluation_request_id: string
  ) => {
    try {
      const blob: Blob = await downloadEvaluation(user_evaluation_request_id);
      const element = document.createElement("a");
      const url = URL.createObjectURL(blob);
      element.href = url;
      element.download = `${name}.pdf`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(url);
    } catch (error) {
      enqueueSnackbar("Failed to download the evaluation", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
        PaperProps={{ sx: { minWidth: "50%", backgroundImage: colors.white } }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color={colors.primary_dark}
            >
              Evaluation History
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={6}
              display="flex"
              alignItems="center"
              sx={{ paddingLeft: "2rem" }}
            >
              <SearchIcon
                sx={{ fontSize: "2rem", color: colors.primary_dark }}
              />
              <TextField
                label="Search"
                fullWidth
                variant="outlined"
                value={search}
                size="small"
                onChange={handleSearchChange}
                InputLabelProps={{
                  style: {
                    color: colors.primary_dark,
                    fontSize: "20px",
                    width: "60%",
                  },
                }}
                sx={{
                  width: "220px",
                  "& .MuiInputLabel-root": {
                    color: colors.primary_dark,
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: colors.btn_dark,
                    },
                    "&:hover fieldset": {
                      borderColor: colors.btn_dark,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: colors.btn_dark,
                    },
                    "& input": {
                      color: colors.primary_dark,
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              paddingRight="2rem"
            >
              <Typography variant="body2" sx={{ color: colors.primary_dark }}>
                Evaluated at
              </Typography>
              <IconButton
                sx={{
                  fontWeight: "bold",
                  color: colors.btn_dark,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleSortChange(
                    sortField,
                    sortOrder === "asc" ? "desc" : "asc"
                  )
                }
              >
                {sortOrder === "asc" ? <ArrowUpward /> : <ArrowDownward />}
              </IconButton>
            </Grid>
          </Grid>

          {evaluationData ? (
            evaluationData.files.length > 0 ? (
              <Box sx={{ marginTop: "1.5rem" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {evaluationData.files.map((file: FileData) => (
                        <TableRow key={file.user_evaluation_request_id}>
                          <TableCell sx={{ color: colors.primary_dark }}>
                            {file.name}
                            <Typography
                              variant="body2"
                              sx={{
                                color: colors.btn_dark,
                                fontSize: "0.8rem",
                                marginTop: "4px",
                              }}
                            >
                              Evaluated at: {formatDate(file.created_at)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex", direction: "row" }}>
                              <Typography
                                sx={{
                                  fontSize: "1rem",
                                  color: colors.primary_dark,
                                  marginRight: "5px",
                                }}
                              >
                                Grade:{" "}
                              </Typography>
                              <Typography
                                sx={{
                                  fonstSize: "1rem",
                                  color: colors.btn_dark,
                                }}
                              >
                                {file.grade}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            <Tooltip
                              title="Download Evaluation"
                              placement="top"
                            >
                              <IconButton
                                sx={{
                                  color: colors.primary_dark,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleDownload(
                                    file.name,
                                    file.user_evaluation_request_id
                                  );
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={evaluationData.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: colors.primary_dark,
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  You do not have any evaluations. If you want to start an
                  evaluation, click the button.
                </Typography>
                <Button
                  sx={{
                    backgroundColor: colors.btn_dark,
                    marginTop: "2rem",
                    "&:hover": {
                      backgroundColor: `${colors.primary_dark}`,
                      color: `${colors.btn_dark}`,
                    },
                  }}
                  onClick={() => navigate("/evaluation")}
                >
                  Start evaluation
                </Button>
              </Box>
            )
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress sx={{ color: colors.primary_dark }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: colors.btn_dark,
              color: colors.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: colors.primary_dark,
                color: colors.btn_dark,
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EvaluationHistory;
