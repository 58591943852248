import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuestionListsforUser } from "../../Hooks/useQuestions";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import { QuestionItem } from "../../Models/question";
import color from "../../Constants/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
  exportQuestionList,
  setDefaultQuestionList,
} from "../../Services/Https/questions";
import { useSnackbar } from "notistack";
import { downloadJSON } from "../../helpers/helpers";
import { handleAxiosError } from "../../Services/Https/errorHandler";

interface QuestionListsTableProps {
  userId: number;
}

const QuestionListsTable: React.FC<QuestionListsTableProps> = ({ userId }) => {
  const { data, isLoading, isError, error, refetch } =
    useQuestionListsforUser(userId);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [selectedList, setSelectedList] = useState<QuestionItem | null>(null);

  const handleClickOpen = (questionList: QuestionItem) => {
    setSelectedList(questionList);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedList(null);
  };

  const handleConfirm = async () => {
    if (selectedList) {
      try {
        await setDefaultQuestionList(selectedList.id, selectedList.category.id);
        enqueueSnackbar("Successfully set as default question list", {
          variant: "success",
        });
        refetch();
      } catch (error) {
        enqueueSnackbar("Error setting default question list", {
          variant: "error",
        });
      }
      handleClose();
    }
  };

  const handleExportList = async (id: number, name: string) => {
    try {
      const response = await exportQuestionList(id);
      downloadJSON(response, `${name}.json`);
      enqueueSnackbar(`Question list exported successfully.`, {
        variant: "success",
      });
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error exporting question list: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (isError) return <div>Error: {error.message}</div>;

  return (
    <Grid container direction="column" alignItems="left" mb={5}>
      <Grid item mt={5}>
        <Typography variant="h4" sx={{ color: "white" }}>
          User Question Lists
        </Typography>
      </Grid>
      <Grid item mt={2}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: color.btn_dark, fontWeight: "bolder" }}>
                  #
                </TableCell>
                <TableCell sx={{ color: color.btn_dark, fontWeight: "bolder" }}>
                  Name
                </TableCell>
                <TableCell sx={{ color: color.btn_dark, fontWeight: "bolder" }}>
                  Category
                </TableCell>
                <TableCell sx={{ color: color.btn_dark, fontWeight: "bolder" }}>
                  Default
                </TableCell>
                <TableCell sx={{ color: color.btn_dark, fontWeight: "bolder" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.question_lists && data.question_lists.length > 0 ? (
                data.question_lists.map(
                  (question_list: QuestionItem, index: number) => (
                    <TableRow
                      key={question_list.id}
                      sx={{
                        "&:hover": { backgroundColor: color.primary_dark },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: question_list.default ? "bold" : "normal",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/question_list/${question_list.id}`)
                        }
                      >
                        <Tooltip
                          title={
                            question_list.default
                              ? "This is the default list"
                              : ""
                          }
                        >
                          <span>{question_list.name}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{question_list.category.name}</TableCell>
                      <TableCell>
                        {question_list.default ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Make Default">
                          <IconButton
                            onClick={() => handleClickOpen(question_list)}
                            color="primary"
                            disabled={question_list.default}
                          >
                            {question_list.default ? (
                              <CheckCircleIcon />
                            ) : (
                              <CheckCircleOutlineIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={`export ${question_list.name} question list`}
                        >
                          <IconButton
                            onClick={() =>
                              handleExportList(
                                question_list.id,
                                question_list.name
                              )
                            }
                            color="primary"
                          >
                            <IosShareIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="left">
                    No question lists available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ color: color.primary_dark }}>
          Confirm Default
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: color.primary_dark }}>
            Are you sure you want to make the question list "
            {selectedList?.name}" default for the certification category "
            {selectedList?.category.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            onClick={handleConfirm}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default QuestionListsTable;
