import React from "react";
import { Input } from "@mui/material";
import color from "../../Constants/colors";

interface SearchInputProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchTerm,
  setSearchTerm,
}) => {
  return (
    <Input
      placeholder="Search..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      sx={{
        width: "30%",
        marginBottom: "30px",
        color: color.white,
        textAlign: "center",
      }}
      className="custom-input"
    />
  );
};

export default SearchInput;
