import axios from "axios";
import { Workspace, GetWorkspaceResponse } from "../../Models/workspace";
import { ModelLevel } from "../../Constants/Enums/companyStatus";

export const createWorkspace = async (
  name: string,
  description: string,
  userEvaluationLimit: number,
  userCompanyLimit: number,
  namespaces: string[],
  iconImage: File | null,
  workspaceAdminId: number | null,
  features: number[] = [],
  certifications: number[] = [],
  model_level: ModelLevel,
): Promise<any> => {
  const formData = new FormData();

  formData.append("name", name);
  formData.append("description", description);
  formData.append("user_evaluation_limit", userEvaluationLimit.toString());
  formData.append("user_company_limit", userCompanyLimit.toString());
  formData.append("namespaces", JSON.stringify(namespaces));
  formData.append("features", JSON.stringify(features));
  formData.append("certifications", JSON.stringify(certifications));
  formData.append("model_level", model_level.toString());

  if (workspaceAdminId !== null) {
    formData.append("workspace_admin_id", workspaceAdminId.toString());
  }

  if (iconImage) {
    formData.append("icon_image", iconImage);
  }

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/workspace/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cih_token")}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllWorkspaces = async (
  page: number,
  pageSize: number
): Promise<{ workspaces: Workspace[]; total: number }> => {
  try {
    const { data } = await axios.get<{
      workspaces: Workspace[];
      total: number;
    }>(`${process.env.REACT_APP_BASE_URL}/v1/workspace/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("cih_token")}` },
      params: { page, page_size: pageSize },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getWorkspace = async (
  workspaceId: string
): Promise<GetWorkspaceResponse> => {
  try {
    const { data } = await axios.get<GetWorkspaceResponse>(
      `${process.env.REACT_APP_BASE_URL}/v1/workspace/${workspaceId}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cih_token")}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error;
  }
};
export const updateWorkspace = async (
  workspaceId: number,
  name: string,
  description: string,
  userEvaluationLimit: number,
  userCompanyLimit: number,
  namespaces: string[],
  iconImage: File | null,
  workspaceAdminId: number | null,
  features: number[] = [],
  certifications: number[] = [],
  model_level: ModelLevel,
): Promise<any> => {
  const formData = new FormData();

  if (name) formData.append("name", name);
  if (description) formData.append("description", description);
  if (userEvaluationLimit)
    formData.append("user_evaluation_limit", userEvaluationLimit.toString());
  if (userCompanyLimit)
    formData.append("user_company_limit", userCompanyLimit.toString());
  if (namespaces && namespaces.length > 0)
    formData.append("namespaces", JSON.stringify(namespaces));
  if (features && features.length > 0)
    formData.append("features", JSON.stringify(features.map(Number)));
  if (certifications && certifications.length > 0)
    formData.append(
      "certifications",
      JSON.stringify(certifications.map(Number))
    );
  if (workspaceAdminId !== null)
    formData.append("workspace_admin_id", workspaceAdminId.toString());
  formData.append("model_level", model_level.toString());
  if (iconImage) {
    formData.append("icon_image", iconImage);
  }

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/v1/workspace/${workspaceId}/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cih_token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteWorkspace = async (workspaceId: number): Promise<void> => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/v1/workspace/${workspaceId}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cih_token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};


export const getCurrentWorkspace = async (): Promise<GetWorkspaceResponse> => {
  try {
    const { data } = await axios.get<GetWorkspaceResponse>(
      `${process.env.REACT_APP_BASE_URL}/v1/workspace/current/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cih_token")}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error;
  }
};
