import React from "react";
import { Box, Typography } from "@mui/material";
import SourceLabel from "./SourceLabel";
import color from "../../Constants/colors";

interface SourceBarProps {
  sources: string[];
  title: string;
}

const SourceBar: React.FC<SourceBarProps> = ({ sources, title }) => {
  if (!sources || sources.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        overflowX: "scroll",
        display: "flex",
        flexDirection: "column",
        padding: "8px",
        marginTop: 2,
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      }}
    >
      <Typography
        variant="caption"
        sx={{ marginBottom: 1, color: color.primary_dark }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          alignItems: "center",
        }}
      >
        {sources.map((source, index) => (
          <div key={index}>
            <SourceLabel fileName={source} />
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default SourceBar;
