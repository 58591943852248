import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import colors from "../../Constants/colors";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useKnowledgeList } from "../../Hooks/useKnowledge";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import color from "../../Constants/colors";
import {
  useCreateCertification,
  useSingleCertification,
  useUpdateCertification,
} from "../../Hooks/useCertifications";
import { getCertCategories } from "../../Services/Https/certifications";
import { Certification } from "../../Models/certification";
import { useExpert } from "../../Hooks/useExpert";
import { KnowledgeBaseStatus } from "../../Constants/Enums/companyStatus";

const CertCategory = () => {
  const [categoryName, setCategoryName] = useState("");
  const [isDigitalPerformance, setIsDigitalPerformance] = useState(false);
  const [knowledgeBaseIds, setKnowledgeBaseIds] = useState<number[]>([]);
  const [selectedExpertId, setSelectedExpertId] = useState<string | null>("");
  const [selectedExpertName, setSelectedExpertName] = useState<string | "">("");
  const [existingCategories, setExistingCategories] = useState<Certification[]>(
    []
  );
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [openKnowledgeBaseSection, setOpenKnowledgeBaseSection] =
    useState(false);
  const navigate = useNavigate();
  const { certCategoryId } = useParams<{ certCategoryId?: string }>();
  const location = useLocation();

  const { data: expertList, isLoading: isExpertListLoading } = useExpert();

  const {
    data: knowledgeBaseList,
    isLoading: knowledgeBaseListLoading,
    isError: knowledgeBaseListError,
  } = useKnowledgeList();

  const { mutate: createCategory, isLoading: isCreating } =
    useCreateCertification();

  const {
    data: singleCertification,
    isLoading: isLoadingSingleCert,
    isError: isFetchingError,
    error: fetchingError,
  } = useSingleCertification(Number(certCategoryId));

  const { mutate: updateCategory, isLoading: isUpdating } =
    useUpdateCertification();

  useEffect(() => {
    if (certCategoryId) {
      setMode("edit");
    } else {
      setMode("create");
      setCategoryName("");
      setIsDigitalPerformance(false);
      setKnowledgeBaseIds([]);
      setSelectedExpertId("");
    }
  }, [certCategoryId]);

  useEffect(() => {
    if (mode === "edit" && singleCertification) {
      setCategoryName(singleCertification.name);
      setIsDigitalPerformance(singleCertification.digital_performance);
      const kbIds = singleCertification.knowledge_bases.map((kb) => kb.id);
      setKnowledgeBaseIds(kbIds);
      setSelectedExpertId(singleCertification.expert?.alan_expert_id || "");
    }
  }, [mode, singleCertification]);

  useEffect(() => {
    getCertCategories().then((categories) => {
      setExistingCategories(categories);
    });
  }, []);

  useEffect(() => {
    if (isFetchingError && fetchingError.response?.status === 404) {
      enqueueSnackbar(
        "The requested Certification Category no longer exists.",
        {
          variant: "error",
        }
      );
      navigate("/cert_category", { replace: true });
    }
  }, [isFetchingError, fetchingError, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (categoryName.trim() === "") {
      enqueueSnackbar("Category name cannot be empty.", { variant: "error" });
      return;
    }

    const categoryExists = existingCategories.some(
      (category) => category.name === categoryName
    );

    if (categoryExists && mode !== "edit") {
      enqueueSnackbar(`Category "${categoryName}" already exists.`, {
        variant: "error",
      });
    } else {
      const payload = {
        name: categoryName,
        digital_performance: isDigitalPerformance,
        knowledge_base_ids: knowledgeBaseIds,
        alan_expert_id: selectedExpertId || null,
      };
      if (mode === "create") {
        createCategory(payload);
      } else {
        updateCategory({
          id: Number(certCategoryId),
          updatedCategory: payload,
        });
      }
    }
  };

  const handleKnowledgeBaseToggle = (id: number) => {
    setKnowledgeBaseIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((kbId) => kbId !== id)
        : [...prevIds, id]
    );
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ padding: 4, backgroundColor: "transparent", borderRadius: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          textAlign="center"
          sx={{ color: colors.white }}
        >
          {mode === "create"
            ? "Create a new Certification Category"
            : "Edit a Certification Category"}
        </Typography>

        {isLoadingSingleCert && !singleCertification ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 4 }}
            key={location.pathname}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Category Name"
                  variant="outlined"
                  name="name"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                  disabled={isCreating || isUpdating}
                  InputLabelProps={{ style: { color: colors.white } }}
                  InputProps={{
                    sx: {
                      color: colors.white,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDigitalPerformance}
                      onChange={(e) =>
                        setIsDigitalPerformance(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Digital Performance"
                  style={{ color: color.white }}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: color.btn_dark,
                      },
                    }}
                    id="expert-select-label"
                  >
                    {mode === "edit" ? (
                      selectedExpertName === "" ? (
                        <Typography variant="body1" sx={{ color: color.white }}>
                          Choose Expert
                        </Typography>
                      ) : (
                        <Typography variant="body1" sx={{ color: color.white }}>
                          {selectedExpertName}
                        </Typography>
                      )
                    ) : (
                      <Typography variant="body1" sx={{ color: color.white }}>
                        Choose Expert
                      </Typography>
                    )}
                  </InputLabel>
                  {isExpertListLoading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "56px",
                      }}
                    >
                      <CircularProgress
                        size={24}
                        sx={{ color: colors.white }}
                      />
                    </Box>
                  ) : (
                    <Select
                      value={selectedExpertId}
                      onChange={(e) => {
                        setSelectedExpertId(e.target.value);
                        setSelectedExpertName("Choose Expert");
                      }}
                      labelId="company-select-label"
                      id="company-select"
                      label="Select Expert"
                      disabled={
                        !expertList?.experts || expertList?.experts.length === 0
                      }
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: color.primary_dark,
                            opacity: 0.95,
                            maxHeight: 370,
                            overflowY: "auto",
                          },
                        },
                      }}
                    >
                      <MenuItem
                        value={""}
                        sx={{
                          color: color.btn_dark_disabled,
                        }}
                      >
                        &lt;empty&gt;
                      </MenuItem>
                      {expertList?.experts.map((expert) => (
                        <MenuItem key={expert.id} value={expert.id}>
                          <Typography
                            variant="body1"
                            sx={{
                              color: color.white,
                            }}
                          >
                            {expert.title}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  mt={2}
                  onClick={() => setOpenKnowledgeBaseSection((prev) => !prev)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography variant="body1" style={{ color: color.white }}>
                    Attach Knowledge Bases
                  </Typography>
                  {openKnowledgeBaseSection ? (
                    <ExpandLessIcon sx={{ color: color.btn_dark }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: color.btn_dark }} />
                  )}
                </Box>

                <Collapse
                  in={openKnowledgeBaseSection}
                  timeout="auto"
                  unmountOnExit
                >
                  {knowledgeBaseListLoading ? (
                    <Typography variant="h6" color={color.btn_dark}>
                      <CircularProgress />
                    </Typography>
                  ) : knowledgeBaseListError ? (
                    <Typography variant="h6" color="error">
                      Failed to load knowledge bases. Please try again.
                    </Typography>
                  ) : (
                    <FormControl fullWidth component="fieldset" margin="normal">
                      <FormGroup aria-label="knowledge bases" row={true}>
                        {knowledgeBaseList && knowledgeBaseList.length > 0 ? (
                          knowledgeBaseList.map((kb) => (
                            <FormControlLabel
                              key={kb.id}
                              value={kb.name}
                              control={
                                <Tooltip
                                  title={
                                    kb.status !== KnowledgeBaseStatus.PROCESSED
                                      ? "Knowledge Base is not available yet."
                                      : ""
                                  }
                                  arrow
                                  disableHoverListener={
                                    kb.status === KnowledgeBaseStatus.PROCESSED
                                  }
                                >
                                  <span>
                                    {" "}
                                    <Checkbox
                                      checked={knowledgeBaseIds.includes(kb.id)}
                                      onChange={() =>
                                        handleKnowledgeBaseToggle(kb.id)
                                      }
                                      disabled={
                                        kb.status !==
                                        KnowledgeBaseStatus.PROCESSED
                                      }
                                    />
                                  </span>
                                </Tooltip>
                              }
                              label={
                                <Typography sx={{ color: color.white }}>
                                  {kb.name}
                                </Typography>
                              }
                            />
                          ))
                        ) : (
                          <Typography variant="h6" color={color.btn_dark}>
                            No knowledge bases available
                          </Typography>
                        )}
                      </FormGroup>
                    </FormControl>
                  )}
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Box mt={2} textAlign="center">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: colors.btn_dark,
                      color: colors.white,
                      "&:hover": {
                        backgroundColor: colors.primary_dark,
                      },
                      padding: 2,
                      marginTop: "50px",
                    }}
                    disabled={isCreating || isUpdating}
                  >
                    <Typography variant="body1">
                      {isCreating || isUpdating ? (
                        <CircularProgress />
                      ) : mode === "create" ? (
                        "Submit"
                      ) : (
                        "Update"
                      )}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default CertCategory;
