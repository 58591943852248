import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import color from "../../Constants/colors";
import { WorkspaceQuestionList } from "../../Models/workspace";
import { useNavigate } from "react-router-dom";

interface WorkspaceQuestionListsTableProps {
  data: WorkspaceQuestionList[];
}

const WorkspaceQuestionListsTable: React.FC<
  WorkspaceQuestionListsTableProps
> = ({ data }) => {
  const navigate = useNavigate();

  if (!data || data.length === 0) {
    return (
      <Typography
        variant="body1"
        sx={{
          color: color.white,
          textAlign: "center",
          marginTop: 2,
        }}
      >
        No question lists available.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 4,
      }}
    >
      <TableContainer
        sx={{
          width: "75vw",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: color.btn_dark,
                  fontWeight: "bold",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  color: color.btn_dark,
                  fontWeight: "bold",
                }}
              >
                Certification Category
              </TableCell>
              <TableCell
                sx={{
                  color: color.btn_dark,
                  fontWeight: "bold",
                }}
              >
                Created By
              </TableCell>
              <TableCell
                sx={{
                  color: color.btn_dark,
                  fontWeight: "bold",
                }}
              >
                Default
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((list) => (
              <TableRow
                key={list.id}
                sx={{
                  "&:hover": { backgroundColor: color.primary_dark },
                }}
              >
                <TableCell
                  sx={{
                    color: color.white,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/question_list/${list.id}`)}
                >
                  {list.name}
                </TableCell>
                <TableCell sx={{ color: color.white }}>
                  {list.certification_category?.name || "N/A"}
                </TableCell>
                <TableCell sx={{ color: color.white }}>
                  {list.created_by?.name} {list.created_by?.surname} (
                  {list.created_by?.email})
                </TableCell>
                <TableCell sx={{ color: color.white }}>
                  {list.default ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default WorkspaceQuestionListsTable;
