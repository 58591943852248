import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomTextField from "../UI/dialogs/CustomTextField";
import { updateUser, changePassword } from "../../Services/Https/user";
import { enqueueSnackbar } from "notistack";
import color from "../../Constants/colors";

interface EditUserModalProps {
  open: boolean;
  onClose: () => void;
  userData: {
    name: string;
    surname: string;
    email: string;
    is_sso: boolean;
  };
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  onClose,
  userData,
}) => {
  const [formValues, setFormValues] = useState({
    name: userData.name,
    surname: userData.surname,
    email: userData.email,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  useEffect(() => {
    if (open) {
      setFormValues({
        name: userData.name,
        surname: userData.surname,
        email: userData.email,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
    }
  }, [open, userData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = name === "email" ? value.toLowerCase() : value;

    setFormValues({ ...formValues, [name]: newValue });
  };

  const handleSaveChanges = async () => {
    try {
      const normalizedEmail = formValues.email.toLowerCase();
      if (
        formValues.oldPassword ||
        formValues.newPassword ||
        formValues.confirmNewPassword
      ) {
        if (formValues.newPassword !== formValues.confirmNewPassword) {
          enqueueSnackbar("Passwords do not match.", { variant: "warning" });
          return;
        }
        await changePassword(formValues.oldPassword, formValues.newPassword);
        enqueueSnackbar("Password changed successfully.", {
          variant: "success",
        });
      } else {
        await updateUser(formValues.name, formValues.surname, normalizedEmail);
        enqueueSnackbar("User updated successfully.", { variant: "success" });
      }
      onClose();
    } catch {
      enqueueSnackbar("Failed to update user.", { variant: "error" });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        <Grid
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Typography variant="h3" sx={{ color: color.primary_dark }}>
            Edit Your Profile
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          sx={{
            width: "80%",
            mx: "auto",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{ color: color.primary_dark, marginTop: "16px" }}
          >
            Personal Information
          </Typography>
          <CustomTextField
            id="name"
            label="Name"
            type="text"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
          />
          <CustomTextField
            id="surname"
            label="Surname"
            name="surname"
            value={formValues.surname}
            onChange={handleInputChange}
          />
          <CustomTextField
            id="email"
            label="Email Address"
            type="email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            disabled={userData.is_sso}
            tooltipMessage="You can't change email because this account uses Single Sign-on"
          />

          <Button
            onClick={handleSaveChanges}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              marginBottom: "10px",
              marginTop: "10px",
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
            variant="outlined"
          >
            Save changes
          </Button>
          <Divider style={{ marginBottom: 16 }} />
          {!userData.is_sso && (
            <>
              <Typography
                variant="h4"
                component="div"
                sx={{ color: color.primary_dark, marginTop: "16px" }}
              >
                Change Password
              </Typography>
              <CustomTextField
                id="old-password"
                label="Old Password"
                type="password"
                name="oldPassword"
                value={formValues.oldPassword}
                onChange={handleInputChange}
              />
              <CustomTextField
                id="new-password"
                label="New Password"
                type="password"
                name="newPassword"
                value={formValues.newPassword}
                onChange={handleInputChange}
              />
              <CustomTextField
                id="confirm-new-password"
                label="Confirm New Password"
                type="password"
                name="confirmNewPassword"
                value={formValues.confirmNewPassword}
                onChange={handleInputChange}
              />
              <Button
                onClick={handleSaveChanges}
                sx={{
                  backgroundColor: color.btn_dark,
                  color: color.white,
                  marginBottom: "10px",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: color.primary_dark,
                    color: color.btn_dark,
                  },
                }}
                variant="outlined"
              >
                Change Password
              </Button>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
