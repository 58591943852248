import React, { useState } from "react";
import { Switch, FormControlLabel, Grid } from "@mui/material";
import color from "../../Constants/colors";

interface ToggleAlanExpertProps {
  onToggle: (useAlanExpert: boolean) => void;
}

const ToggleAlanExpert: React.FC<ToggleAlanExpertProps> = ({ onToggle }) => {
  const [isAlanExpert, setIsAlanExpert] = useState<boolean>(true);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setIsAlanExpert(newValue);
    onToggle(newValue);
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={isAlanExpert}
              onChange={handleToggleChange}
              name="alanExpertToggle"
              sx={{
                "& .MuiSwitch-thumb": {
                  backgroundColor: isAlanExpert ? color.orange : "lightgray",
                },
                "& .MuiSwitch-track": {
                  backgroundColor: isAlanExpert
                    ? color.primary_light
                    : "lightcoral",
                },
              }}
            />
          }
          label="Use Alan Knowledge base"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "0.875rem",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ToggleAlanExpert;
