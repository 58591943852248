import {
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import theme from "../../Constants/theme";
import color from "../../Constants/colors";
import { CompanyResponse } from "../../Models/company";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { useEffect } from "react";

interface SelectCompanyProps {
  selectedCompanyId: number | "";
  handleSelectChange: (event: SelectChangeEvent<number>) => void;
  companyResponse: CompanyResponse | undefined;
  isEvaluateLoading: boolean;
  navigateToCreateCompany: () => void;
  onRefetchCompanies: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<CompanyResponse, unknown>>;
  areCompaniesLoading: boolean;
}

const SelectCompany: React.FC<SelectCompanyProps> = ({
  selectedCompanyId,
  handleSelectChange,
  companyResponse,
  isEvaluateLoading,
  navigateToCreateCompany,
  onRefetchCompanies,
}) => {
  useEffect(() => {
    if (!isEvaluateLoading) {
      onRefetchCompanies();
    }
  }, [isEvaluateLoading, onRefetchCompanies]);

  return (
    <Grid item sx={{ width: { xs: "90%", sm: "70%", md: "30%" }, mt: 5 }}>
      <FormControl fullWidth>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: color.btn_dark,
            },
          }}
          id="company-select-label"
        >
          <Typography variant="body1" sx={{ color: color.white }}>
            Select Company
          </Typography>
        </InputLabel>
        <Select
          labelId="company-select-label"
          id="company-select"
          value={selectedCompanyId}
          label="Select Company"
          onChange={handleSelectChange}
          disabled={!companyResponse}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: color.primary_dark,
                opacity: 0.95,
              },
            },
          }}
        >
          {companyResponse?.companies?.map((company) => (
            <MenuItem
              key={company.id}
              value={company.id}
              disabled={company.in_progress}
            >
              <Typography
                variant="body1"
                component="div"
                sx={{
                  color: company.in_progress ? "inherit" : color.white,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {company.name}
              </Typography>
            </MenuItem>
          ))}
          <MenuItem onClick={navigateToCreateCompany} sx={{ opacity: 0.7 }}>
            <ListItemIcon>
              <Add sx={{ color: color.btn_dark }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  style={{
                    color: theme.palette.primary.main,
                    opacity: 0.7,
                  }}
                >
                  Add new company
                </Typography>
              }
            />
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};

export default SelectCompany;
