import React from "react";
import { Box, Typography, styled } from "@mui/material";
import color from "../../Constants/colors";

interface UserLimitsProps {
  dailyLimit: number | null;
  evaluations: number;
  companiesLimit: number | null;
  companiesCount: number;
}

const CircularLimitBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  margin: "1rem",
  width: "200px",
}));

const CircularSVG = styled("svg")(({ theme }) => ({
  width: "150px",
  height: "150px",
}));

const BackgroundCircle = styled("circle")(({ theme }) => ({
  fill: "none",
  stroke: color.white,
  strokeWidth: "12",
}));

const ProgressCircle = styled("circle")(({ theme }) => ({
  fill: "none",
  stroke: color.accent,
  strokeWidth: "12",
  strokeLinecap: "round",
  transformOrigin: "50% 50%",
  transform: "rotate(-90deg)",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: color.white,
  fontSize: "1rem",
  fontWeight: "bold",
  marginTop: "0.5rem",
}));

const Value = styled(Typography)(({ theme }) => ({
  color: color.white,
  fontSize: "1.5rem",
  fontWeight: "bold",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

const InfinitySymbol = styled("span")(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
  color: color.white,
}));

const UserLimits: React.FC<UserLimitsProps> = ({
  dailyLimit,
  evaluations,
  companiesLimit,
  companiesCount,
}) => {
  const calculateProgress = (value: number, limit: number | null) => {
    if (limit === null) return 100;
    return Math.min((value / limit) * 100, 100);
  };

  const calculateStrokeDasharray = (percentage: number) => {
    const circumference = 2 * Math.PI * 70;
    const filled = (percentage / 100) * circumference;
    const remaining = circumference - filled;
    return `${filled} ${remaining}`;
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      gap="1.5rem"
      sx={{
        "@media (min-width: 600px)": {
          flexDirection: "row",
        },
        "@media (max-width: 600px)": {
          flexDirection: "column",
        },
      }}
    >
      {/* Evaluation Limit */}
      <CircularLimitBox>
        <Box position="relative">
          <CircularSVG>
            <BackgroundCircle cx="75" cy="75" r="70" />
            <ProgressCircle
              cx="75"
              cy="75"
              r="70"
              strokeDasharray={calculateStrokeDasharray(
                calculateProgress(evaluations, dailyLimit)
              )}
            />
          </CircularSVG>
          <Value>
            {dailyLimit === null ? (
              <InfinitySymbol>∞</InfinitySymbol>
            ) : (
              `${evaluations} / ${dailyLimit}`
            )}
          </Value>
        </Box>
        <Label>Evaluation Limit</Label>
      </CircularLimitBox>

      {/* Company Limit */}
      <CircularLimitBox>
        <Box position="relative">
          <CircularSVG>
            <BackgroundCircle cx="75" cy="75" r="70" />
            <ProgressCircle
              cx="75"
              cy="75"
              r="70"
              strokeDasharray={calculateStrokeDasharray(
                calculateProgress(companiesCount, companiesLimit)
              )}
            />
          </CircularSVG>
          <Value>
            {companiesLimit === null ? (
              <InfinitySymbol>∞</InfinitySymbol>
            ) : (
              `${companiesCount} / ${companiesLimit}`
            )}
          </Value>
        </Box>
        <Label>Company Limit</Label>
      </CircularLimitBox>
    </Box>
  );
};

export default UserLimits;
