import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import colors from "../../Constants/colors";

interface UserLimitsProps {
  userEvaluationLimit: number;
  userCompanyLimit: number;
  onChange: (limits: {
    userEvaluationLimit: number;
    userCompanyLimit: number;
  }) => void;
}

const UserLimits: React.FC<UserLimitsProps> = ({
  userEvaluationLimit,
  userCompanyLimit,
  onChange,
}) => {
  const [tempEvaluationLimit, setTempEvaluationLimit] = useState<
    string | number
  >(userEvaluationLimit);
  const [tempCompanyLimit, setTempCompanyLimit] = useState<string | number>(
    userCompanyLimit
  );

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "userEvaluationLimit") {
      setTempEvaluationLimit(value);
    } else if (name === "userCompanyLimit") {
      setTempCompanyLimit(value);
    }
  };

  const handleBlur = () => {
    const newEvaluationLimit =
      tempEvaluationLimit === "" || Number(tempEvaluationLimit) < 1
        ? 1
        : Number(tempEvaluationLimit);

    const newCompanyLimit =
      tempCompanyLimit === "" || Number(tempCompanyLimit) < 1
        ? 1
        : Number(tempCompanyLimit);

    setTempEvaluationLimit(newEvaluationLimit);
    setTempCompanyLimit(newCompanyLimit);

    onChange({
      userEvaluationLimit: newEvaluationLimit,
      userCompanyLimit: newCompanyLimit,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="User Evaluation Limit"
          name="userEvaluationLimit"
          value={tempEvaluationLimit}
          onChange={handleInputChange}
          onBlur={handleBlur} // Handle revert to 1 on blur
          fullWidth
          variant="outlined"
          margin="normal"
          type="number"
          inputProps={{
            min: 1,
            step: 1,
          }}
          InputLabelProps={{
            shrink: true,
            sx: { color: colors.white },
          }}
          sx={{
            "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
              {
                WebkitAppearance: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="User Company Limit"
          name="userCompanyLimit"
          value={tempCompanyLimit}
          onChange={handleInputChange}
          onBlur={handleBlur} // Handle revert to 1 on blur
          fullWidth
          variant="outlined"
          margin="normal"
          type="number"
          inputProps={{
            min: 1,
            step: 1,
          }}
          InputLabelProps={{
            shrink: true,
            sx: { color: colors.white },
          }}
          sx={{
            "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
              {
                WebkitAppearance: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UserLimits;
