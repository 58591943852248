import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { getCertCategories } from "../../Services/Https/certifications";
import color from "../../Constants/colors";

interface Certification {
  id: number;
  name: string;
}

interface CertificationsSelectorProps {
  selectedCertifications: number[];
  onChange: (selected: number[]) => void;
}

const CertificationsSelector: React.FC<CertificationsSelectorProps> = ({
  selectedCertifications,
  onChange,
}) => {
  const [certificationOptions, setCertificationOptions] = useState<
    Certification[]
  >([]);

  useEffect(() => {
    const fetchCertifications = async () => {
      try {
        const certifications = await getCertCategories();
        setCertificationOptions(certifications);
      } catch (error) {
        enqueueSnackbar(
          "Failed to fetch certifications. Please try again later.",
          { variant: "error" }
        );
      }
    };

    fetchCertifications();
  }, []);

  const handleCheckboxChange = (certId: number) => {
    const updatedCertifications = selectedCertifications.includes(certId)
      ? selectedCertifications.filter((id) => id !== certId) // Remove if already selected
      : [...selectedCertifications, certId]; // Add if not selected

    onChange(updatedCertifications);
  };

  return (
    <FormControl component="fieldset" fullWidth margin="normal">
      <Typography
        variant="h4"
        align="center"
        sx={{ color: color.white, marginBottom: 2 }}
      >
        Certifications
      </Typography>
      <FormGroup>
        {certificationOptions.map((cert) => (
          <FormControlLabel
            key={cert.id}
            control={
              <Checkbox
                checked={selectedCertifications.includes(cert.id)}
                onChange={() => handleCheckboxChange(cert.id)}
                sx={{ color: "white" }}
              />
            }
            label={<Typography sx={{ color: "white" }}>{cert.name}</Typography>}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CertificationsSelector;
