import React, { useEffect, useState } from "react";
import { getCurrentWorkspace } from "../../Services/Https/workspace";

const WorkspaceIcon: React.FC = () => {
  const [icon, setIcon] = useState<string | null>(null);
  const [workspaceName, setWorkspaceName] = useState<string | null>(null);

  useEffect(() => {
    const getWorkspaceDetails = async () => {
      try {
        const data = await getCurrentWorkspace();
        const iconImage = data.workspace?.icon_image;
        const name = data.workspace?.name;
        setIcon(iconImage ? `data:image/png;base64,${iconImage}` : null);
        setWorkspaceName(name || null);
      } catch (error) {
        console.error("Failed to fetch workspace details:", error);
      }
    };

    getWorkspaceDetails();
  }, []);

  if (!workspaceName) {
    return null;
  }

  return (
    <div
      className="workspace-details"
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "300px",
      }}
    >
      {icon && (
        <div
          className="workspace-icon"
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "60px",
            marginRight: "60px",
            marginBottom: "-20px",
          }}
        >
          <img
            src={icon}
            alt="Workspace Icon"
            aria-hidden="true"
            style={{
              width: "180px",
              height: "150px",
              objectFit: "contain",
            }}
          />
        </div>
      )}
      <div
        className="workspace-name"
        style={{
          fontWeight: "bold",
          fontSize: "1.2em",
          padding: "5px",
          whiteSpace: "normal",
          wordBreak: "break-word",
          textAlign: "center",
        }}
      >
        {workspaceName}
      </div>
    </div>
  );
};

export default WorkspaceIcon;
