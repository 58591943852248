import { createTheme } from "@mui/material/styles";
import colors from "./colors";

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Barlow, sans-serif",
      fontWeight: "medium",
      lineHeight: "76px",
      letterSpacing: "0px",
    },
    h2: {
      fontFamily: "Barlow, sans-serif",
      fontWeight: "medium",
      fontSize: "3.25rem",
      lineHeight: "56px",
      letterSpacing: "0px",
    },
    h3: {
      fontFamily: "Barlow, sans-serif",
      fontWeight: "medium",
      fontSize: "2.125rem",
      lineHeight: "44px",
      letterSpacing: "0px",
    },
    h4: {
      fontFamily: "Barlow, sans-serif",
      fontWeight: "medium",
      fontSize: "1.75rem",
      lineHeight: "38px",
      letterSpacing: "0px",
    },

    body1: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: "regular",
      fontSize: "1.375rem",
      lineHeight: "28px",
      letterSpacing: "0px",
    },
    body2: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: "regular",
      fontSize: "1rem",
      lineHeight: "20px",
      letterSpacing: "0px",
    },
    subtitle1: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: "medium",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0px",
    },
    caption: {
      fontFamily: "Work Sans, sans-serif",
      fontWeight: "medium",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0px",
    },
  },
  breakpoints: {
    values: {
      xs: 0, //For mobile devices (0 < W < 480)
      sm: 481, //iPads and Tablets (480 < W < 768)
      md: 769, //Laptops and small screens (768 < W < 1024)
      lg: 1025, //Large screens and Desktops (1024 < W < 1200)
      xl: 1201, //TV and Extra Large Screens (W > 1200)
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    accent: {
      main: colors.accent,
    },
    error: {
      main: colors.red,
    },
    background: {
      default: colors.primary_dark,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      disabled: colors.textDisabled,
      hint: colors.textHint,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: colors.white,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.btn_dark,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.btn_dark,
          },
          input: {
            color: colors.white,
          },
          textarea: {
            color: colors.white,
          },
          "&.Mui-disabled": {
            "input, textarea": {
              color: colors.primary_light,
              WebkitTextFillColor: colors.primary_light,
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: colors.primary_light,
              WebkitTextFillColor: colors.primary_light,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: colors.white,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: colors.btn_dark_disabled,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&[data-shrink="false"]': {
            color: colors.white,
          },
          ".Mui-focused, .MuiFormLabel-filled": {
            color: colors.white,
          },
          "&:hover": {
            color: colors.white,
          },
          "&.Mui-disabled": {
            color: `${colors.primary_light} !important`,
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: colors.btn_dark,
          "&:hover": {
            color: colors.btn_dark,
            "& .MuiTableSortLabel-icon": {
              color: colors.btn_dark,
            },
          },
          "&.Mui-active": {
            color: colors.btn_dark,
            "& .MuiTableSortLabel-icon": {
              color: colors.btn_dark,
            },
          },
        },

        icon: {
          color: colors.btn_dark,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: colors.white,
          borderColor: colors.white,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: colors.primary,
          "& .MuiTableCell-root": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: colors.btn_dark,
        },
        selectIcon: {
          color: colors.btn_dark,
        },
        select: {
          color: colors.btn_dark,
        },
        actions: {
          "& .MuiIconButton-root": {
            color: colors.btn_dark,
          },
          "& .MuiIconButton-root.Mui-disabled": {
            color: colors.primary_medium,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.btn_dark,
          "&.Mui-checked": {
            color: colors.accent,
          },
          "&.MuiCheckbox-indeterminate": {
            color: colors.secondary,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.btn_dark,
          "&.Mui-checked": {
            color: colors.accent,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary,
        },
        message: {
          color: colors.btn_dark,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&.Mui-disabled": {
            color: colors.primary_light,
            WebkitTextFillColor: colors.primary_light,
          },
        },
        Menu: {
          Paper: {
            opacity: 1,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: `${colors.white} !important`,
            "& .MuiSelect-select": {
              color: `${colors.primary_light} !important`,
              WebkitTextFillColor: `${colors.primary_light} !important`,
            },
          },
        },
      },
    },
  },
});

// RESPONSIVE TYPOGRAPHY
theme.typography.h1 = {
  [theme.breakpoints.up("xs")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "3.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "4.5rem",
  },
};

// theme.typography.h2 = {
//   [theme.breakpoints.up("xs")]: {
//     fontSize: "2rem",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "2.5rem",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "3rem",
//   },
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "4rem",
//   },
// };

export default theme;
